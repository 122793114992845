//import router from "@/router";
import Cookies from 'js-cookie'
import { getUrlParams, initLocalName } from '@/utils'
import { Cats } from '@/api/user'
import { db } from '@/db'

const state = {
	API_TOKEN:
		process.env.NODE_ENV == 'development'
			? process.env.VUE_APP_API_TOKEN
			: '', //getToken(),
	name: '', //用户
	platTime: {
		start: '',
		end: '',
	},
	brandId: '',
	moneySymbol:
		window.location.host.includes('oversea') &&
		localStorage.getItem('language') == 'en'
			? 'US $'
			: '￥',
	loginInfo: {}, //登陆后的信息名
	ActivedMnenue: '1-1', //当前选中的menue
	ActivedTab: 'Summary', //当前选中的tab
	ShowMainHead: false,
	plat: getUrlParams('plat') ? getUrlParams('plat') : '',
	navContent: [], //nav的tab数据
	navData: {}, //nav的所有数据
	catName: '',
	catId: getUrlParams('cid') ? getUrlParams('cid') : '',
	parentCatId: '',
	title: getUrlParams('title') ? getUrlParams('title') : '', //自定义标题
	labelLevel: getUrlParams('label_level') ? getUrlParams('label_level') : '', //头部筛选条件选中声量的等级
	aspectType: getUrlParams('aspect_type') ? getUrlParams('aspect_type') : '', //头部筛选条件选中声量的类型(全部,正向,负向)
	words: getUrlParams('words') ? getUrlParams('words') : '', //头部筛选条件选中声量图表方面的名称
	ruleId: getUrlParams('rule_id') ? getUrlParams('rule_id') : '', //规则ID
	analyseTyp: getUrlParams('analyse_typ') ? getUrlParams('analyse_typ') : '', //分析名称
	nerType: getUrlParams('ner_type') ? getUrlParams('ner_type') : '', //自定义名称
	customTyp: getUrlParams('custom_typ') ? getUrlParams('custom_typ') : '', //规则名称
	routerRuleId: getUrlParams('r_rule_id') ? getUrlParams('r_rule_id') : '', //头部筛选条件的规则ID
	routerAnalyseTyp: getUrlParams('r_analyse_typ')
		? getUrlParams('r_analyse_typ')
		: '', //头部筛选条件的规则名称
	routerNerType: getUrlParams('r_ner_type') ? getUrlParams('r_ner_type') : '', //头部筛选条件的ner名称
	customFilters: null, //头部筛选条件的参数数组
	data_by_name: '', //页面切换条件时，不重复调用接口，用于根据字段直接修改数据展示
	analyseTsType: sessionStorage.getItem('analyseTsType')
		? sessionStorage.getItem('analyseTsType')
		: 'month', //对比分析的周月日度
	tsType: sessionStorage.getItem('tsType')
		? sessionStorage.getItem('tsType')
		: 'month',
	shopId: '',
	itemId: '',
	skuId: '',
	start: getUrlParams('start') ? getUrlParams('start') : '',
	end: getUrlParams('end') ? getUrlParams('end') : '',
	titleWord: '',
	titleTyp: '',
	contentWord: '',
	picType: '1,2',
	priceMax: '',
	priceMin: '',
	catTs: [], // 如果有权限, 用当前权限类目的时间范围
	showDownloadBtn: true, // 控制页面所有下载功能是否展示
	platCats: null, //所有平台下的类目权限数据
	isNormal: false,
	latestTime: null,
	question_time_typ: 'question',
	userLoaded: false,
	// headProductIsShow: false, //是否显示产品价值栏
	// headProductList: localStorage.getItem("FX_HEAD_PRODUCT_LIST")
	//   ? JSON.parse(localStorage.getItem("FX_HEAD_PRODUCT_LIST"))
	//   : [], //产品价值数据
	// headProductCompletedMove: false, //触发了解价值点后的动效
	fxUrlId: '',
	itemAnalyse: '',
	aspLevel: '',
	attrId: getUrlParams('attr_id') ? getUrlParams('attr_id') : '',
	attrName: getUrlParams('attr_name') ? getUrlParams('attr_name') : '',
	attrFilters: null,
	antaTarget: '',
	titleFilters: null,
	activeRuleId: '',
	isShowNeu: false,
	multipleCatIdArr: [], //多选类目时所选中的类目对象数组
	routerAspLevel: '',
	shopIdList: [],
	itemIdList: [],
	skuIdList: [],
	customIdList: [],
}

const mutations = {
	SET_PRICE_SUFFIX: (state, moneySymbol) => {
		state.moneySymbol = moneySymbol
	},
	SET_API_TOKEN: (state, data) => {
		state.API_TOKEN = data
	},
	SET_SHOW_MAIN_HEAD: (state, data) => {
		state.ShowMainHead = data
	},
	SET_NAV_CONTENT: (state, data) => {
		state.navContent = data
	},
	SET_NAV_DATA: (state, data) => {
		state.navData = data
	},
	SET_PLAT: (state, data) => {
		state.plat = data
	},
	SET_LOGININFO: (state, data) => {
		state.loginInfo = data
	},
	SET_ACTIVED_MENUE: (state, data) => {
		state.ActivedMnenue = data
	},
	SET_ACTIVED_TAB: (state, data) => {
		state.ActivedTab = data
	},
	SET_CAT_NAME: (state, data) => {
		state.catName = data
	},
	SET_CATID: (state, data) => {
		state.catId = data
	},
	SET_PARENT_CAT_ID: (state, data) => {
		state.parentCatId = data
	},
	SET_DATA_BY_NAME: (state, data) => {
		state.data_by_name = data
	},
	SET_TITLE: (state, data) => {
		state.title = data
	},
	SET_RULE_ID: (state, data) => {
		state.ruleId = data
	},
	SET_ANALYSE_TYP: (state, data) => {
		state.analyseTyp = data
	},
	SET_WORDS: (state, data) => {
		state.words = data
	},
	SET_LABEL_LEVEL: (state, data) => {
		state.labelLevel = data
	},
	SET_ASPECT_TYPE: (state, data) => {
		state.aspectType = data
	},
	SET_CUSTOM_TYP: (state, data) => {
		state.customTyp = data
	},
	SET_NER_TYPE: (state, data) => {
		state.nerType = data
	},
	SET_ANALYSE_TS_TYPE: (state, data) => {
		state.analyseTsType = data
	},
	SET_TS_TYPE: (state, data) => {
		state.tsType = data
	},
	SET_ROUTER_RULE_ID: (state, data) => {
		state.routerRuleId = data
	},
	SET_ROUTER_ANALYSE_TYP: (state, data) => {
		state.routerAnalyseTyp = data
	},
	SET_ROUTER_NER_TYPE: (state, data) => {
		state.routerNerType = data
	},
	SET_CUSTOM_FILTERS: (state, data) => {
		state.customFilters = data
	},
	SET_PLAT_TIME: (state, data) => {
		state.platTime = data
	},
	SET_SHOP_ID: (state, data) => {
		state.shopId = data
	},
	SET_ITEM_ID: (state, data) => {
		state.itemId = data
	},
	SET_SKU_ID: (state, data) => {
		state.skuId = data
	},
	SET_START: (state, data) => {
		state.start = data
	},
	SET_END: (state, data) => {
		state.end = data
	},
	SET_BRAND_ID: (state, data) => {
		state.brandId = data
	},
	SET_TITLE_WORD: (state, data) => {
		state.titleWord = data
	},
	SET_TITLE_TYP: (state, data) => {
		state.titleTyp = data
	},
	SET_CONTENT_WORD: (state, data) => {
		state.contentWord = data
	},
	SET_PIC_TYPE: (state, data) => {
		state.picType = data
	},
	SET_PRICE_MAX: (state, data) => {
		state.priceMax = data
	},
	SET_PRICE_MIN: (state, data) => {
		state.priceMin = data
	},
	SET_CAT_TS: (state, data) => {
		state.catTs = data
	},
	SET_SHOW_DOWNLOAD_BTN: (state, data) => {
		state.showDownloadBtn = data
	},
	SET_PLAT_CATS: (state, data) => {
		state.platCats = data
	},
	SET_IS_NORMAL: (state, data) => {
		state.isNormal = data
	},
	SET_LATEST_TIME: (state, data) => {
		state.latestTime = data
	},
	SET_QUESTION_TIME_TYP: (state, data) => {
		state.question_time_typ = data
	},
	SET_USER_LOADED: (state, data) => {
		state.userLoaded = data
	},
	// SET_HEAD_PRODUCT_IS_SHOW: (state, data) => {
	//   state.headProductIsShow = data;
	// },
	// SET_HEAD_PRODUCT_LIST: (state, data) => {
	//   state.headProductList = data;
	// },
	// SET_HEAD_PRODUCT_COMPLETED_MOVE: (state, data) => {
	//   state.headProductCompletedMove = data;
	// },
	SET_FX_URL_ID: (state, data) => {
		state.fxUrlId = data
	},
	SET_ITEM_ANALYSE: (state, data) => {
		state.itemAnalyse = data
	},
	SET_ASP_LEVEL: (state, data) => {
		state.aspLevel = data
	},
	SET_ATTR_ID: (state, data) => {
		state.attrId = data
	},
	SET_ATTR_NAME: (state, data) => {
		state.attrName = data
	},
	SET_ANTA_TARGET: (state, data) => {
		state.antaTarget = data
	},
	SET_ATTR_FILTERS: (state, data) => {
		state.attrFilters = data
	},
	SET_TITLE_FILTERS: (state, data) => {
		state.titleFilters = data
	},
	SET_ACTIVE_RULE_ID: (state, data) => {
		state.activeRuleId = data
	},
	SET_IS_SHOW_NEU: (state, data) => {
		state.isShowNeu = data
	},
	SET_MULTIPLE_CAT_ID_ARR: (state, data) => {
		state.multipleCatIdArr = data
	},
	SET_ROUTER_ASP_LEVEL: (state, data) => {
		state.routerAspLevel = data
	},
	SET_SHOP_ID_LIST: (state, data) => {
		state.shopIdList = data
	},
	SET_ITEM_ID_LIST: (state, data) => {
		state.itemIdList = data
	},
	SET_SKU_ID_LIST: (state, data) => {
		state.skuIdList = data
	},
	SET_CUSTOM_ID_LIST: (state, data) => {
		state.customIdList = data
	}
}

const actions = {
	changeMoneySymbol({ commit }, moneySymbol) {
		commit('SET_PRICE_SUFFIX', moneySymbol)
	},
	changeApiToken({ commit, dispatch }, data) {
		commit('SET_API_TOKEN', data)
	},
	changeShowMainHead({ commit, dispatch }, data) {
		commit('SET_SHOW_MAIN_HEAD', data)
	},
	changeNavContent({ commit, dispatch }, data) {
		commit('SET_NAV_CONTENT', data)
	},
	changeNavData({ commit, dispatch }, data) {
		commit('SET_NAV_DATA', data)
	},
	changePlat({ commit, dispatch }, data) {
		commit('SET_PLAT', data)
	},
	changeLoginInfo({ commit, dispatch }, data) {
		commit('SET_LOGININFO', data)
	},
	changeActivedMnenue({ commit, dispatch }, data) {
		commit('SET_ACTIVED_MENUE', data)
	},
	changeActivedTab({ commit, dispatch }, data) {
		commit('SET_ACTIVED_TAB', data)
	},
	changeCatName({ commit, dispatch }, data) {
		commit('SET_CAT_NAME', data)
	},
	changeCatId({ commit, dispatch }, data) {
		commit('SET_CATID', data)
	},
	changeParentCatId({ commit, dispatch }, data) {
		commit('SET_PARENT_CAT_ID', data)
	},
	changeDataByName({ commit, dispatch }, data) {
		commit('SET_DATA_BY_NAME', data)
	},
	changeTitle({ commit, dispatch }, data) {
		commit('SET_TITLE', data)
	},
	changeRuleId({ commit, dispatch }, data) {
		commit('SET_RULE_ID', data)
	},
	changeAnalyseTyp({ commit, dispatch }, data) {
		commit('SET_ANALYSE_TYP', data)
	},
	changeWords({ commit, dispatch }, data) {
		commit('SET_WORDS', data)
	},
	changeLabelLevel({ commit, dispatch }, data) {
		commit('SET_LABEL_LEVEL', data)
	},
	changeAspectType({ commit, dispatch }, data) {
		commit('SET_ASPECT_TYPE', data)
	},
	changeCustomTyp({ commit, dispatch }, data) {
		commit('SET_CUSTOM_TYP', data)
	},
	changeNerType({ commit, dispatch }, data) {
		commit('SET_NER_TYPE', data)
	},
	changeAnalyseTsType({ commit, dispatch }, data) {
		commit('SET_ANALYSE_TS_TYPE', data)
	},
	changeTsType({ commit, dispatch }, data) {
		commit('SET_TS_TYPE', data)
	},
	changeRouterRuleId({ commit, dispatch }, data) {
		commit('SET_ROUTER_RULE_ID', data)
	},
	changeRouterAnalyseTyp({ commit, dispatch }, data) {
		commit('SET_ROUTER_ANALYSE_TYP', data)
	},
	changeRouterNerType({ commit, dispatch }, data) {
		commit('SET_ROUTER_NER_TYPE', data)
	},
	changeCustomFilters({ commit, dispatch }, data) {
		commit('SET_CUSTOM_FILTERS', data)
	},
	changePlatTime({ commit, dispatch }, data) {
		commit('SET_PLAT_TIME', data)
	},
	changeShopId({ commit, dispatch }, data) {
		commit('SET_SHOP_ID', data)
	},
	changeItemId({ commit, dispatch }, data) {
		commit('SET_ITEM_ID', data)
	},
	changeSkuId({ commit, dispatch }, data) {
		commit('SET_SKU_ID', data)
	},
	changeStart({ commit, dispatch }, data) {
		commit('SET_START', data)
	},
	changeEnd({ commit, dispatch }, data) {
		commit('SET_END', data)
	},
	changeBrandId({ commit, dispatch }, data) {
		commit('SET_BRAND_ID', data)
	},
	changeTitleWord({ commit, dispatch }, data) {
		commit('SET_TITLE_WORD', data)
	},
	changeTitleTyp({ commit, dispatch }, data) {
		commit('SET_TITLE_TYP', data)
	},
	changeContentWord({ commit, dispatch }, data) {
		commit('SET_CONTENT_WORD', data)
	},
	changePicType({ commit, dispatch }, data) {
		commit('SET_PIC_TYPE', data)
	},
	changePriceMax({ commit, dispatch }, data) {
		commit('SET_PRICE_MAX', data)
	},
	changePriceMin({ commit, dispatch }, data) {
		commit('SET_PRICE_MIN', data)
	},
	changeCatTs({ commit, dispatch }, data) {
		commit('SET_CAT_TS', data)
	},
	changeShowDownloadBtn({ commit, dispatch }, data) {
		commit('SET_SHOW_DOWNLOAD_BTN', data)
	},
	changePlatCats({ commit, dispatch }, data) {
		commit('SET_PLAT_CATS', data)
	},
	changeIsNormal({ commit, dispatch }, data) {
		commit('SET_IS_NORMAL', data)
	},
	changeLatestTime({ commit, dispatch }, data) {
		commit('SET_LATEST_TIME', data)
	},
	changeQuestionTimeTyp({ commit, dispatch }, data) {
		commit('SET_QUESTION_TIME_TYP', data)
	},
	changeUserLoaded({ commit, dispatch }, data) {
		commit('SET_USER_LOADED', data)
	},
	// changeHeadProductIsShow({ commit, dispatch }, data) {
	//   commit("SET_HEAD_PRODUCT_IS_SHOW", data);
	// },
	// changeHeadProductList({ commit, dispatch }, data) {
	//   commit("SET_HEAD_PRODUCT_LIST", data);
	// },
	// changeHeadProductCompletedMove({ commit, dispatch }, data) {
	//   commit("SET_HEAD_PRODUCT_COMPLETED_MOVE", data);
	// },
	changeFxUrlId({ commit, dispatch }, data) {
		commit('SET_FX_URL_ID', data)
	},
	changeItemAnalyse({ commit, dispatch }, data) {
		commit('SET_ITEM_ANALYSE', data)
	},
	changeAspLevel({ commit, dispatch }, data) {
		commit('SET_ASP_LEVEL', data)
	},
	changeAttrId({ commit, dispatch }, data) {
		commit('SET_ATTR_ID', data)
	},
	changeAttrName({ commit, dispatch }, data) {
		commit('SET_ATTR_NAME', data)
	},
	changeAntaTarget({ commit, dispatch }, data) {
		commit('SET_ANTA_TARGET', data)
	},
	changeAttrFilters({ commit, dispatch }, data) {
		commit('SET_ATTR_FILTERS', data)
	},
	changeTitleFilters({ commit, dispatch }, data) {
		commit('SET_TITLE_FILTERS', data)
	},
	changeActiveRuleId({ commit, dispatch }, data) {
		commit('SET_ACTIVE_RULE_ID', data)
	},
	changeIsShowNeu({ commit, dispatch }, data) {
		commit('SET_IS_SHOW_NEU', data)
	},
	changeMultipleCatIdArr({ commit, dispatch }, data) {
		commit('SET_MULTIPLE_CAT_ID_ARR', data)
	},
	changeRouterAspLevel({ commit, dispatch }, data) {
		commit('SET_ROUTER_ASP_LEVEL', data)
	},
	changeShopIdList({ commit, dispatch }, data) {
		commit('SET_SHOP_ID_LIST', data)
	},
	changeItemIdList({ commit, dispatch }, data) {
		commit('SET_ITEM_ID_LIST', data)
	},
	changeSkuIdList({ commit, dispatch }, data) {
		commit('SET_SKU_ID_LIST', data)
	},
	changeCustomIdList({ commit, dispatch }, data) {
		commit('SET_CUSTOM_ID_LIST', data)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
