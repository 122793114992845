<template>
  <div>
    <div v-if="isShowSelect"
         class="mask"
         @click="isShowSelect = !isShowSelect" />
    <el-popover ref="popover"
                class="ztreePopover"
                :class="{'isDisabledZtree':!!fxUrlId}"
                v-model="isShowSelect"
                placement="bottom-start"
                :width="width"
                trigger="click"
                :disabled="!!fxUrlId">
      <el-input :placeholder="options.searchPlaceholder"
                :size="size"
                style="margin-bottom:10px"
                v-model="filterText"
                @change="searchChange">
      </el-input>

      <ul :id="treeDomId"
          ref="catSelectTree"
          v-show="!isSearchTree"
          class="ztree"
          :style="style"
          style="overflow-y: auto;"></ul>
      <ul :id="treeDomSearchId"
          class="ztree"
          v-if="isSearchTree"
          :style="style"
          style="overflow-y: auto;"></ul>
      <!-- <el-select slot="reference"
                 ref="select"
                 v-model="treeSelectedData"
                 :placeholder="placeholder"
                 :disabled="isDisabled"
                 :style="selectStyle"
                 :size="size"
                 :multiple="multiple"
                 :collapse-tags="false"
                 v-bind="$attrs"
                 v-on="$listeners"
                 class="tree-select"
                 :clearable="false"
                 @remove-tag="removeTag"
                 @clear="clearNode"
                 @click.native="isShowSelect = !isShowSelect"
                 @change="changeSelect">
        <el-option v-for="item in selectOptions"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id" />
      </el-select> -->
      <div slot="reference"
           class="cat-select-input">
        <span class="cat-select-inner">{{catName ? catName : selectCatName}}
          <i class="el-icon-arrow-down cat-tree-icon"
             v-if="!isShowSelect" />
          <i class="el-icon-arrow-up cat-tree-icon"
             v-else />
        </span>
      </div>

      <div style="display: flex; justify-content: center">
        <el-button size="mini"
                   type="primary"
                   class="cat-confirm-btn"
                   @click="confirmHandler">确定</el-button>
      </div>
    </el-popover>
  </div>
</template>

<script>
import '@/libs/js/jquery.ztree.core.js'
import '@/libs/js/jquery.ztree.excheck.js'
import '@/libs/css/treeSelectStyle.css'

export default {
  name: 'zTree',
  model: {
    prop: 'treeSelectValue',
    event: 'change',
  },
  props: {
    // 选择的值
    treeSelectValue: {
      type: Array,
      default() {
        return []
      },
    },
    size: {
      type: String,
      default() {
        return 'mini'
      },
    },
    width: {
      type: Number,
      default() {
        return 180
      },
    },
    height: {
      type: Number,
      default() {
        return 300
      },
    },
    treeDomId: {
      type: String,
      default() {
        return 'selectZtree'
      },
    },
    treeDomSearchId: {
      type: String,
      default() {
        return 'searchTree'
      },
    },
    plat: {
      type: String,
      default: '',
    },
    checkedKeys: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'default',
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    this.catsData = {}
    this.zTreeObj = {}
    this.zNodes = []
    return {
      placeholder: '加载中...',
      isSearchTree: false, //是否展示搜索后的tree
      style:
        'width:' + (this.width - 20) + 'px;' + 'height:' + this.height + 'px;',
      selectStyle: 'width:' + (this.width - 20) + 'px;font-size: 12px',
      filterText: '',
      isShowSelect: false, // 是否显示树状选择器
      selectedData: '[]', // 选中的节点
      treeSelectedData: [], // 选中的节点
      catTreeObj: null,
      selectOptions: [],
      setting: {
        treeId: 'id',
        data: {
          key: {
            name: 'label',
          },
          simpleData: {
            enable: true,
            idKey: 'id',
            pIdKey: 'pId',
            rootPId: 0,
          },
        },
        view: {
          showIcon: false,
          //   showLine: false,
          fontCss: this.zTreefontCss,
          nodeClasses: this.zTreeNodeClasses,
        },
        check: {
          enable: true,
          chkStyle: 'checkbox',
          radioType: 'all',
          autoCheckTrigger: false,
          chkDisabledInherit: false,
          nocheckInherit: false,
          chkboxType: { Y: 'ps', N: 'ps' },
        },
        callback: {
          onCheck: this.zTreeOnCheck,
          beforeClick: this.zTreeBeforeClick,
          beforeCheck: this.zTreeBeforeCheck,
        },
      },
      searchSetting: {
        treeId: 'id',
        async: {
          enable: false,
          url: '',
          autoParam: ['id'],
        },
        data: {
          key: {
            name: 'label',
          },
          simpleData: {
            enable: true,
            idKey: 'id',
            pIdKey: 'pId',
            rootPId: 0,
          },
        },
        view: {
          showIcon: false,
          //   showLine: false,
          fontCss: this.zTreeSearchfontCss,
          nodeClasses: this.zTreeSearchNodeClasses,
        },
        check: {
          enable: true,
          chkStyle: 'checkbox',
          radioType: 'all',
          autoCheckTrigger: false,
          chkDisabledInherit: false,
          nocheckInherit: false,
          chkboxType: { Y: 'ps', N: 'ps' },
        },
        callback: {
          onCheck: this.zTreeSearchOnCheck,
          beforeClick: this.zTreeBeforeClick,
          beforeCheck: this.zTreeBeforeCheck,
        },
      },
      options: {
        searchShowParent: true,
        searchPlaceholder: '按enter检索',
        searchKeys: null,
      },
      searchTime: null,
      isDisabled: true,
      selectCatName: '',
      selectCat: [],
    }
  },
  watch: {
    treeSelectValue: {
      handler(value) {
        // this.selectedData = this.treeSelectValue
      },
      deep: true,
    },
    selectCat(newVal) {
      this.selectCatName = newVal
        .map((item) => {
          const node = this.zTreeObj.getNodeByParam('id', item.cat_id)
          return node.label
        })
        .join(',')
    },
    loginInfo(newVal) {
      if (this.plat) {
        this.loadCats()
      }
      // this.treeSelectedData = this.treeSelectValue
    },
    isShowSelect(val) {
      // 隐藏select自带的下拉框
      this.isSearchTree = false
      if (val) {
        this.filterText = ''
        let zTree = $.fn.zTree.getZTreeObj(this.treeDomId)
      } else {
        let zTree = $.fn.zTree.getZTreeObj(this.treeDomId)
        this.loadCatsDebounce()
      }
    },
    plat: {
      handler(newVal) {
        this.zNodes = []
        this.loadCatsDebounce()
      },
    },
    checkedKeys(val) {
      if (val.length == 0) return
      this.zNodes = []
      this.loadCatsDebounce()
    },
  },
  computed: {
    loginInfo() {
      return this.$store.state.user.loginInfo
    },
    fxUrlId() {
      return this.$store.state.user.fxUrlId
    },
    catName() {
      return this.$store.state.user.catName
    },
  },
  created() {
    // 存储各平台树结构
    if (!window.treeObj) {
      window.treeObj = {
        tmall: [],
        jd: [],
        custom: [],
        douyin: [],
        amazon_us: [],
      }
    }
    this.loadCatsDebounce = _.debounce(() => {
      this.loadCats()
    }, 100)
    this.loadCats()
  },
  mounted() {},
  methods: {
    zTreefontCss(treeId, treeNode) {
      if (treeNode.disabled) {
        return { color: '#7D8A9B' }
      }
    },
    zTreeSearchfontCss(treeId, treeNode) {
      if (treeNode.isSearchNode) {
        return { color: '#dc1d35' }
      }
    },
    zTreeSearchNodeClasses(treeId, treeNode) {
      let _this = this
      let add = []
      if (_this.ifHit(treeNode, this.treeSelectedData, [])) {
        add.push('search-hit-node')
      }
      return { add: add }
    },
    ifHit(node, keyWord, serverSearchData) {
      serverSearchData = serverSearchData || []
      let _this = this
      if (
        _this.options.searchKeys instanceof Array &&
        _this.options.searchKeys.length > 0
      ) {
        let flag = false
        $(_this.options.searchKeys).each(function (index, item) {
          if (item.startsWith('like_')) {
            if (node[item.replace('like_', '')].indexOf(keyWord) != -1) {
              flag = true
              return
            }
          } else if (item.startsWith('eq_')) {
            if (node[item.replace('eq_', '')] === keyWord) {
              flag = true
              return
            }
          }
        })
        return flag
      } else {
        return (
          node[_this.setting.data.key.name].indexOf(keyWord) != -1 ||
          _.includes(serverSearchData, node.id)
        )
      }
    },
    zTreeOnClick(event, treeId, treeNode) {
      if (treeNode.disabled) {
        return
      }
      this.selectOptions = [
        {
          id: treeNode.id,
          name: treeNode.label,
        },
      ]
      this.treeSelectedData = treeNode.id
      this.isShowSelect = false
      this.$emit('cidChange', this.selectOptions)
      this.$emit('change', [this.treeSelectedData])
      let zTree = $.fn.zTree.getZTreeObj(this.treeDomId)
      zTree.checkNode(treeNode, !treeNode.checked, true)
    },
    zTreeSearchOnCheck(event, treeId, treeNode) {
      let zTree = $.fn.zTree.getZTreeObj(this.treeDomId)
      let node = zTree.getNodeByParam('id', treeNode.id)
      zTree.checkNode(node, treeNode.checked, true)

      this.zTreeOnCheck()
    },
    zTreeOnCheck(event, treeId, treeNode) {
      let zTree = $.fn.zTree.getZTreeObj(this.treeDomId)
      // zTree.cancelSelectedNode()
      // zTree.selectNode(treeNode)
      let checkedNodes = zTree.getChangeCheckedNodes()
      let ignoreIds = []
      const selectIds = []
      const selectOptions = []
      checkedNodes.forEach((item) => {
        // 该节点被全选且父节点未被选中,则子节点id不传入
        if (item.check_Child_State == 2 && ignoreIds.indexOf(item.id) == -1) {
          let catItem = {
            cat_id: item.id,
            parent_id: item.getParentNode() ? item.getParentNode().id : 0,
          }
          selectIds.push(catItem)
          // let node = zTree.getNodeByParam('id', item.id)
          // zTree.selectNode(node,true,true)
          selectOptions.push({
            id: item.id,
            name: item.label,
          })
          ignoreIds.push(...this.getChdNodesIds(item))
        }
        // 父节点不是全选,子节点没有children
        else if (
          item.check_Child_State == -1 &&
          ignoreIds.indexOf(item.id) == -1
        ) {
          let catItem = {
            cat_id: item.id,
            parent_id: item.getParentNode() ? item.getParentNode().id : 0,
          }
          selectIds.push(catItem)
          // let node = zTree.getNodeByParam('id', item.id)
          // zTree.selectNode(node,true,true)
          selectOptions.push({
            id: item.id,
            name: item.label,
          })
        }
      })
      // 设置选择的节点
      const selectNodesIds = zTree.getSelectedNodes().map((item) => item.id)
      selectIds.forEach((item) => {
        if (selectNodesIds.indexOf(item.cat_id) == -1) {
          let node = zTree.getNodeByParam('id', item.cat_id)
          zTree.selectNode(node, true, true)
        }
      })
      selectNodesIds.forEach((item) => {
        if (selectIds.indexOf(item) == -1) {
          let node = zTree.getNodeByParam('id', item.cat_id)
          zTree.cancelSelectedNode(node)
        }
      })
      this.treeSelectedData = selectIds
      this.selectOptions = selectOptions
    },
    getChdNodesIds(nodes) {
      const ids = []
      if (nodes.children && nodes.children.length > 0) {
        nodes.children.forEach((item) => {
          ids.push(item.id)
          if (item.children && item.children.length > 0) {
            ids.push(...this.getChdNodesIds(item))
          }
        })
      }
      return ids
    },
    zTreeBeforeClick(treeId, treeNode) {
      return !this.multiple
    },
    zTreeBeforeCheck(treeId, treeNode) {
      if (this.plat == 'custom') {
        let treeObj = $.fn.zTree.getZTreeObj(this.treeDomId)
        let checkedNodes = treeObj.getCheckedNodes(true)
        if (_.some(checkedNodes, treeNode.getPath()[0])) {
          return true
        }
        function getRootNode(node) {
          var parent = node.getParentNode()
          if (parent == null) {
            return node
          } else {
            return getRootNode(parent)
          }
        }
        var checkedRootNodeIds = []
        for (var i = 0; i < checkedNodes.length; i++) {
          var node = checkedNodes[i]
          var rootNode = getRootNode(node)
          if (rootNode) {
            checkedRootNodeIds.push(rootNode.id)
          }
        }
        // 去重
        checkedRootNodeIds = Array.from(new Set(checkedRootNodeIds))
        if (
          checkedRootNodeIds.length > 0 &&
          treeNode.id.indexOf(checkedRootNodeIds[0]) == -1
        ) {
          this.$message.warning('自定义平台定制类目不能多选, 请检查 !')
          return false
        }
      }
    },
    clearNode() {
      this.$emit('clear')
      let treeObj = $.fn.zTree.getZTreeObj(this.treeDomId)
      treeObj.cancelSelectedNode()
      this.treeSelectedData = []
      this.isShowSelect = false
    },
    changeSelect() {},
    searchChange(value) {
      if (value.trim() !== '') {
        this.isSearchTree = true
        this.doSearch(value)
      } else {
        this.isSearchTree = false
      }
    },
    doSearch(searchWord) {
      let _this = this
      /*异步执行低频率执行解决性能问题*/
      if (_this.searchTime) {
        clearTimeout(_this.searchTime)
      }
      _this.searchTime = setTimeout(function () {
        let serverSearch =
          _this.options.serverSearch ||
          function () {
            return new Promise(function (resolve, reject) {
              resolve(null)
            })
          }
        serverSearch(searchWord).then(function (serverSearchData) {
          let searchzTreeObj = _this.zTreeObj
          let nodeList = searchzTreeObj.getNodesByFilter(function (node) {
            return _this.ifHit(node, searchWord, serverSearchData)
          }) //通过关键字模糊搜索
          let datasMap = {}
          $(nodeList).each(function (index, item) {
            item.isSearchNode = true
            datasMap[item['id']] = item
          })
          if (_this.options.searchShowParent) {
            $(nodeList).each(function (index, node) {
              _this.loadParents(node, datasMap)
            })
          }
          let _zNodes = _.values(datasMap)
          _this.initSearchTree(_zNodes, searchWord, serverSearchData)
        })
      }, 200)
    },
    loadParents(node, datasMap) {
      let parent = node.getParentNode()
      if (parent) {
        let copyparent = $.extend({}, parent)
        copyparent.children = null
        copyparent.open = true
        datasMap[parent['id']] = copyparent
        this.loadParents(parent, datasMap)
      } else {
        return
      }
    },
    initSearchTree(data) {
      let _this = this
      $.fn.zTree.init($('#' + this.treeDomSearchId), _this.searchSetting, data)
    },
    async loadCats() {
      let rawData = await this.loadSiteCats()
      this.catsData = rawData
      this.$emit('ChildrenListLoaded', this.catsData.result.childrenList)
      this.getData()
    },
    loadSiteCats() {
      return new Promise((resolve, reject) => {
        try {
          if (this.plat == 'tmall' && !window.cache_cat) {
            // load taobao's plat category
            const s = document.createElement('script')
            s.type = 'text/javascript'
            s.src = ''
            if (
              window.location.host == 'commentvue.mktindex.com' ||
              window.location.host == 'comment.mktindex.com'
            ) {
              s.src =
                'https://chance.mktindex.com/static/chance/json/bigcats.js'
            } else {
              s.src =
                'https://chancetest.mktindex.com/static/chance/json/bigcats.js'
            }
            document.body.appendChild(s)
            s.onload = function () {
              let datas = window.cache_cat
              resolve(datas)
            }
          } else if (this.plat == 'jd' && !window.cache_cat_jd) {
            // load jingdong plat category
            const s_jd = document.createElement('script')
            s_jd.type = 'text/javascript'
            s_jd.src = ''
            if (
              window.location.host == 'commentvue.mktindex.com' ||
              window.location.host == 'comment.mktindex.com'
            ) {
              s_jd.src =
                'https://comment.mktindex.com/static/lingting/json/bigcats_jd.js'
            } else {
              s_jd.src =
                'https://commenttest.mktindex.com/static/lingting/json/bigcats_jd.js'
            }
            document.body.appendChild(s_jd)
            s_jd.onload = function () {
              let datas = window.cache_cat_jd
              resolve(datas)
            }
          } else if (this.plat == 'custom' && !window.cache_cat_custom) {
            // load custom plat category
            const s_custom = document.createElement('script')
            s_custom.type = 'text/javascript'
            s_custom.src = ''
            if (
              window.location.host == 'commentvue.mktindex.com' ||
              window.location.host == 'comment.mktindex.com'
            ) {
              s_custom.src =
                'https://comment.mktindex.com/static/lingting/json/bigcats_custom_test.js'
            } else {
              s_custom.src =
                'https://commenttest.mktindex.com/static/lingting/json/bigcats_custom_test.js'
            }
            document.body.appendChild(s_custom)
            s_custom.onload = function () {
              let datas = window.cache_cat_custom
              resolve(datas)
            }
          } else if (this.plat == 'amazon_us' && !window.cache_cat_amazon_us) {
            // load custom plat category
            const s_amazon_us = document.createElement('script')
            s_amazon_us.type = 'text/javascript'
            s_amazon_us.src = ''
            if (
              window.location.host == 'commentvue.mktindex.com' ||
              window.location.host == 'comment.mktindex.com'
            ) {
              s_amazon_us.src =
                'https://comment.mktindex.com/static/lingting/json/bigcats_amazon_us.js'
            } else {
              s_amazon_us.src =
                'https://commenttest.mktindex.com/static/lingting/json/bigcats_amazon_us.js'
            }
            document.body.appendChild(s_amazon_us)
            s_amazon_us.onload = function () {
              let datas = window.cache_cat_amazon_us
              resolve(datas)
            }
          } else if (this.plat == 'douyin' && !window.cache_douyin_cats) {
            // load douyin plat category
            const s_douyin = document.createElement('script')
            s_douyin.type = 'text/javascript'
            s_douyin.src = ''
            if (
              window.location.host == 'commentvue.mktindex.com' ||
              window.location.host == 'comment.mktindex.com'
            ) {
              s_douyin.src =
                'https://chance.mktindex.com/static/chance/json/bigcats_douyin.js'
            } else {
              s_douyin.src =
                'https://chancetest.mktindex.com/static/chance/json/bigcats_douyin.js'
            }
            document.body.appendChild(s_douyin)
            s_douyin.onload = function () {
              let datas = window.cache_douyin_cats
              resolve(datas)
            }
          } else {
            let datas
            switch (this.plat) {
              case 'jd':
                datas = window.cache_cat_jd
                break
              case 'tmall':
                datas = window.cache_cat
                break
              case 'custom':
                datas = window.cache_cat_custom
                break
              case 'douyin':
                datas = window.cache_douyin_cats
                break
              case 'amazon_us':
                datas = window.cache_cat_amazon_us
                break
              default:
                datas = window.cache_cat_jd
            }
            resolve(datas)
          }
        } catch (err) {
          console.error(err)
          reject(err)
        }
      })
    },
    async getData() {
      let arrs = this.catsData.result.bigCategory
      const loginCatlist = this.loginInfo.cat_list
      let cids = this.$store.state.user.platCats[this.plat].cids
      let pids = this.$store.state.user.platCats[this.plat].pids
      if (loginCatlist == 'all') {
        if (window.treeObj[this.plat].length > 0) {
          setTimeout(() => {
            this.zNodes = window.treeObj[this.plat]
            this.isDisabled = false
            this.initSelectzTree()
          }, 200)
        } else {
          this.startWorker(arrs, loginCatlist, cids, pids).then((res) => {
            this.zNodes = res
            this.isDisabled = false
            window.treeObj[this.plat] = res
            this.initSelectzTree()
          })
        }
      } else {
        let cat_list = loginCatlist[this.plat]
        arrs = []
        Object.keys(cat_list).forEach((v) => {
          cat_list[v].forEach((o, i) => {
            let parents = this.get_parents(o.cat_id)
            arrs.push(_.last(parents) + '') //cat_id会存在返回的值为数字类型需要转成字符串
          })
        })
        if (window.treeObj[this.plat].length > 0) {
          setTimeout(() => {
            this.zNodes = window.treeObj[this.plat]
            this.isDisabled = false
            this.initSelectzTree()
          }, 200)
        } else {
          let arr = []
          _.forEach(cat_list, function (value, key) {
            if (value.length > 0) {
              arr.push(value)
            }
          })
          arr = _.flatten(arr)
          if (arr.length == 0) {
            this.selectCatName = '没有类目可供选择'
          }
          this.startWorker(arrs, cat_list, cids, pids).then((res) => {
            this.zNodes = res
            this.isDisabled = false
            window.treeObj[this.plat] = res
            this.initSelectzTree()
          })
        }
      }
    },
    initSelectzTree() {
      this.zTreeObj = $.fn.zTree.init(
        $('#' + this.treeDomId),
        this.setting,
        this.zNodes
      )
      // .expandAll(false)
      let zTree = $.fn.zTree.getZTreeObj(this.treeDomId)
      const disabledNodes = zTree.getNodesByParam('disabled', true)
      disabledNodes.forEach((item) => {
        zTree.setChkDisabled(item, true)
      })
      this.selectOptions = []
      this.treeSelectedData = []
      this.zTreeObj = zTree
      // 处理没有任何类目权限用户,默认id为undefined,类目树不加载
      if (this.zTreeObj.getNodes().length == 0) {
        this.selectOptions = [
          {
            id: 1,
            name: '无权限',
          },
        ]
        if (this.multiple) {
          this.treeSelectedData = [1]
          this.$emit('change', this.treeSelectedData)
        } else {
          this.treeSelectedData = 1
          this.$emit('change', [this.treeSelectedData])
        }
        if (this.type != 'search') {
          this.$eventBus.$emit('loadingCats', false)
        }
        return
      }
      if (this.checkedKeys.length > 0 && this.checkedKeys[0] != '') {
        if (this.multiple) {
          for (const item of this.checkedKeys) {
            if (item.parent_id && item.parent_id !== 0) {
              let node = zTree.getNodeByParam('id', item.cat_id)
              this.selectOptions.push({
                id: node.id,
                name: node.label,
              })
              let catItem = {
                cat_id: node.id,
                parent_id: node.getParentNode() ? node.getParentNode().id : 0,
              }
              this.treeSelectedData.push(catItem)
              zTree.checkNode(node, true, true)
              zTree.selectNode(node)
            } else {
              let parentItem = item.cat_id ? item.cat_id : item
              let node = zTree.getNodeByParam('id', parentItem)
              if (node) {
                this.selectOptions.push({
                  id: node.id,
                  name: node.label,
                })
                let catItem = {
                  cat_id: node.id,
                  parent_id: node.getParentNode() ? node.getParentNode().id : 0,
                }
                this.treeSelectedData.push(catItem)
                zTree.checkNode(node, true, true)
                zTree.selectNode(node)
              }
            }
          }
          this.selectCat = this.treeSelectedData
          this.$emit('change', this.treeSelectedData)
        } else {
          let node = zTree.getNodeByParam('id', this.checkedKeys[0])
          if (!node || node.disabled) {
            node = zTree.getNodes()[0]
            if (node.disabled) {
              node = this.getAllowedNode(node)
            }
          }
          this.selectOptions = [
            {
              id: node.id,
              name: node.label,
            },
          ]
          this.treeSelectedData = [node.id]
          this.selectCat = this.treeSelectedData
          this.$emit('change', this.treeSelectedData)
          zTree.checkNode(node, true, true)
          zTree.selectNode(node)
        }
      } else {
        let node = null
        if (
          zTree.getNodes()[0].disabled == false ||
          zTree.getNodes()[0].disabled == undefined
        ) {
          node = zTree.getNodes()[0]
        } else {
          node = zTree.getNodesByParam('disabled', false)[0]
        }
        this.selectOptions = [
          {
            id: node.id,
            name: node.label,
          },
        ]
        if (this.multiple) {
          let catItem = {
            cat_id: node.id,
            parent_id: node.getParentNode() ? node.getParentNode().id : 0,
          }
          this.treeSelectedData = [catItem]
          this.selectCat = this.treeSelectedData
          this.$emit('change', this.treeSelectedData)
        } else {
          this.treeSelectedData = node.id
          this.$emit('change', [this.treeSelectedData])
        }

        zTree.checkNode(node, true, true)
        zTree.selectNode(node)
      }
      this.handlerTreeSelectChange()
    },
    getAllowedNode(node) {
      let resultNode = null
      const children = node.children
      children.forEach((item) => {
        if (!item.disabled) {
          resultNode = item
        }
      })
      if (resultNode) {
        return resultNode
      } else {
        return this.getAllowedNode(children[0])
      }
    },
    startWorker(cats, cat_list, cids, pids) {
      const actions = [
        {
          message: 'transformPickerOptions',
          func: function (
            cats,
            childrenList,
            cat_level_list,
            cat_list,
            plat,
            cids,
            pids
          ) {
            return _transformPickerOptions(
              cats,
              childrenList,
              cat_level_list,
              cat_list,
              plat,
              cids,
              pids
            )
            function _transformPickerOptions(
              _cats,
              _childrenList,
              cat_level_list,
              cat_list,
              plat,
              cids,
              pids
            ) {
              let catArr = []
              for (let key in _cats) {
                let catId = _cats[key]
                if (cat_level_list == 'all' || cat_list == 'all') {
                  let { category_id, is_parent, chd, name, only_show, level } =
                    _childrenList[catId]
                  if (!checkHasData(catId, _childrenList, cids, pids)) {
                    continue
                  }
                  let _cat = {
                    id: category_id,
                    label:
                      plat == 'gamazon'
                        ? childrenList[catId].category_name_en
                        : name,
                    disabled: only_show,
                    is_parent,
                  }
                  if (is_parent) {
                    let _children = _transformPickerOptions(
                      chd,
                      _childrenList,
                      cat_level_list,
                      cat_list,
                      plat,
                      cids,
                      pids
                    )
                    if (_children.length) {
                      _cat.children = _children
                    }
                  }
                  catArr.push(_cat)
                } else {
                  // if (plat == 'gamazon') {
                  //   let {
                  //     category_id,
                  //     is_parent,
                  //     chd,
                  //     name,
                  //     only_show,
                  //     level,
                  //   } = _childrenList[catId]
                  //   let cat = {
                  //     id: category_id,
                  //     label: childrenList[catId].category_name_en,
                  //     disabled: only_show,
                  //     is_parent,
                  //   }
                  //   catArr.push(cat)
                  //   continue
                  // }
                  if (_childrenList[catId]) {
                    let {
                      category_id,
                      is_parent,
                      chd,
                      name,
                      only_show,
                      level,
                    } = _childrenList[catId]
                    if (!checkHasData(catId, _childrenList, cids, pids)) {
                      continue
                    }
                    only_show = check_permission(
                      catId,
                      _childrenList,
                      cat_list
                    )[1]
                    let has_priv = check_permission(
                      catId,
                      _childrenList,
                      cat_list
                    )[0]
                    let _cat = null
                    if (has_priv) {
                      _cat = {
                        id: category_id,
                        label:
                          plat == 'gamazon'
                            ? childrenList[catId].category_name_en
                            : name,
                        disabled: only_show == true,
                        is_parent: is_parent,
                      }
                    } else {
                      continue
                    }
                    if (is_parent) {
                      let _children = _transformPickerOptions(
                        chd,
                        _childrenList,
                        cat_level_list,
                        cat_list,
                        plat,
                        cids,
                        pids
                      )
                      if (_children.length) {
                        _cat.children = _children
                      }
                    }
                    // 如果前面类目父类已经添加,就不要重复添加
                    let index = catArr.findIndex((item) => {
                      return item.id == _cat.id
                    })
                    if (index > -1) {
                      continue
                    } else {
                      catArr.push(_cat)
                    }
                  } else {
                    continue
                  }
                }
              }
              return catArr
            }
            function checkHasData(id, _childrenList, VALID_CIDS, VALID_PIDS) {
              let pids = get_parents(id, _childrenList)
              let flag = false
              for (var j = 0, jk = pids.length; j < jk; j++) {
                if (VALID_CIDS.indexOf(pids[j]) >= 0) {
                  flag = true
                  break
                }
              }
              if (VALID_PIDS.indexOf(id) >= 0) {
                flag = true
              }
              return flag
            }
            function check_permission(cid, __childrenList, cat_list) {
              let pids = get_parents(cid, __childrenList)
              let has_priv = false,
                only_show = true
              //1.父类目是否有权限
              pids.forEach(function (pid, i) {
                let cat = __childrenList[pid]
                let level = cat.level
                if (cat_list['level' + level]) {
                  cat_list['level' + level].forEach(function (o, j) {
                    if (o.cat_id == pid) {
                      has_priv = true
                      only_show = false
                    }
                  })
                }
              })
              //2.子类目有权限的，需展示父类目
              if (!has_priv) {
                Object.keys(cat_list).forEach(function (v) {
                  cat_list[v].forEach(function (o, i) {
                    let parents = get_parents(o.cat_id, __childrenList)
                    if (parents.indexOf(cid) >= 0) {
                      has_priv = true
                      only_show = true
                    }
                  })
                })
              } else {
                only_show = false
              }

              return [has_priv, only_show]
            }
            function get_parents(cid, ___childrenList) {
              let cat = ___childrenList[cid]
              let pids = [cid]
              while (cat && cat.parent_id != '0') {
                pids.push(cat.parent_id)
                cat = ___childrenList[cat.parent_id]
              }
              return pids
            }
          },
        },
      ]
      let worker = this.$worker.create(actions)
      let postMessageActionName = 'transformPickerOptions'
      return worker.postMessage(postMessageActionName, [
        cats,
        this.catsData.result.childrenList,
        cat_list,
        cat_list,
        this.plat,
        cids,
        pids,
      ])
    },
    transformPickerOptions(cats) {
      let self = this
      let childrenList = this.catsData.result.childrenList
      return _transformPickerOptions(cats)
      function _transformPickerOptions(_cats) {
        let resultArr = []
        for (let key in _cats) {
          let catId = _cats[key]
          if (self.loginCatList == 'all' || self.plat == 'gamazon') {
            let { category_id, is_parent, chd, name, only_show, level } =
              childrenList[catId]
            let _cat = {
              id: category_id,
              label:
                self.plat == 'gamazon'
                  ? childrenList[catId].category_name_en
                  : name,
              disabled: only_show,
              is_parent,
              level,
            }
            if (is_parent) {
              let _children = _transformPickerOptions(chd)
              if (_children.length) {
                _cat.children = _children
              }
            }
            resultArr.push(_cat)
          } else {
            if (childrenList[catId]) {
              let { category_id, is_parent, chd, name, only_show, level } =
                childrenList[catId]
              only_show = self.check_permission(catId)[1]
              let has_priv = self.check_permission(catId)[0]
              let _cat = null
              if (has_priv) {
                _cat = {
                  id: category_id,
                  label: name,
                  disabled: only_show == true,
                  is_parent: is_parent,
                  level,
                }
              } else {
                continue
              }
              if (is_parent) {
                let _children = _transformPickerOptions(chd)
                if (_children.length) {
                  _cat.children = _children
                }
              }
              // 如果前面类目父类已经添加,就不要重复添加
              let index = resultArr.findIndex((item) => {
                return item.id == _cat.id
              })
              if (index > -1) {
                continue
              } else {
                resultArr.push(_cat)
              }
            } else {
              continue
            }
          }
        }
        return resultArr
      }
    },
    check_permission(cid) {
      let self = this
      let childrenList = this.catsData.result.childrenList
      let pids = this.get_parents(cid)
      let has_priv = false,
        only_show = true
      const cat_list =
        this.plat == 'jd' ? self.loginInfo.jd_cat_list : self.loginInfo.cat_list
      //1.父类目是否有权限
      pids.forEach(function (pid, i) {
        let cat = childrenList[pid]
        let level = cat.level
        if (cat_list['level' + level]) {
          cat_list['level' + level].forEach(function (o, j) {
            if (o.cat_id == pid) {
              has_priv = true
              only_show = false
            }
          })
        }
      })
      //2.子类目有权限的，需展示父类目
      if (!has_priv) {
        Object.keys(cat_list).forEach(function (v) {
          cat_list[v].forEach(function (o, i) {
            let parents = self.get_parents(o.cat_id)
            if (parents.indexOf(cid) >= 0) {
              has_priv = true
              only_show = true
            }
          })
        })
      } else {
        only_show = false
      }

      return [has_priv, only_show]
    },
    get_parents(cid) {
      let childrenList = this.catsData.result.childrenList
      let cat = childrenList[cid]
      let pids = [cid]
      while (cat.parent_id != '0') {
        pids.push(cat.parent_id)
        cat = childrenList[cat.parent_id]
      }
      return pids
    },
    getCatPath(cid) {
      let that = this
      let path = getParent(cid)
      if (that.maxLevel > path.length && !that.isPc) {
        path = path.concat(_.fill(Array(that.maxLevel - path.length), '-'))
      }
      return path
      function getParent(cid, path = [cid]) {
        if (
          this.catsData.result.childrenList[cid] &&
          this.catsData.result.childrenList[cid].parent_id
        ) {
          if (this.catsData.result.childrenList[cid].parent_id !== '0') {
            let parentId = this.catsData.result.childrenList[cid].parent_id
            return getParent(parentId, _.concat([parentId], path))
          } else {
            return path
          }
        } else {
          return [cid]
        }
      }
    },
    removeTag(val) {
      let zTree = $.fn.zTree.getZTreeObj(this.treeDomId)
      let node = zTree.getNodeByParam('id', val)
      zTree.checkNode(node, false, true)
      zTree.cancelSelectedNode(node)
    },
    getCatNameById(cid) {
      const node = this.zTreeObj.getNodeByParam('id', cid)
      return node.label
    },
    confirmHandler() {
      this.$emit('change', this.treeSelectedData)
      this.handlerTreeSelectChange()
      this.selectCat = this.treeSelectedData
      this.isShowSelect = false
      if (this.$route.name == 'contrastAnalysis') {
        this.$eventBus.$emit('contrastAnalysisChangeCat', this.treeSelectedData)
      }
    },
    handlerTreeSelectChange() {
      const cat_list = this.loginInfo.cat_list
      if (cat_list !== 'all') {
        const platCatList = cat_list[this.plat]
        const datas = [
          ...platCatList.level1,
          ...platCatList.level2,
          ...platCatList.level3,
          ...platCatList.level4,
        ]
        let times = []
        this.treeSelectedData.forEach((item) => {
          let id = item.cat_id
          let node = this.zTreeObj.getNodeByParam('id', id)
          let index = datas.findIndex((item) => item.cat_id == id)
          while (index == -1) {
            node = node.getParentNode()
            id = node.id
            index = datas.findIndex((item) => item.cat_id == id)
          }
          times.push(datas[index].ts)
        })
        this.$store.dispatch('user/changeCatTs', times)
      }
    },
    getCatInfoById(id) {
      const node = this.zTreeObj.getNodeByParam('id', id)
      const { path, cid_path } = this.computeNodePath(node)
      const parent_id = node.getParentNode() ? node.getParentNode().id : '0'
      const catInfo = {
        _id: node._id ? node._id : node.id,
        custom: node.custom,
        name: node.label,
        is_parent: node.is_parent,
        sub_name: node.sub_name,
        id: node.id,
        fix_id: node.id,
        top_id: node.id,
        parent_id,
        path,
        cid_path,
      }
      return catInfo
    },
    computeNodePath(node) {
      const path = []
      const cid_path = []
      const pathes = node.getPath()
      pathes.forEach((item) => {
        path.push(item.label)
        cid_path.push(item.id)
      })
      return { path, cid_path }
    },
  },
}
</script>

<style lang="less">
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
}
.common-tree {
  overflow: auto;
}

ul {
  padding: 0 0 0 10px;
}
.ztreePopover {
  .el-select .el-tag {
    max-width: 120px;
  }
}
.cat-confirm-btn {
  width: 200px;
  display: flex;
  justify-content: center;
}

.cat-select-input {
  cursor: pointer;
  margin-left: 10px;
  .cat-select-inner {
    position: relative;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    margin-top: 5px;
    display: block;
    color: #606266;
    background: #fff;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .cat-tree-icon {
      position: absolute;
      right: 4px;
      top: 8px;
    }
  }
}
.isDisabledZtree {
  .cat-select-input .cat-select-inner {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
  }
}
</style>

<style lang="less">
</style>
