const getters = {
  // token: (state) => state.user.token,
  // avatar: (state) => state.user.avatar,
  // name: (state) => state.user.name,
  // introduction: (state) => state.user.introduction,
  // roles: (state) => state.user.roles,
  selectedType: (state) => state.user.selectedType,
  selectedKeyWards: (state) => state.user.selectedKeyWards,
  plants: (state) => state.user.plants,
  startMonth: (state) => state.user.startMonth,
  endMonth: (state) => state.user.endMonth,
  lang: (state) => state.lang.lang,
  API_TOKEN: (state) => state.user.API_TOKEN,
  // logingState: (state) => state.user.logingState,
};
export default getters;
