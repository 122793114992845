<template>
  <div class="search">
    <el-select v-model="value"
               placeholder="商品"
               size="small"
               class="item-select"
               popper-class="tophead-select-popper"
               :popper-append-to-body="false"
               @change="changePlat">
      <el-option v-for="item in options"
                 :key="item.value"
                 :label='item.label'
                 :value='item.value'>
      </el-option>
    </el-select>
    <!-- 商品搜索筛选 -->
    <div v-if="showSearch == 'item'">
      <el-popover width="360"
                  trigger="click"
                  placement="bottom">
        <el-input :placeholder="itemInput"
                  class="search-input"
                  v-model="titleWord"
                  size="small"
                  slot="reference"
                  @keyup.enter.native="searchTitleWords">
          <el-button slot="append"
                     @click="searchTitleWords"
                     icon="el-icon-search"></el-button>
        </el-input>
        <div class="sku-title">请选择一种搜索方案：
          <span class="sku-tip">(输入商品链接时无需选择)</span>
        </div>
        <el-radio v-model="titleTyp"
                  label="skuandtitle"
                  @change="changeSkuTitle('skuandtitle')">在SKU名字 + 商品标题中搜索</el-radio>
        <el-radio v-model="titleTyp"
                  label="sku"
                  @change="changeSkuTitle('sku')">只在SKU名字中搜索</el-radio>
        <el-radio v-model="titleTyp"
                  label="skuortitle"
                  @change="changeSkuTitle('skuortitle')">在SKU名字中搜索,SKU名为空时在商品标题中搜索</el-radio>
      </el-popover>
      <el-tooltip class="tool-item"
                  effect="dark"
                  placement="bottom">
        <div slot="content">
          <div style="width:300px">
            <p>商品关键词和评论关键词支持使用“and”、“or”、“not”的筛选条件， <span style="color:yellow; font-size:15px">与汉字用空格隔开。</span></p>
            <p>如： “小米 and 华为”表示关键词既包含“小米”又包含“华为”</p>
            <p>“小米 or 华为 ” 表示关键词“小米”“华为”至少包含其一</p>
            <p>“小米 not 华为” 表示关键词包含“小米”且不包含“华为”</p>
          </div>
        </div>
        <i class="iconfont icon-Hint"></i>
      </el-tooltip>
    </div>
    <!-- 品牌搜索筛选 -->
    <div v-if="showSearch == 'brand'">
      <el-popover width="360"
                  trigger="manual"
                  placement="bottom">
        <el-select v-model="brandWord"
                   filterable
                   remote
                   reserve-keyword
                   :placeholder="brandInput"
                   :remote-method="remoteMethod"
                   :loading="brandLoading"
                   class="search-input"
                   style="width: 330px; margin: 0;"
                   size="small"
                   slot="reference">
          <el-option v-for="item in brandOptions"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value"
                     @click.native="addBrandId(item.value)" />
        </el-select>
      </el-popover>
      <el-tooltip class="tool-item"
                  effect="dark"
                  placement="bottom">
        <div slot="content">
          <div style="width:300px">
            <p>商品关键词和评论关键词支持使用“and”、“or”、“not”的筛选条件， <span style="color:yellow; font-size:15px">与汉字用空格隔开。</span></p>
            <p>如： “小米 and 华为”表示关键词既包含“小米”又包含“华为”</p>
            <p>“小米 or 华为 ” 表示关键词“小米”“华为”至少包含其一</p>
            <p>“小米 not 华为” 表示关键词包含“小米”且不包含“华为”</p>
          </div>
        </div>
        <i class="iconfont icon-Hint"></i>
      </el-tooltip>
      <el-button slot="append"
                 @click="open"
                 icon="el-icon-search"
                 class="open-btn"></el-button>
    </div>
    <!-- 评论搜索筛选 -->
    <div v-if="showSearch == 'comment'">
      <el-input :placeholder="commentInput"
                class="search-input"
                v-model="commentWord"
                size="small"
                @keyup.enter.native="searchCommentWords">
        <el-button slot="append"
                   @click="searchCommentWords"
                   icon="el-icon-search"></el-button>
      </el-input>
      <el-tooltip class="tool-item"
                  effect="dark"
                  placement="bottom">
        <div slot="content">
          <div style="width:300px">
            <p>商品关键词和评论关键词支持使用“and”、“or”、“not”的筛选条件， <span style="color:yellow; font-size:15px">与汉字用空格隔开。</span></p>
            <p>如： “小米 and 华为”表示关键词既包含“小米”又包含“华为”</p>
            <p>“小米 or 华为 ” 表示关键词“小米”“华为”至少包含其一</p>
            <p>“小米 not 华为” 表示关键词包含“小米”且不包含“华为”</p>
          </div>
        </div>
        <i class="iconfont icon-Hint"></i>
      </el-tooltip>
    </div>
    <!-- 价格搜索筛选 -->
    <div v-if="showSearch == 'price'"
         class="price-range-input">
      <el-input :placeholder="priceRangeMore"
                v-model="price_min">
        <template slot="suffix">元</template>
      </el-input>
      <span style="line-height: 30px; padding: 0px 5px;">~</span>
      <el-input :placeholder="priceRangeLess"
                v-model="price_max">
        <template slot="suffix">元</template>
        <el-button slot="append"
                   @click="searchPriceWords"
                   icon="el-icon-search"></el-button>
      </el-input>
    </div>
  </div>
</template>


<script>
import { getBrand } from '@/api/baseInfo'
import { getTitleWord } from '@/api/topItem'
export default {
  name: 'TopSearch',
  data() {
    return {
      keyword: '',
      options: [
        {
          value: 'item',
          label: '商品',
        },
        {
          value: 'brand',
          label: '品牌',
        },
        {
          value: 'comment',
          label: '评论',
        },
        {
          value: 'price',
          label: '价格',
        },
      ],
      value: '',
      itemInput: '可输入标题关键词或商品链接进行搜索',
      brandInput: '',
      commentInput: '请输入评论关键词或评论ID',
      showSearch: 'item',
      priceRangeMore: '大于等于此价格的商品',
      priceRangeLess: '小于等于此价格的商品',
      titleWord: '',
      titleTyp: 'skuandtitle',
      brandWord: '',
      commentWord: this.$store.state.user.contentWord,
      price_min: this.$store.state.user.priceMin,
      price_max: this.$store.state.user.priceMax,
      brandOptions: [],
      brandLoading: false,
    }
  },
  methods: {
    open() {
      this.$message('请选择品牌')
    },
    changePlat(value) {
      this.showSearch = value
    },
    changeSkuTitle(value) {
      this.titleTyp = value
    },
    searchTitleWords() {
      const { plat, cid } = this.$route.query
      const titleParams = {
        title_word: this.titleWord,
        title_typ: this.titleTyp,
        plat: plat,
        cid: cid,
      }
      getTitleWord(titleParams).then((res) => {
        // 在返回结果有个code '-1'=商品关键词搜索  '-2'=商品链接搜索未找到商品 '0'=商品链接搜索返回item_id.
        if (res.status == 'ok') {
          const { result } = res
          if (res.result.code === '-1') {
            let query = _.cloneDeep(this.$route.query)
            let titleWord = []
            let query_title_word = []
            let query_title_typ = []
            let titleWordList = this.titleWord.split(',')
            let set = new Set(titleWordList)
            titleWordList = Array.from(set)
            for (let i = 0; i < titleWordList.length; i++) {
              const element = titleWordList[i]
              let titleWordItem = {
                word: element,
                title_typ: this.titleTyp,
              }
              query_title_word.push(element)
              query_title_typ.push(this.titleTyp)
              titleWord.push(titleWordItem)
            }
            this.$store.dispatch(
              'user/changeTitleWord',
              JSON.stringify(titleWord)
            )
            this.$store.dispatch('user/changeTitleTyp', this.titleTyp)
            query.title_word = query_title_word.join(',')
            query.title_typ = query_title_typ.join(',')
            this.$store.dispatch('user/changeActivedTab', 'Summary')
            this.$router.push({
              name: this.$route.name,
              query,
            })
          } else if (res.result.code === '0') {
            this.$eventBus.$emit('changeCatById', result.cid)
            this.$store.dispatch('user/changeItemId', result.item_id)
            // this.$store.dispatch('user/changeCatId', result.cid)
            this.$store.dispatch('user/changeActivedTab', 'Summary')
          } else if (res.result.code === '-2') {
            this.$message.error(
              '对不起，您搜索的”' +
                this.titleWord +
                '”找不到对应的商品，请检查您的输入后重试。'
            )
          }
        } else {
          this.$message.error(res.error_msg || '服务器错误，请截屏并联系客服')
        }
      })

      //   const numRe = new RegExp('^[-\\+]?([0-9]+\\.?)?[0-9]+$')
      //     const isTitleNumber = numRe.test(this.titleWord)
      //   if (isTitleNumber) {
      //     const { plat, cid } = this.$route.query
      //     const titleParams = {
      //       title_word: this.titleWord,
      //       plat: plat,
      //       cid: cid,
      //     }
      //     getTitleWord(titleParams).then((res) => {
      //       if (res.status == 'ok') {
      //         const { result } = res
      //         this.$eventBus.$emit('changeCatById', result.cid)
      //         this.$store.dispatch('user/changeItemId', result.item_id)
      //         this.$store.dispatch('user/changeCatId', result.cid)
      //         this.$store.dispatch('user/changeActivedTab', 'Summary')
      //       }
      //     })
      //   } else if (!isTitleNumber || this.titleWord !== '') {
      //     const titleWord = [
      //       {
      //         word: this.titleWord,
      //         title_typ: this.titleTyp,
      //       },
      //     ]
      //     this.$store.dispatch('user/changeTitleWord', JSON.stringify(titleWord))
      //     this.$store.dispatch('user/changeTitleTyp', this.titleTyp)
      //     this.$store.dispatch('user/changeActivedTab', 'Summary')
      //   }
    },
    searchCommentWords() {
      this.$store.dispatch('user/changeContentWord', this.commentWord)
      this.$store.dispatch('user/changeActivedTab', 'Summary')
    },
    searchPriceWords() {
      let query = _.cloneDeep(this.$route.query)
      this.$store.dispatch('user/changePriceMin', this.price_min)
      this.$store.dispatch('user/changePriceMax', this.price_max)
      query.price_min = this.price_min
      query.price_max = this.price_max
      this.$store.dispatch('user/changeActivedTab', 'Summary')
      this.$router.push({
        name: this.$route.name,
        query,
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.brandLoading = true
        const { plat, cid, start, end } = this.$route.query
        const params = {
          cat_id: cid,
          url_id: cid,
          plat: plat,
          parent_cat_id: this.$store.state.user.parentCatId,
          start: start,
          end: end,
          word: query,
          ts_type: this.tsType,
        }
        getBrand(params).then((res) => {
          this.brandLoading = false
          if (res.status == 'ok') {
            const { data } = res.result
            this.brandOptions = data.map((item) => {
              return {
                label: item.brand_name,
                value: item.brand_id,
              }
            })
          } else {
            this.brandOptions = []
          }
        })
      } else {
        this.brandOptions = []
      }
    },
    addBrandId(value) {
      this.brandWord = null
      this.$store.dispatch('user/changeBrandId', value)
      this.$store.dispatch('user/changeActivedTab', 'Summary')
    },
  },
  computed: {
    tsType() {
      return this.$store.state.user.tsType
    },
  },
  mounted() {
    this.$eventBus.$on('deleteTitleWord', (title_word) => {
      this.titleWord = title_word
    })
    this.$eventBus.$on('deleteBrandWord', () => {
      this.brandWord = null
    })
    this.$eventBus.$on('deleteContentWord', () => {
      this.commentWord = null
    })
    this.$eventBus.$on('deletePriceWord', () => {
      this.price_min = null
      this.price_max = null
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('deleteTitleWord')
    this.$eventBus.$off('deleteBrandWord')
    this.$eventBus.$off('deleteContentWord')
    this.$eventBus.$off('deletePriceWord')
  },
  watch: {
    '$route.query': {
      handler(newVal, oldVal) {
        if (newVal.title_word) {
          //刷新之后不会丢失
          this.titleWord = newVal.title_word
        }
      },
    },
  },
}
</script>

<style scoped lang="less">
.open-btn {
  width: 40px;
  height: 30px;
  line-height: inherit;
  border-radius: 0px;
  padding: 0px;
}
.open-btn:hover,
.open-btn:focus,
.open-btn:active {
  color: #ffffff;
  border-color: #dcdfe6;
  background-color: #ffffff;
}
.sku-title {
  font-size: 14px;
  color: #907c7c;
  margin-bottom: 4px;
  .sku-tip {
    display: inline-block;
    font-size: 12px;
    color: #85868a;
  }
}
.search {
  display: flex;
  position: relative;
  /deep/ .el-icon-search {
    font-size: 18px;
    color: #85868a;
  }
  .item-select {
    width: 75px;
    /deep/ input {
      height: 30px;
      font-size: 14px;
      color: #ffffff;
      background-color: #ff7d00;
      border-color: #ff7d00;
      border-radius: unset;
      padding: 0 10px;
    }
    /deep/ input::-webkit-input-placeholder {
      color: #ffffff;
    }
    /deep/ i {
      color: #ffffff;
    }
    /deep/ .el-select-dropdown__list {
      padding: 0;
    }
    /deep/ .popper__arrow {
      display: none;
    }
    /deep/ .el-popper {
      border: 1px solid #707070;
      border-radius: 0;
      margin-top: 0px;
      left: 0px !important;
    }
  }
  .price-range-input {
    display: flex;
    /deep/ input {
      width: 180px;
      height: 30px;
      border-radius: 0;
      padding: 0;
    }
    /deep/ .el-input__suffix {
      height: 30px;
      font-size: 12px;
      color: #85868a;
      background-color: #ffffff;
      border-radius: 0;
      border: 1px solid #ccc;
      padding: 0 5px;
      right: 0;
      .el-input__suffix-inner {
        line-height: 30px;
      }
    }
    /deep/ .el-input-group__append {
      background: #ffffff;
      border-radius: unset;
      padding: 0 10px;
    }
  }
  .search-input {
    width: 360px;
    margin-right: 10px;
    /deep/ input {
      height: 30px;
      line-height: 30px;
      border-radius: unset;
    }
    /deep/ .el-input-group__append {
      background: #ffffff;
      border-radius: unset;
      padding: 0 10px;
    }
  }
  .tool-item {
    position: absolute;
    z-index: 12;
    color: #85868a;
    line-height: 30px;
    right: 65px;
  }
}
.tophead-select-popper {
  .el-select-dropdown__item {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #ffffff;
    font-weight: normal;
    background-color: #ff7d00;
    padding: 0 10px;
  }
  .el-select-dropdown__item.selected {
    font-weight: normal;
    background-color: #ff7d00;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    color: #ffffff;
    font-weight: normal;
    background-color: #1798fb;
  }
}
</style>
