import { render, staticRenderFns } from "./CostReduce.vue?vue&type=template&id=093e7a53&scoped=true&"
import script from "./CostReduce.vue?vue&type=script&lang=js&"
export * from "./CostReduce.vue?vue&type=script&lang=js&"
import style0 from "./CostReduce.vue?vue&type=style&index=0&id=093e7a53&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "093e7a53",
  null
  
)

export default component.exports