import request from "@/utils/request";
import axios from "axios";
const BASE_API = process.env.VUE_APP_BASE_API;

export function getUserInfo() {
  return request({
    url: `${BASE_API}/api/user`,
    method: "get",
  });
}
export function smsSendLogin(data) {
  return request({
    url: `${BASE_API}/api/sms/send/login`,
    method: "post",
    data,
  });
}
export function smsSendSetting(data) {
  return request({
    url: `${BASE_API}/api/sms/send/setting`,
    method: "post",
    data,
  });
}
export function smsVerifySetting(data) {
  return request({
    url: `${BASE_API}/api/sms/verify/setting`,
    method: "post",
    data,
  });
}
export function smsVerifyLogin(data) {
  return request({
    url: `${BASE_API}/api/sms/verify/login`,
    method: "post",
    data,
  });
}
export function Cats() {
  return new Promise((resolve, reject) => {
    axios
      .get("/cats.json")
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
}

export function getUserSetting() {
  return request({
    url: `${BASE_API}/api/user/setting`,
    method: "get",
  });
}

export function putUserSetting(data) {
  return request({
    url: `${BASE_API}/api/user/setting`,
    method: "put",
    data: data
  });
}

export function getVisitCount(params) {
  return request({
    url: `${BASE_API}/api/sms/visitcount`,
    method: "get",
    params
  });
}