import i18n from "@/components/I18n";
// 图表label
const PIECHAR_TYPE = {
};

//平台类型
const PLANT_TYPE = {
};

// 原文链接地址
const POST_LINK = {
  xhs: "https://www.xiaohongshu.com/discovery/item/",
  weibo: "https://m.weibo.cn/status/",
  douyin: "https://www.douyin.com/video/",
};

export { PLANT_TYPE, PIECHAR_TYPE, POST_LINK };
