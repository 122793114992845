<template>
  <div class="date-picker-cpn">
    <el-select v-model="dateTime[0]"
               size="mini"
               @change="changeDate($event, 'start')"
               class="w120">
      <el-option v-for="(item,index) in timeList"
                 :key="index"
                 :label="item"
                 :value="item">
      </el-option>
    </el-select>
    <span style="margin: 0 10px">至</span>
    <el-select v-model="dateTime[1]"
               size="mini"
               @change="changeDate($event, 'end')"
               class="w120">
      <el-option v-for="(item,index) in timeList"
                 :key="index"
                 :label="item"
                 :value="item">
      </el-option>
    </el-select>
  </div>
</template>


<script>
import { getLatestTime } from '@/api/public.js'
import { build_ts, build_day_ts } from '@/utils'
import { DateTime } from 'luxon'
export default {
  name: 'DayPicker',
  data() {
    return {
      timeList: [],
      dateTime: ['', ''],
      plat: '',
    }
  },
  props: {
    currentTime: {
      type: Array,
      default: () => ['', ''],
    },
  },
  computed: {
    latestTime() {
      return this.$store.state.user.latestTime
    },
  },
  methods: {
    getLatestTimeMethod() {
      const currentPlatTime = this.latestTime[this.plat]
      const { tsType } = this.$store.state.user
      this.timeList = build_day_ts(currentPlatTime[1], currentPlatTime[0])
      this.$store.dispatch('user/changePlatTime', {
        start: currentPlatTime[0],
        end: currentPlatTime[1],
      })
      const { catTs } = this.$store.state.user
      if (catTs.length > 0) {
        let minTime = this.timeList[0]
        catTs.forEach((item) => {
          if (new Date(item).getTime() < new Date(minTime).getTime()) {
            minTime = item
          }
        })
        this.timeList = build_ts(minTime, currentPlatTime[0])
        this.$store.dispatch('user/changePlatTime', {
          end: minTime,
          start: currentPlatTime[0],
        })
      }
      const query = _.cloneDeep(this.$route.query)
      if (this.checkDateFormat(query.start) == 2) {
        this.dateTime = [query.start, query.end]
      } else if (tsType == 'week') {
        // this.dateTime = [this.timeList[6], this.timeList[0]]
        let obj = getWeekDate(this.timeList[0])
        this.dateTime = [obj.firstDayOfWeek, obj.lastDayOfWeek]
      } else if (tsType == 'day') {
        const lastDay =
          this.timeList[0].split('-')[0] +
          '-' +
          this.timeList[0].split('-')[1] +
          '-' +
          '01'
        this.dateTime = [lastDay, this.timeList[0]]
      }
      query.start = this.dateTime[0]
      query.end = this.dateTime[1]
      this.$store.dispatch('user/changeStart', query.start)
      this.$store.dispatch('user/changeEnd', query.end)
      this.$router.push({
        name: this.$route.name,
        query,
      })
    },
    getWeekDate(dateString) {
      // 创建日期时间实例
      const date = DateTime.fromISO(dateString).setZone('Asia/Shanghai')
      // 获取给定周的第一天和最后一天
      const firstDayOfWeek = date.startOf('week').toISODate()
      const lastDayOfWeek = date.endOf('week').toISODate()
      return { firstDayOfWeek, lastDayOfWeek }
    },
    init() {
      const { tsType, platTime } = this.$store.state.user
      if (platTime.start && platTime.end) {
        this.timeList = build_day_ts(platTime.end, platTime.start)
        if (tsType == 'week') {
          this.dateTime = [this.timeList[6], this.timeList[0]]
        } else if (tsType == 'day') {
          const lastDay =
            this.timeList[0].split('-')[0] +
            '-' +
            this.timeList[0].split('-')[1] +
            '-' +
            '01'
          this.dateTime = [lastDay, this.timeList[0]]
        }
        const query = _.cloneDeep(this.$route.query)
        query.start = this.dateTime[0]
        query.end = this.dateTime[1]
        this.$store.dispatch('user/changeStart', query.start)
        this.$store.dispatch('user/changeEnd', query.end)
        this.$router.push({
          name: this.$route.name,
          query,
        })
      }
    },
    changeCatTs(times) {
      this.catTs = times
      if (times.length > 0) {
        let minTime = this.timeList[0]
        times.forEach((item) => {
          if (new Date(item).getTime() < new Date(minTime).getTime()) {
            minTime = item
          }
        })
        const { platTime, latestTime, parentCatId, catId } =
          this.$store.state.user
        const latestTs =
          latestTime[this.plat][2][parentCatId == 0 ? catId : parentCatId]
        if (latestTs) {
          const time = latestTs[1]
          if (new Date(time).getTime() < new Date(minTime).getTime()) {
            minTime = time
          }
        }
        this.timeList = build_ts(minTime, platTime.start)
        const query = _.cloneDeep(this.$route.query)
        if (query.start && query.end) {
          this.dateTime = [query.start, query.end]
        } else {
          this.dateTime = [this.timeList[0], this.timeList[0]]
          query.start = this.dateTime[0]
          query.end = this.dateTime[0]
        }
        this.$store.dispatch('user/changeStart', query.start)
        this.$store.dispatch('user/changeEnd', query.end)
        this.$store.dispatch('user/changePlatTime', {
          start: this.dateTime[0],
          end: this.dateTime[1],
        })
        this.$router.push({
          name: this.$route.name,
          query,
        })
      }
    },

    checkDateFormat(dateString) {
      // 使用正则表达式来匹配两种可能的日期格式
      const regex1 = /^\d{4}-\d{2}$/
      const regex2 = /^\d{4}-\d{2}-\d{2}$/
      if (regex1.test(dateString)) {
        // 匹配第一种格式 'xxxx-xx'
        return 1
      } else if (regex2.test(dateString)) {
        // 匹配第二种格式 'xxxx-xx-xx'
        return 2
      } else {
        // 未匹配任何格式
        return 'invalid'
      }
    },
  },
  created() {
    this.getTime = _.debounce(this.getLatestTimeMethod, 300)
    if (this.$route.query.plat) {
      this.plat = this.$route.query.plat
      this.getTime()
    }
  },
  watch: {
    '$route.query': {
      handler(newVal, oldVal) {
        if (newVal.plat && oldVal.plat !== newVal.plat) {
          this.plat = newVal.plat
        }
        this.getTime()
      },
    },
    '$store.state.user.tsType': {
      handler(newVal) {
        this.init()
      },
    },
    '$store.state.user.catTs': {
      handler(value) {
        this.changeCatTs(value)
      },
    },
    dateTime(value) {
      this.$emit('on-changeDateTime', value)
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style lang="less" scoped>
.w120 {
  width: 120px;
}
</style>
