<template>
  <div class="question-select-area"
       v-if="isQuestionUser">
    <el-select size="mini"
               v-model="filterValue"
               style="width: 80px; margin-right: 10px"
               @change="filterChange">
      <el-option v-for="item in filterList"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value"></el-option>
    </el-select>
    <el-select v-if="filterValue == 'question'"
               size="mini"
               v-model="questionValue"
               style="width: 120px; margin-right: 10px"
               @change="questionChange">
      <el-option v-for="item in questionList"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value"></el-option>
    </el-select>
  </div>
</template>


<script>
export default {
  name: 'QuestionSelect',
  data() {
    return {
      filterValue: 'comment',
      filterList: [
        {
          label: '评论',
          value: 'comment',
        },
        {
          label: '问答',
          value: 'question',
        },
      ],
      questionValue: 'questionTime',
      questionList: [
        {
          label: '问答时间',
          value: 'questionTime',
        },
        {
          label: '回答时间',
          value: 'answerTime',
        },
      ],
    }
  },
  props: {},
  computed: {
    isQuestionUser() {
      if (
        this.$store.state.user.loginInfo.is_question_user &&
        (this.$route.query.plat == 'jd' || this.$route.query.plat == 'tmall')
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    filterChange(val) {
      if (val == 'question') {
        localStorage.setItem('is_question', true)
        window.location.reload()
      } else {
        localStorage.setItem('is_question', false)
        window.location.reload()
      }
    },
    questionChange(val) {
      if (val == 'questionTime') {
        this.$store.dispatch('user/changeQuestionTimeTyp', 'question')
      } else {
        this.$store.dispatch('user/changeQuestionTimeTyp', 'answer')
      }
    },
  },
  created() {
    const is_question = JSON.parse(localStorage.getItem('is_question'))
    if (is_question) {
      this.filterValue = 'question'
    } else {
      this.filterValue = 'comment'
    }
  },
}
</script>

<style lang="less" scoped>
.question-select-area {
}
</style>