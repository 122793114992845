<template>
  <div class="date-picker-cpn">
    <el-select v-model="dateTime[0]"
               size="mini"
               @change="changeDate($event, 'start')"
               class="w120">
      <el-option v-for="(item,index) in timeList"
                 :key="index"
                 :label="item"
                 :value="item">
      </el-option>
    </el-select>
    <span style="margin: 0 10px">至</span>
    <el-select v-model="dateTime[1]"
               size="mini"
               @change="changeDate($event, 'end')"
               class="w120">
      <el-option v-for="(item,index) in timeList"
                 :key="index"
                 :label="item"
                 :value="item">
      </el-option>
    </el-select>
  </div>
</template>


<script>
import { getLatestTime } from '@/api/public.js'
import { build_ts } from '@/utils'
export default {
  name: 'DatePicker',
  data() {
    return {
      timeList: [],
      dateTime: ['', ''],
      plat: '',
      catTs: [],
    }
  },
  props: {
    currentTime: {
      type: Array,
      default: () => ['', ''],
    },
  },
  computed: {
    latestTime() {
      return this.$store.state.user.latestTime
    },
  },
  methods: {
    getLatestTimeMethod() {
      const currentPlatTime = this.latestTime[this.plat]
      this.timeList = build_ts(currentPlatTime[1], currentPlatTime[0])
      this.$store.dispatch('user/changePlatTime', {
        start: currentPlatTime[0],
        end: currentPlatTime[1],
      })
      const { catTs, start, end } = this.$store.state.user
      if (catTs.length > 0) {
        let minTime = this.timeList[0]
        catTs.forEach((item) => {
          if (new Date(item).getTime() < new Date(minTime).getTime()) {
            minTime = item
          }
        })
        this.timeList = build_ts(minTime, currentPlatTime[0])
        this.$store.dispatch('user/changePlatTime', {
          end: minTime,
          start: currentPlatTime[0],
        })
      }
      const query = _.cloneDeep(this.$route.query)
      if (query.start && query.end) {
        this.dateTime = [query.start, query.end]
      } else {
        this.dateTime = [this.timeList[0], this.timeList[0]]
        query.start = this.dateTime[0]
        query.end = this.dateTime[0]
      }
      this.$store.dispatch('user/changeStart', query.start)
      this.$store.dispatch('user/changeEnd', query.end)
      this.$router.push({
        name: this.$route.name,
        query,
      })
    },
    changeDate(val, type) {},
    init() {
      const { platTime } = this.$store.state.user
      if (platTime.start && platTime.end && this.catTs.length == 0) {
        this.timeList = build_ts(platTime.end, platTime.start)
        this.dateTime = [this.timeList[0], this.timeList[0]]
        const query = _.cloneDeep(this.$route.query)
        query.start = this.dateTime[0]
        query.end = this.dateTime[0]
        this.$store.dispatch('user/changeStart', query.start)
        this.$store.dispatch('user/changeEnd', query.end)
        this.$router.push({
          name: this.$route.name,
          query,
        })
      }
    },
    changeCatTs(times) {
      this.catTs = times
      if (times.length > 0) {
        let minTime = this.timeList[0]
        times.forEach((item) => {
          if (new Date(item).getTime() < new Date(minTime).getTime()) {
            minTime = item
          }
        })
        const { platTime, latestTime, parentCatId, catId } =
          this.$store.state.user
        const latestTs =
          latestTime[this.plat][2][parentCatId == 0 ? catId : parentCatId]
        if (latestTs) {
          const time = latestTs[1]
          if (new Date(time).getTime() < new Date(minTime).getTime()) {
            minTime = time
          }
        }
        this.timeList = build_ts(minTime, platTime.start)
        this.dateTime = [this.timeList[0], this.timeList[0]]
        const query = _.cloneDeep(this.$route.query)
        query.start = this.dateTime[0]
        query.end = this.dateTime[0]
        this.$store.dispatch('user/changeStart', query.start)
        this.$store.dispatch('user/changeEnd', query.end)
        this.$store.dispatch('user/changePlatTime', {
          start: this.dateTime[0],
          end: this.dateTime[1],
        })
        this.$router.push({
          name: this.$route.name,
          query,
        })
      }
    },
  },
  created() {
    this.getTime = _.debounce(this.getLatestTimeMethod, 300)
    if (this.$route.query.plat) {
      this.plat = this.$route.query.plat
      this.getTime()
    }
  },
  mounted() {
    if (this.$store.state.user.platTime.start) {
      this.init()
    }
  },
  watch: {
    '$route.query': {
      handler(newVal, oldVal) {
        if (newVal.plat && oldVal.plat !== newVal.plat) {
          this.plat = newVal.plat
        }
        this.getTime()
      },
    },
    '$store.state.user.catTs': {
      handler(value) {
        this.changeCatTs(value)
      },
    },
    dateTime(value) {
      this.$emit('on-changeDateTime', value)
    },
  },
}
</script>

<style lang="less" scoped>
.w120 {
  width: 120px;
}
</style>
