<template>
  <span class="navTab-rename">
    <span v-if="showInput">
      <span @click.stop="">
        <el-input class="tab-name-input"
                  :placeholder="inputItem.title"
                  v-model="tabNameValue" />
        <i class="el-icon-circle-check"
           @click="modifyTabName(inputItem)" />
        <i class="el-icon-circle-close"
           @click="closeInput(inputItem)" />
      </span>
    </span>
    <span v-else
          class="icon-box">
      <i v-if="inputItem.rule_id&&inputItem.custom_typ == 'title'"
         class="el-icon-star-on"
         style="color:red;"></i>
      <i v-if="inputItem.rule_id&&inputItem.custom_typ == 'product'"
         class="el-icon-star-on"
         style="color:#45A6EC;"></i>
      <i v-if="inputItem.rule_id&&inputItem.custom_typ != 'title'&&inputItem.custom_typ != 'product'&&inputItem.analyse_typ == ''&&inputItem.ner_type != 'DrivingFactors'"
         class="el-icon-star-on"
         style="color:#fed141;"></i>
      <i v-if="inputItem.origin_user"
         class="el-icon-star-on"
         style="color:#ff7d00;"></i>
      <span>{{inputItem.title}}</span>
      <span class="icon-show-box"
            @click.stop="">
        <i v-if="inputItem.rule_id&&inputItem.rule_id != 'a390cc28-1ea9-4050-8120-cfd72dc4adf8'"
           class="el-icon-edit"
           @click="tabRename(inputItem)" />
        <i v-if="inputItem.title != '自定义分析'"
           class="el-icon-remove"
           @click="hideTab(inputItem)" />
      </span>
    </span>
  </span>
</template>

<script>
export default {
  data() {
    return {
      showInput: false,
      hideTabList: _.cloneDeep(this.hideList),
      tabNameValue: _.cloneDeep(this.item.title)
        ? _.cloneDeep(this.item.title)
        : '',
    }
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    hideList: {
      type: Array,
      default: [],
    },
  },
  computed: {
    inputItem() {
      return _.cloneDeep(this.item)
    },
  },
  methods: {
    modifyTabName(inputItem) {
      const titleParam = {
        title: {
          from: inputItem.title,
          name: inputItem.rule_id,
          to: this.tabNameValue,
        },
      }
      this.$emit('modifyTabName', titleParam, this.tabNameValue)
      this.$eventBus.$emit('modifyTabName', titleParam, this.tabNameValue)
    },
    closeInput() {
      this.showInput = false
    },
    tabRename() {
      this.showInput = true
    },
    deleteHideTabs(inputItem) {
      this.$emit('deleteHideTabs', inputItem)
    },
    hideTab(inputItem) {
      const { plat } = this.$store.state.user
      const hideParam = {
        hide: {
          from: '',
          name: inputItem.name,
          to: true,
        },
      }
      this.$emit('hideTab', hideParam)
    },
  },
}
</script>

<style lang="less" scoped>
.navTab-rename {
  .tab-name-input {
    display: inline-block;
    width: 100px;
  }
  .el-icon-circle-check {
    color: #85868a;
    margin: 0 5px;
  }
  .el-icon-circle-close {
    color: #85868a;
  }
  .icon-box:hover > .icon-show-box > .el-icon-edit {
    display: inline-block;
    margin: 0 2px 0 4px;
  }
  .icon-box:hover > .icon-show-box > .el-icon-remove {
    display: inline-block;
  }
  .el-icon-edit {
    display: none;
  }
  .el-icon-remove {
    display: none;
  }
}
</style>