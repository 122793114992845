<template>
  <div>
    <el-dialog :visible.sync="dialogVisible"
               custom-class="vip-dialog"
               @close="close"
               width="50%">
      <template v-if="showContent">
        <slot name="header">
          <div style="font-size:16px">您的提问额度已用完~</div>
          <div style="font-size:16px">即刻充值,继续和镜界AI一起高效洞察市场!</div>
          <div class="document-area">
            <el-link href="https://qmctsz2s8t.feishu.cn/docx/NdaOdC8MPo4gi8xk76ZcXD3Unre"
                     type="primary"
                     target="_blank">《镜界AI使用指南》</el-link>
          </div>
        </slot>
        <slot>
          <div class="recharge-area">
            <div class="recharge-card"
                 v-for="item in writingList"
                 :key="item.id"
                 :class="{active: activeId == item.id}"
                 @click="cardChange(item.id)">
              <div class="card-area">
                <div class="title">{{item.title}}</div>
                <div class="price">
                  <span class="unit">{{item.unit}}</span>
                  <span class="number">{{item.number}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="QR-code-area">
            <el-radio-group v-model="tab"
                            size="small"
                            style="margin-bottom: 10px;">
              <el-radio-button label="wechat"><i class="iconfont icon-weixinzhifu"
                   style="margin-right: 5px;font-size: 14px"></i><span style="height: 16px;line-height: 16px">扫码使用微信支付</span></el-radio-button>
              <!-- <el-radio-button label="alipay"><i class="iconfont icon-zhifubaozhifu"
                   style="margin-right: 5px;font-size: 14px"></i><span style="height: 16px;line-height: 16px">支付宝</span></el-radio-button> -->
            </el-radio-group>
            <div class="content"
                 v-loading="QRLoading">
              <div id="wechat-qrcode"
                   v-show="tab == 'wechat' && agreementChecked"
                   width="100px"
                   alt="微信付款码">
              </div>
              <img :src="qrCode"
                   alt=""
                   style="width: 100px; height: 100px"
                   v-show="!agreementChecked">
              <!-- <img id="alipay-qrcode"
                   v-show="tab == 'alipay'"
                   width="100px"
                   src="../assets/QRCode.png"
                   alt="支付宝付款码"> -->
            </div>
          </div>
        </slot>
        <slot name="footer">
          <div class="product-area">
            <div style="margin-bottom: 20px">
              <span style="margin-right: 10px">产品文档</span>
              <el-link href="https://qmctsz2s8t.feishu.cn/docx/NdaOdC8MPo4gi8xk76ZcXD3Unre"
                       type="primary"
                       target="_blank"><i class="iconfont icon-baogao-copy"></i>【魔镜·镜界AI】产品使用文档</el-link>
            </div>
            <el-popover placement="bottom-start"
                        trigger="hover">
              <div style="width: 200px; height:200px">
                <img src="../assets/JessieCode.jpeg"
                     alt=""
                     style="width: 100%;height: 100%">
              </div>
              <span class="text"
                    slot="reference">对产品有疑问？联系镜界AI产品经理</span>
            </el-popover>
            <div class="footer-area"
                 style="margin-top: 10px">
              <el-checkbox v-model="agreementChecked">勾选即代表同意会员服务协议
                <el-link href="https://ai.mktindex.com/member.txt"
                         style="height: 20px;line-height: 20px"
                         type="primary"
                         target="_blank">《镜界AI会员服务协议》</el-link>
              </el-checkbox>
            </div>
          </div>
        </slot>
      </template>
      <template v-if="showSuccess">
        <div class="payment-success">
          <h1 style="color:#4caf50"><i class="el-icon-circle-check"></i>支付成功!</h1>
          <p>立刻继续和镜界AI一起高效洞察市场，把握商机吧！</p>
          <p> 您可以随时在页面左下角“设置”中查看账号到期时间和问题记录~
          </p>
          <!-- <div class="order-details">
            <h2>订单详情</h2>
            <ul>
              <li><strong>订单编号:</strong> {{}}</li>
              <li><strong>日期:</strong> 2024年5月21日</li>
              <li><strong>金额:</strong> ¥150.00</li>
            </ul>
          </div> -->
          <div class="success-actions">
            <button @click="goToHome">我知道了</button>
          </div>
        </div>
      </template>
      <template v-if="showFail">
        <div class="payment-failure">
          <h1 style="color: #f44336"><i class="el-icon-circle-close"></i>支付失败</h1>
          <p>很遗憾，您的付款无法处理。请再试一次。</p>
          <!-- <div class="error-details">
            <h2>错误详情</h2>
            <p>错误码: 1234</p>
            <p>如果问题仍然存在，请联系客户支持。</p>
          </div> -->
          <div class="actions">
            <button @click="retryPayment">重试</button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import QRCode from 'qrcode'
export default {
  name: 'VipDialog',
  data() {
    return {
      dialogVisible: false,
      activeId: 0,
      // 付费选项
      writingList: [],
      QRLoading: false,
      tab: 'wechat',
      showContent: true,
      showSuccess: false,
      showFail: false,
      payId: '',
      payInterval: '',
      // 协议同意
      agreementChecked: true,
      qrCode: require('../assets/qr-code-ma.png'),
    }
  },
  props: {
    getOrderListPromise: {
      type: Function,
      default: () => null,
    },
    getPayDetail: {
      type: Function,
      default: () => null,
    },
    queryPay: {
      type: Function,
      default: () => null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
  },
  computed: {
    loginInfo() {
      return this.$store.state.user.loginInfo
    },
  },
  methods: {
    cardChange(id) {
      this.activeId = id
      this.QRLoading = true
      const host = window.location.hostname
      const domain = host.split('.')[0]
      this.getPayDetail({
        order_type: id,
        order_id: this.orderId,
        username: this.loginInfo.username,
        app_id: domain,
      })
        .then((res) => {
          if (res.status == 'ok') {
            const result = res.result
            this.generateQR('wechat', result.code_url)
            this.payStatusInterval()
            this.QRLoading = false
          }
        })
        .catch((err) => {
          this.QRLoading = false
          console.log(err)
        })
    },
    generateQR(plat, url) {
      switch (plat) {
        case 'wechat': {
          const existingCanvas = document.getElementById('wechat-canvas')
          if (existingCanvas) {
            existingCanvas.remove()
          }
          const canvas = document.createElement('canvas')
          canvas.id = 'wechat-canvas'
          QRCode.toCanvas(canvas, url, { width: 100 }, (error) => {
            if (error) console.error(error)
            document.getElementById('wechat-qrcode').appendChild(canvas)
          })
          break
        }
      }
    },
    // 支付状态轮询
    payStatusInterval(payId) {
      if (this.payInterval) {
        clearInterval(this.payInterval)
        this.payInterval = ''
      }
      this.payInterval = setInterval(() => {
        this.queryPay({
          order_id: this.orderId,
          username: this.loginInfo.username,
        })
          .then((res) => {
            if (res.status == 'ok') {
              const result = res.result
              const tradeState = result.state
              if (tradeState == 'SUCCESS') {
                this.changePayStatus('success')
              }
            }
          })
          .catch((err) => {
            this.changePayStatus('error')
          })
      }, 500)
      // setTimeout(() => {
      //   this.changePayStatus('error')
      // }, 10000)
    },
    changePayStatus(type) {
      switch (type) {
        case 'success':
          {
            clearInterval(this.payInterval)
            this.payInterval = ''
            this.showSuccess = true
            this.showContent = false
            this.showFail = false
          }
          break
        case 'error':
          {
            clearInterval(this.payInterval)
            this.payInterval = ''
            this.showSuccess = false
            this.showContent = false
            this.showFail = true
          }
          break
      }
    },
    goToHome() {
      this.dialogVisible = false
    },
    retryPayment() {
      this.dialogVisible = false
      setTimeout(() => {
        this.dialogVisible = true
      }, 500)
    },
    close() {
      this.showContent = true
      this.showSuccess = false
      this.showFail = false
      this.$emit('payDialogClose')
    },
  },
  watch: {
    visible(newVal) {
      this.dialogVisible = newVal
    },
    tab(newVal) {
      console.log(newVal)
    },
    dialogVisible: {
      handler(newVal) {
        const host = window.location.hostname
        const domain = host.split('.')[0]
        if (newVal) {
          this.QRLoading = true
          if (this.getOrderListPromise) {
            this.getOrderListPromise({
              order_id: this.orderId,
              username: this.loginInfo.username,
              app_id: domain,
            })
              .then((res) => {
                this.QRLoading = false
                if (res.status == 'ok') {
                  const result = res.result
                  this.writingList = result.order_type_list.map((item) => {
                    const temp = {
                      unit: '¥',
                      title: item.desc,
                      number: (item.amount / 100).toFixed(2),
                      amount: item.amount,
                      id: item.order_type,
                    }
                    return temp
                  })
                  this.activeId = this.writingList[0].id
                  // this.payId = this.writingList[0].id
                  this.payStatusInterval()
                  this.generateQR('wechat', result.code_url)
                }
              })
              .catch((err) => {
                this.QRLoading = false
              })
          }
        } else {
          if (this.payInterval) {
            clearInterval(this.payInterval)
            this.payInterval = ''
          }
          this.showContent = true
          this.showSuccess = false
          this.showFail = false
        }
      },
      immediate: true,
    },
    payId: {
      handler(newVal) {
        if (newVal) {
          this.payStatusInterval(newVal)
        }
      },
      immediate: true,
    },
  },
  created() {},
}
</script>

<style lang="less">
.el-dialog__wrapper {
  .vip-dialog {
    border-radius: 30px;
    width: 100%;
    .el-dialog__body {
      // border-radius: 50%;
      .recharge-area {
        margin-top: 20px;
        display: flex;
        .recharge-card {
          background: #fefaee;
          border: 1px solid #f0c992;
          border-radius: 12px;
          cursor: pointer;
          height: 140px;
          position: relative;
          width: 165px;
          margin-left: 20px;
          .card-area {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
          }
          .title {
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            background-image: linear-gradient(
              281.06deg,
              #813803 4.09%,
              #58290a 117.55%
            );
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 4px;
            text-align: center;
          }
          .price {
            margin-bottom: 4px;
            text-align: center;
            .unit {
              color: #632d09;
              font-size: 18px;
              font-weight: 500;
              line-height: 25px;
              margin-right: 2px;
            }
            .number {
              -webkit-text-fill-color: transparent;
              background-clip: text;
              -webkit-background-clip: text;
              background-image: linear-gradient(270deg, #793505, #5d2b09);
              font-family: vipNumber !important;
              font-size: 34px;
              font-weight: 500;
              line-height: 40px;
            }
          }
        }
      }
      .recharge-area .active {
        background: linear-gradient(
          288.44deg,
          #eabc88 -18.63%,
          #ebbf8d -11.17%,
          #fadbaf 1.29%,
          #fff1d4 54.41%,
          #fffefa 116.17%
        );
      }
      .document-area {
        // margin-left: 5px;
        margin-top: 10px;
      }
      .product-area {
        margin-top: 20px;
        .text {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .QR-code-area {
        background: rgba(245, 246, 247, 0.698);
        border-radius: 8px;
        color: #676890;
        height: 140px;
        padding: 12px;
        width: 90%;
        margin-top: 20px;
        .tabs {
          display: flex;
          margin-bottom: 20px;
          .tab {
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 8px;
            cursor: pointer;
            margin-left: 20px;
          }

          .tab.active {
            background: #6182f5;
            color: #fff;
          }
        }
        .content {
          width: 100px;
        }
      }
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #606266;
    }
  }
}
.vip-dialog {
  .payment-success {
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  .success-icon {
    font-size: 100px;
    color: #4caf50;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .order-details {
    text-align: left;
    margin: 20px 0;
  }
  .order-details h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .order-details ul {
    list-style: none;
    padding: 0;
  }
  .order-details li {
    margin-bottom: 5px;
  }
  .success-actions {
    margin-top: 20px;
  }
  .success-actions button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: 5px;
  }
  .success-actions button:hover {
    background-color: #45a049;
  }
  .payment-failure {
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  .failure-icon {
    font-size: 100px;
    color: #f44336;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .error-details {
    text-align: left;
    margin: 20px 0;
  }
  .error-details h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .actions {
    margin-top: 20px;
  }
  .actions button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: 5px;
  }
  .actions button:hover {
    background-color: #d32f2f;
  }
}
</style>
