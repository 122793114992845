import request from '@/utils/request'
import { formateObjToParamStr } from "@/utils";
const BASE_API = process.env.VUE_APP_BASE_API
const REAL_BASE_API =
	process.env.NODE_ENV === 'development'
		? process.env.VUE_APP_REAL_BASE_API
		: process.env.VUE_APP_BASE_API
const LOGIN_API = process.env.VUE_APP_LOGIN_API
export function getItemsList(urlParam, param) {
	return request({
		url:
			`${BASE_API}/api/linsten/platform/` +
			urlParam.plat +
			`/cats/` +
			urlParam.cid +
			'/brands/' +
			urlParam.bid +
			'/top_items',
		method: 'get',
		params: param,
	})
}

export function getTitleWord(param) {
  return request({
    url: `${BASE_API}/api/linsten/search/item`,
    method: 'get',
    params: param
  })
}

export function postItemId(data) {
  return request({
    url: `${BASE_API}/api/linsten/item_url_id`,
    method: 'post',
    data
  })
}

export function getItemsListDataUrl(urlParam, param) {
  return `${BASE_API}/api/linsten/platform/` + urlParam.platform + `/cats/` + urlParam.cats + `/brands/` + urlParam.brands + `/top_items?` + formateObjToParamStr(param);
}