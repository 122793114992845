import request from '@/utils/request'
import { formateObjToParamStr, formateFenxiParamStr } from '@/utils'
// import { encryptByDES } from '@/utils/crypto'
import qs from 'qs'
import axios from 'axios'
const BASE_API = process.env.VUE_APP_BASE_API
const REAL_BASE_API =
	process.env.NODE_ENV === 'development'
		? process.env.VUE_APP_REAL_BASE_API
		: process.env.VUE_APP_BASE_API
const LOGIN_API = process.env.VUE_APP_LOGIN_API
const FENXI_HOST = 'https://fenxiplus.mktindex.com'
export function getWordTs(param) {
	return request({
		url: `${BASE_API}/api/get_word_ts`,
		method: 'get',
		params: param,
	})
}
export function getJdWordTs(param) {
	return request({
		url: `${BASE_API}/api/get_jd_ts`,
		method: 'get',
		params: param,
	})
}
export function getTbJdWordTs(param) {
	return request({
		url: `${BASE_API}/api/get_tbjd_ts`,
		method: 'get',
		params: param,
	})
}
export function getVolumeTable(param) {
	return request({
		url: `${BASE_API}/api/ecommerce`,
		method: 'post',
		data: param,
	})
}

export function setMarked(data) {
	return request({
		url: `${BASE_API}/api/marked`,
		method: 'post',
		data,
	})
}
export function getMarked(param) {
	return request({
		url: `${BASE_API}/api/marked`,
		method: 'get',
		params: param,
	})
}
export function getSearchMarked(param) {
	return request({
		url: `${BASE_API}/api/search_marked`,
		method: 'post',
		data: param,
	})
}
export function getSearchData(param) {
	return request({
		url: `${BASE_API}/api/search`,
		method: 'post',
		data: param,
	})
}

export function getVolumeDataUrl(param) {
	return `${BASE_API}/api/ecommerce?` + formateObjToParamStr(param)
}
//获取top类目下载地址
export function getCategoryDataUrl(param) {
	return `${FENXI_HOST}/api/dwfcat2?` + formateFenxiParamStr(param)
}
//获取top品牌下载地址
export function getBrandDataUrl(param) {
	return `${FENXI_HOST}/api/dwtopbrands?` + formateFenxiParamStr(param)
}
//获取top商品下载地址
export function getCommodityDataUrl(param) {
	return `${FENXI_HOST}/api/dwtopitems?` + formateFenxiParamStr(param)
}
export function getCloudData(param) {
	return request({
		url: `${BASE_API}/api/cloud`,
		method: 'get',
		params: param,
	})
}
// 商品标题词云图
export function getTitleCloudData(param) {
	return request({
		url: `${BASE_API}/api/itemcloud`,
		method: 'post',
		data: param,
	})
}
export function getECloudData(data) {
	return request({
		url: `${BASE_API}/api/ecloud`,
		method: 'post',
		data,
	})
}
export function getSocialData(param) {
	return request({
		url: `${BASE_API}/api/social`,
		method: 'get',
		params: param,
	})
}
export function getPieChartData(param) {
	return request({
		url: `${BASE_API}/api/latest_social`,
		method: 'get',
		params: param,
	})
}
export function getLineBarChartDataUrl(param) {
	return `${REAL_BASE_API}/api/search?` + formateObjToParamStr(param)
}
export function getPhone(param) {
	return request({
		url: `${BASE_API}/api/phone/setting`,
		method: 'get',
		params: param,
	})
}
export function setPhone(data) {
	return request({
		url: `${BASE_API}/api/phone/setting`,
		method: 'PUT',
		data,
	})
}
export function deletePhone(data) {
	return request({
		url: `${BASE_API}/api/phone/setting`,
		method: 'DELETE',
		data,
	})
}

export function getCatName(data) {
	return request({
		url: `${BASE_API}/api/get_catname`,
		method: 'post',
		data,
	})
}

export function queryCatNum(data) {
	return request({
		url: `${BASE_API}/api/cat_num`,
		method: 'post',
		data,
	})
}

export function getDwNotice(param) {
	return request({
		url: `${BASE_API}/api/dw_notice`,
		method: 'get',
		params: param,
	})
}

export function getNav(urlParam, param) {
	return request({
		url:
			`${BASE_API}/api/linsten/platform/` + urlParam.plat + `/navigation`,
		method: 'get',
		params: param,
	})
}

export function putNav(plat, urlParam, param) {
	return request({
		url:
			`${BASE_API}/api/linsten/platform/` +
			plat +
			`/navigation?` +
			formateObjToParamStr(urlParam),
		method: 'put',
		data: param,
	})
}

export function getAttention(params) {
	return request({
		url: `${BASE_API}/api/linsten/attention`,
		method: 'get',
		params,
	})
}

export function putAttention(params) {
	return request({
		url: `${BASE_API}/api/linsten/attention`,
		method: 'put',
		data: params,
	})
}

export function getLatestTime(params) {
	return request({
		url: `${BASE_API}/api/latesttime`,
		method: 'get',
		params,
	})
}

// 用户对功能模块的访问日志统计
export function getModuleVisit(params) {
	return request({
		url: `${BASE_API}/api/sms/modulecount`,
		method: 'get',
		params,
	})
}

export function putCatSetting(params) {
	return request({
		url: `${BASE_API}/api/user/setting`,
		method: 'put',
		data: params,
	})
}

export function clientError(data) {
	return request({
		url: `${BASE_API}/api/client_error`,
		method: 'post',
		data,
	})
}