import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const originalpush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalpush.call(this, location).catch((err) => err)
}

const routes = [
	{
		path: '/',
		redirect: '/catsheetnew',
	},
	{
		// path: '/catsheet/:plat/:cid/:brand/:item_id/:shop/:title_word/:content_word/:words/:analyse_typ/:aspect_type/:rule_id/:label_level/:title_typ/:price_min/:price_max/:item_analyse/:active_rule_id/:target_typ/:fx_url_id/:start/:end',
		path: '/catsheet/:pathMatch(.*)*',
		name: 'redirectCpn',
		component: () =>
			import('@/views/catsheet/components/redirectCpn/redirectCpn.vue'),
		pathToRegexpOptions: { strict: false, sensitive: false },
	},
	{
		path: '/catsheetnew',
		name: 'catSheet',
		component: () => import('@/views/catsheet/CatSheet.vue'),
	},

	{
		path: '/contrastAnalysis',
		name: 'contrastAnalysis',
		component: () => import('@/views/contrastAnalysis.vue'),
	},
	{
		// "/contrastAnalysis/:plat/:cid/:urlIds/:contrast_id/:start/:end"
		path: '/contrastAnalysis/:pathMatch(.*)*',
		component: () =>
			import('@/views/catsheet/components/dashboard/dashboard.vue'),
		pathToRegexpOptions: { strict: false, sensitive: false },
	},

	{
		path: '/subscription',
		name: 'subscription',
		component: () => import('@/views/subscription.vue'),
	},

	{
		path: '/marked',
		name: 'marked',
		component: () => import('@/views/marked.vue'),
	},

	{
		path: '/custom',
		name: 'custom',
		component: () => import('@/views/custom.vue'),
	},

	{
		path: '/setUp',
		name: 'setUp',
		component: () => import('@/views/setUp.vue'),
	},
	{
		path: '/settings',
		name: 'settings',
		component: () => import('@/views/setUp.vue'),
	},
]

const router = new VueRouter({
	mode: 'hash',
	routes,
})
router.beforeEach(async (to, from, next) => {
	if (to.path.includes('/search')) {
		store.dispatch('user/changeShowMainHead', false)
	} else {
		store.dispatch('user/changeShowMainHead', true)
	}
	next()
})

export default router
