import Vue from "vue";
import VueI18n from "vue-i18n";
import enElement from "element-ui/lib/locale/lang/en";
import zhElement from "element-ui/lib/locale/lang/zh-CN";
import zhLocal from "@/common/lang/zh";
import enLocal from "@/common/lang/en";
Vue.use(VueI18n);
const messages = {
  en: {
    ...enLocal,
    ...enElement,
  },
  zh: {
    ...zhLocal,
    ...zhElement,
  },
};
const i18n = new VueI18n({
  locale: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "zh", // "zh", //getCookie('PLAY_LANG', 'zh'), // 语言标识
  messages,
});

export default i18n;
