// 注意：一定是 exports，不是 export，否则会报错，报错信息是下列的中的内容不是 string
module.exports = {
	topHead: {
		login_label: '登录',
		login_out: '退出',
	},
	mainHead: {
		tabs_Summary: '基本信息',
		tabs_EachSound: '方面&观点',
		tabs_TopBrand: '声量TOP100品牌',
		tabs_TopShop: '店铺分析',
		tabs_TopItem: '商品分析',
		tabs_TopSku: 'SKU分析',
		tabs_CatList: '类目分析',
		tabs_Itemtastelist: '商品口味分析',
		tabs_Customaspect: '自定义分析',
	},
	userInfo: {
		select_phone_title: '选择一个手机号进行验证',
		select_placeholder: '请选择',
		search_phone: '搜索手机号',
		input_sms: '输入验证码',
		get_sms: '获取验证码',
		verify: '验证',
		cancel: '取消',
	},
	baseInfo: {
		sales: '销售额',
		sold: '销量',
		yoy: '同比',
		mom: '环比',
		avg: '商品均价',
		shop_count: '店铺数',
		item_count: '商品数',
		downLoad: '下载',
		downLoading: '下载准备中...',
		cloud_nameLabel: '词',
		cloud_valueLabel: '数量',
		ranking: '序号',
		// headProductText: '为什么值得买：',
	},
	EachSound: {
		downLoad: '评论下载',
		downLoading: '下载准备中',
	},
	catList: {
		catName: '类目',
		comment_count: '评论数',
		volume_count: '声量数',
		pos_volume: '正向声量',
		neg_volume: '负向声量',
		neu_volume: '中性声量',
		percentage: '占比',
		neg_percentage: '负向率',
		neg_percentage_ratio: '负向声量占比',
	},
	shopList: {
		shopName: '店铺',
		advantage: '优势方面',
		inferiority: '劣势方面',
	},
	itemList: {
		image: '图片',
		itemName: '商品',
		price: '价格',
	},
	brandList: {
		name: '声量TOP100品牌',
		collect: '汇总',
	},
	skuList: {
		image: '图片',
		itemName: '商品',
		skuName: 'SKU名称',
		price: '价格',
	},
	attrList: {
		attr: '属性',
		sold: '销量',
		pos_volume: '正向声量',
		neg_volume: '负向声量',
		neu_volume: '中性声量',
		neg_per: '差评率',
		pos_per: '好评率',
	},
	setting: {
		phone_set: '手机号设置',
		primary_number: '您的主手机号',
		second_number: '您的辅手机号',
		delete: '删除',
		phone_placeholder: '请输入内容',
		sms: '短信验证登录',
		add: '添加手机',
		success: '手机号添加成功',
		confirm: '确 定',
		dialog_phone: '主手机号',
		dialog_input_placeholder: '输入验证码',
		verification: '验证',
		cancel: '取消',
		yesterday: '昨天',
		7: '近7天',
		30: '近30天',
		90: '近90天',
		enter_num: '请输入手机号',
		enter_correct_num: '请输入正确的手机号',
		first_num: '第一个手机号必须开启短信登录验证',
	},
	changePasswordPanel: {
		change_password_title: '修改密码 （仅主手机号可用）',
		change_password: '修改密码',
		use_latest_cat:
			'当平台类目结构发生变化时，使用最新类目结构统计历史数据',
		message_success: '修改成功',
	},
}
