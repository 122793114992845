<template>
  <div class="back-cmi">
    <a :href="cmiUrl" class="back-a">
      <img src="./img/back_cmi.png">
      <span>返回CMI平台</span>
    </a>
  </div>
</template>


<script>
let cmiUrl
const host = window.location.host
if (
  host == 'commentvue.mktindex.com' ||
  host == 'comment.mktindex.com'
) {
  cmiUrl = 'https://cmi.mktindex.com/#/app'
} else {
  cmiUrl = 'https://cmitest.mktindex.com/#/app'
}
export default {
  name: 'BackCmi',
  data() {
    return {
      cmiUrl: cmiUrl,
    }
  },
}
</script>

<style lang="less" scoped>
.back-cmi {
  text-align: center;
  .back-a {
    cursor: pointer;
    img {
      width: 22px;
      height: 22px;
    }
    span {
      color: #d01b28;
      line-height: 30px;
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      margin-left: 5px;
    }
  }
  .back-a:hover {
    text-decoration: none;
  }
}
</style>
