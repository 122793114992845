// import store from "@/store";

export default {
  DisplayRow: () => import("./nested-DisplayRow.vue"),
  Color: () => import("./td-Color.vue"),
  CreatetimeTd: () => import("./td-Createtime.vue"),
  CreatetimeTh: () => import("./th-Createtime.vue"),
  Email: () => import("./td-Email.vue"),
  IP: () => import("./td-IP.vue"),
  Opt: () => import("./td-Opt.vue"),
  FilterTh: () => import("./th-Filter.vue"),
  ScreenTh: () => import("./th-Screen.vue"),
  NumberTd: () => import("./td-Number.vue"),
  AmountNumTd: () => import("./td-AmountNum.vue"),
  NameTd: () => import("./td-Name.vue"),
  NameNormalTd: () => import("./td-Name-Normal.vue"),
  PicTd: () => import("./td-Pic.vue"),
  ActionTd: () => import("./td-Action.vue"),
  PlantNameTd: () => import("./td-plantName.vue"),
  ImgListTd: () => import("./td-Img-List.vue"),
  ShortNumTd: () => import("./td-Short-Num.vue"),
  InfoTh: () => import("./th-Info.vue"),
  UserDefineNameTd: () => import("./td-Name-UserDefine.vue"),
  RangeTimeTd: () => import("./td-RangeTime.vue"),
  LinkNameTd: () => import("./td-LinkName.vue"),
  NameBtnTd: () => import("./td-Name-Btn.vue"),
  FollowTd: () => import("./td-Follow.vue"),
  GrowthTd: () => import("./td-Growth.vue"),
  EditTd: () => import("./td-Edit-Content.vue"),
  SuggestTd: () => import("./td-Suggest.vue"),
  PercentTd: () => import("./td-Percent.vue"),
  SelectTd: () => import("./td-Select.vue"),
  TextTd: () => import("./td-Text.vue"),
  YoyTd: () => import("./td-Yoy.vue"),
  divideTd: () => import("./td-Divide.vue"),
  bgDivideTd: () => import("./td-Bg-Divide.vue"),
  transNumTd: () => import("./td-transNum.vue"),
  BrandPriceTd: () => import("./td-brandPrice.vue"),
  CustomAnalyzeTh: () => import("./th-Custom-Ana.vue"),
  CustomAnalyzeTd: () => import("./td-Custom-Ana.vue"),
  NamePredTd: () => import("./td-Name-pred.vue"),
  FilingNameTd: () => import("./td-Filing-Name.vue"),
  WordCloudTd: () => import("./td-Word-Cloud.vue"),
  TransTd: () => import("./td-transform.vue"),
  NamePredTd: () => import("./td-Name-pred.vue"),
  ImgLinkTd: () => import("./td-Img-Link.vue"),
  BrandCollectTd: () => import("./td-Brand-Collect.vue"),
  CollectTd: () => import("./td-Collect.vue"),
  //FriendsTable: () => import("@/views/datatableExample.vue"),
  NameCloseTd: () => import("./td-Name-close.vue"),
};
