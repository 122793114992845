<template>
  <div class="date-picker-cpn">
    <el-date-picker v-model="dateTime[0]"
                    :type="datePickerType"
                    size="mini"
                    :picker-options="pickerOptions"
                    :format="format"
                    :clearable="false"
                    @change="changeDate($event, 'start')"
                    placeholder="选择日期">
    </el-date-picker>
    <span style="margin: 0 10px">至</span>
    <el-date-picker v-model="dateTime[1]"
                    :type="datePickerType"
                    size="mini"
                    :picker-options="pickerOptions"
                    :format="format"
                    :clearable="false"
                    @change="changeDate($event, 'end')"
                    placeholder="选择日期">
    </el-date-picker>
  </div>
</template>


<script>
import { build_ts, build_day_ts, build_day, parseTime } from '@/utils'
import { LuxonTime } from 'common'
export default {
  name: 'DatePicker',
  data() {
    return {
      timeList: [], //所有权限时间数据
      dateTime: ['', ''], //选择器展示数据
      plat: '',
      catTs: [], //当前权限类目的时间范围
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate: (time) => this.disabledDate(time),
      },
    }
  },
  props: {
    currentTime: {
      type: Array,
      default: () => ['', ''],
    },
    datePickerType: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    latestTime() {
      return this.$store.state.user.latestTime
    },
    format() {
      if (this.datePickerType == 'month') {
        return 'yyyy-MM'
      } else {
        return 'yyyy-MM-dd'
      }
    },
    // pickerOptions(){
    //     return this.dateTime[0] < this.timeList[0] || this.dateTime[0] > this.timeList[this.timeList.length -1]
    // },
    // pickerOptions2(){
    //     return this.dateTime[1] < this.timeList[0] || this.dateTime[1] > this.timeList[this.timeList.length -1]
    // },
  },
  methods: {
    disabledDate(date) {
      if (this.timeList && this.timeList.length > 0) {
        let time = this.formatDate(date, '{y}-{m}')
        if (this.datePickerType != 'month') {
          time = this.formatDate(date, '{y}-{m}-{d}')
        }
        return (
          //第一项为最大时间，最后一项为最小时间
          time > this.timeList[0] ||
          time < this.timeList[this.timeList.length - 1]
        )
      }
    },
    changeDate(val, type) {
      if (this.datePickerType == 'week') {
        let givenDate = new Date(this.formatDate(val))
        const { startOfWeek, endOfWeek } = this.getStartAndEndOfWeek(givenDate)
        if (type == 'start') {
          this.dateTime[0] = startOfWeek
        }
        if (type == 'end') {
          this.dateTime[1] = endOfWeek
        }
        this.$forceUpdate()
      } else if (this.datePickerType == 'month') {
        if (type == 'start') {
          this.dateTime[0] = this.formatDate(val, '{y}-{m}')
        }
        if (type == 'end') {
          this.dateTime[1] = this.formatDate(val, '{y}-{m}')
        }
      } else if (this.datePickerType == 'date') {
        if (type == 'start') {
          this.dateTime[0] = this.formatDate(val, '{y}-{m}-{d}')
        }
        if (type == 'end') {
          this.dateTime[1] = this.formatDate(val, '{y}-{m}-{d}')
        }
      }
    },
    initMethod() {
      const { platTime } = this.$store.state.user
      const { start, end } = this.$route.query
      if (platTime.start && platTime.end && this.catTs.length == 0) {
        if (this.datePickerType == 'month') {
          this.timeList = build_ts(platTime.end, platTime.start)
        } else {
          if (platTime.start.split('-').length == 2) {
            this.timeList = build_day_ts(platTime.end, platTime.start)
          } else {
            this.timeList = build_day(platTime.end, platTime.start)
          }
        }
        this.dateTime = [start, end]
      }
    },
    getStartAndEndOfWeek(date) {
      const day = date.getDay()
      const diff = date.getDate() - day + (day === 0 ? -6 : 1) // 考虑周日的情况

      const startDate = new Date(date)
      startDate.setDate(diff)

      const endDate = new Date(date)
      endDate.setDate(diff + 6)

      // 格式化日期
      const formattedStartDate = startDate.toISOString().split('T')[0]
      const formattedEndDate = endDate.toISOString().split('T')[0]

      return {
        startOfWeek: formattedStartDate,
        endOfWeek: formattedEndDate,
      }
    },
    getLastFullWeekStartAndEnd(month, year) {
      //根据给定的月份和年份获取所需的最后一个完整周的开始和结束时间
      let lastDayOfMonth = new Date(year, month, 0).getDate()
      let lastDayOfWeek = new Date(year, month - 1, lastDayOfMonth).getDay() // 获取当月最后一天是星期几

      let endDay = lastDayOfMonth - lastDayOfWeek // 获取完整周的结束日期

      let endWeekStart = new Date(year, month - 1, endDay - 6) // 完整周开始时间
      let endWeekEnd = new Date(year, month - 1, endDay) // 完整周结束时间

      return {
        start: this.formatDate(endWeekStart),
        end: this.formatDate(endWeekEnd),
      }
    },
    formatDate(date, cFormat) {
      const format = cFormat || '{y}-{m}-{d}'
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const formatObj = {
        y: year,
        m: month,
        d: day,
      }
      const time_str = format.replace(/{([ymd])+}/g, (result, key) => {
        const value = formatObj[key]
        return value.toString()
      })
      return time_str
    },
  },
  created() {
    this.init = _.debounce(this.initMethod, 300)
  },
  mounted() {
    if (this.$store.state.user.platTime.start) {
      this.init()
    }
  },
  watch: {
    '$route.query': {
      handler(newVal, oldVal) {
        if (newVal.plat && oldVal.plat !== newVal.plat) {
          this.plat = newVal.plat
        }
        if (newVal.start) {
          console.log(newVal, '----------------')
          this.dateTime = [newVal.start, newVal.end]
        }
        // this.init()
      },
    },
    '$store.state.user.platTime': {
      handler(value) {
        this.init()
      },
    },
    dateTime(value) {
      this.$emit('on-changeDateTime', value)
    },
    datePickerType: {
      handler(newVal, oldVal) {
        if (this.$route.path == '/catsheetnew') {
          const { platTime, start, end } = this.$store.state.user
          if (platTime.start && platTime.end) {
            if (newVal == 'month') {
              this.timeList = build_ts(platTime.end, platTime.start)
              this.dateTime = [this.timeList[0], this.timeList[0]]
            } else if (newVal == 'week') {
              const year = this.dateTime[1].split('-')[0]
              const month = this.dateTime[1].split('-')[1]
              const st = this.timeList[0]
              const et = this.timeList[this.timeList.length - 1]
              const time = this.getLastFullWeekStartAndEnd(month, year)
              this.dateTime[0] = time.start
              this.dateTime[1] = time.end
              if (st.split('-').length == 2) {
                this.timeList = build_day_ts(st, et)
              }
            } else {
              if (platTime.start.split('-').length == 2) {
                this.timeList = build_day_ts(platTime.end, platTime.start)
              } else {
                this.timeList = build_day(platTime.end, platTime.start)
              }
              this.dateTime = [this.timeList[0], this.timeList[0]]
            }
          }
          if (start != this.dateTime[0]) {
            this.$store.dispatch('user/changeStart', this.dateTime[0])
          }
          if (end != this.dateTime[1]) {
            this.$store.dispatch('user/changeEnd', this.dateTime[1])
          }
          //   this.$store.dispatch('user/changeActivedTab', 'Summary')
          //   this.$eventBus.$emit('carSheetReload', true)
        } else if (this.$route.path == '/contrastAnalysis') {
          {
            const { platTime, start, end } = this.$store.state.user
            if (platTime.start && platTime.end) {
              if (newVal == 'month') {
                this.timeList = build_ts(platTime.end, platTime.start)
                this.dateTime = [this.timeList[0], this.timeList[0]]
              } else if (newVal == 'week') {
                const year = this.dateTime[1].split('-')[0]
                const month = this.dateTime[1].split('-')[1]
                const st = this.timeList[0]
                const et = this.timeList[this.timeList.length - 1]
                const time = this.getLastFullWeekStartAndEnd(month, year)
                this.dateTime[0] = time.start
                this.dateTime[1] = time.end
                if (st.split('-').length == 2) {
                  this.timeList = build_day_ts(st, et)
                }
              } else {
                if (platTime.start.split('-').length == 2) {
                  this.timeList = build_day_ts(platTime.end, platTime.start)
                } else {
                  this.timeList = build_day(platTime.end, platTime.start)
                }
                this.dateTime = [this.timeList[0], this.timeList[0]]
              }
            }
            if (start != this.dateTime[0]) {
              this.$store.dispatch('user/changeStart', this.dateTime[0])
            }
            if (end != this.dateTime[1]) {
              this.$store.dispatch('user/changeEnd', this.dateTime[1])
            }
          }
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.w120 {
  width: 120px;
}
</style>
