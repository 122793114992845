<template>
  <div class="picker-cpn">
    <el-select size="mini"
               v-if="showDaily"
               v-model="timeType"
               style="width: 130px; margin-right: 20px"
               @change="changeTsType">
      <el-option v-for="item in timeOptions"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value"
                 :disabled="item.disabled"></el-option>
    </el-select>
    <div>
      <DatePickerNew @on-changeDateTime="changeDateTime"
                     :datePickerType="datePickerType"></DatePickerNew>
      <!-- <template v-if="timeType == 'month'">
        <DatePicker @on-changeDateTime="changeDateTime"></DatePicker>
      </template>
      <template v-else>
        <DayPicker @on-changeDateTime="changeDateTime"></DayPicker>
      </template> -->
    </div>

  </div>
</template>


<script>
// import DatePickerNew from '@/components/DatePickerNew/index.vue'
import DatePickerNew from '@/components/DatePickerNew/DayPicker.vue'
import DatePicker from '@/components/DatePicker/index.vue'
import DayPicker from '@/components/DatePicker/DayPicker.vue'
import { getObjFromUrl } from '@/utils'
export default {
  name: 'PickerCpn',
  data() {
    return {
      timeType: this.$store.state.user.tsType,
      timeOptions: [
        {
          label: '月度时间段',
          value: 'month',
        },
        {
          label: '周度时间段',
          value: 'week',
        },
        {
          label: '日度时间段',
          value: 'day',
        },
      ],
      dateTime: ['', ''],
    }
  },
  components: {
    DatePicker,
    DayPicker,
    DatePickerNew,
  },
  props: {
    // typ: {
    //   type: String,
    //   default: '',
    // },
  },
  computed: {
    showDaily() {
      return this.$store.state.user.loginInfo.is_show_daily
    },
    datePickerType() {
      if (this.timeType == 'month') {
        return 'month'
      } else if (this.timeType == 'week') {
        return 'week'
      } else if (this.timeType == 'day') {
        return 'date'
      }
    },
    typ() {
      return this.$route.name
    },
  },
  methods: {
    changeTsType(val) {
      if (this.typ == 'contrastAnalysis') {
        //对比分析和全局的需要区别处理
        //   this.$store.dispatch('user/changeAnalyseTsType', val)
        sessionStorage.setItem('analyseTsType', val)
      } else {
        //   this.$store.dispatch('user/changeTsType', val)
        sessionStorage.setItem('tsType', val)
        console.log(this.$store.state.user.tsType, '7777')
      }
    },
    changeDateTime(val) {
      this.dateTime = val
    },
  },
  watch: {
    '$store.state.user.tsType': {
      handler(newVal) {
        this.timeType = newVal
        console.log(this.timeType, '333333333333')
      },
    },
    '$store.state.user.analyseTsType': {
      handler(newVal) {
        if (this.typ == 'contrastAnalysis') {
          this.timeType = newVal
        }
      },
    },
    '$route.query': {
      handler(newVal) {
        if (
          newVal.cid == 'model_bestore_month_tmall' ||
          newVal.cid == 'model_bestore_month_jd'
        ) {
          this.timeOptions[1].disabled = true
          this.timeOptions[2].disabled = true
        } else if (
          newVal.cid == 'model_bestore_daily_tmall' ||
          newVal.cid == 'model_bestore_daily_jd'
        ) {
          this.timeOptions[0].disabled = true
          this.timeOptions[1].disabled = true
        } else {
          this.timeOptions[0].disabled = false
          this.timeOptions[1].disabled = false
          this.timeOptions[2].disabled = false
        }
        this.$forceUpdate()
      },
    },
  },
  created() {
    let param_val = getObjFromUrl(window.location.href)
    if (
      param_val.cid == 'model_bestore_month_tmall' ||
      param_val.cid == 'model_bestore_month_jd'
    ) {
      this.timeOptions[1].disabled = true
      this.timeOptions[2].disabled = true
    } else if (
      param_val.cid == 'model_bestore_daily_tmall' ||
      param_val.cid == 'model_bestore_daily_jd'
    ) {
      this.timeOptions[0].disabled = true
      this.timeOptions[1].disabled = true
    } else {
      this.timeOptions[0].disabled = false
      this.timeOptions[1].disabled = false
      this.timeOptions[2].disabled = false
    }
    if (this.typ == 'contrastAnalysis') {
      this.timeType = this.$store.state.user.analyseTsType
    }
  },
}
</script>

<style lang="less" scoped>
.picker-cpn {
  display: flex;
}
</style>
