<template>
  <el-menu class="comment-menu"
           :default-openeds="defaultOpeneds"
           :default-active="defaultActive"
           @select="handelSelect">
    <div style="margin-top:70px;margin-bottom: 10px">
      <BackCmi v-if="isPartner==false" />
    </div>
    <div class="usage-scenario"
         v-if="isPartner==false">
      <a href="https://qmctsz2s8t.feishu.cn/docx/Vx2Udr2JBoDF1Gxb2Wcc3hAOnAd"
         target="_blank"
         class="scenario">消费者研究怎么做</a>
    </div>
    <template v-if="!isExampleUser">
      <el-menu-item :index="item.index"
                    v-for="(item) in menueData"
                    :disabled="disabled"
                    @click="routeTo(item.routeName)"
                    :key="item.index"
                    :id="item.index">
        <span slot="title">{{item.label}}</span>
      </el-menu-item>
    </template>
    <template v-else>
      <template v-for="item in menueData">
        <el-menu-item v-if="item.routeName !== 'businessAnalysis'"
                      :index="item.index"
                      :key="item.index"
                      @click="routeTo(item.routeName)"
                      :disabled="disabled"
                      :id="item.index">
          <span slot="title">{{item.label}}</span>
        </el-menu-item>
        <el-submenu v-else
                    :index="item.index"
                    :key="item.index"
                    :id="item.index">
          <template slot="title">
            <span style="color: #85868a">文本标签模型</span>
          </template>
          <el-menu-item @click="jumpToExample">运动鞋服定制模型</el-menu-item>
          <el-menu-item @click="routeTo(item.routeName)"
                        :index="item.index">定制模型说明</el-menu-item>
        </el-submenu>
      </template>
    </template>
    <div style="margin-left: 28px;margin-top: 10px;display: flex;align-items: center;color: #3b76f2;cursor: pointer;"
         @click="dialogVisible = true"
         v-if="isPartner==false">
      <i class="iconfont icon-dengpao"
         style="font-size:20px;margin-right: 5px;"></i>
      AI降本增效
    </div>
    <el-dialog :visible.sync="businessVisible"
               :show-close="false"
               width="1000px">
      <DianShangDialog :src="business"></DianShangDialog>
    </el-dialog>
    <el-dialog :visible.sync="dataVisible"
               :show-close="false"
               width="1000px">
      <DianShangDialog :src="data"></DianShangDialog>
    </el-dialog>
    <el-dialog :visible.sync="consumerVisible"
               :show-close="false"
               width="1000px">
      <DianShangDialog :src="consumer"></DianShangDialog>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible"
               :show-close="false"
               class="cost-reduce-dialog"
               width="1000px">
      <CostReduce />
    </el-dialog>
  </el-menu>
</template>

<script>
import BackCmi from './BackCmi/index.vue'
import DianShangDialog from './DianShangDialog.vue'
import CostReduce from './CostReduce.vue'
export default {
  name: 'AsideMenue',
  components: {
    BackCmi,
    DianShangDialog,
    CostReduce,
  },
  data() {
    return {
      defaultOpeneds: ['1'],
      // defaultActive: '1-1',
      menueDatas: [
        {
          index: '1-1',
          label: '行业聆听',
          routeName: 'catSheet',
        },
        {
          index: '1-2',
          label: '对比分析',
          routeName: 'contrastAnalysis',
        },
        {
          index: '1-3',
          label: '文本标签模型',
          routeName: 'businessAnalysis',
        },
        {
          index: '1-4',
          label: '导入自有数据',
          routeName: 'dataImport',
        },
        {
          index: '1-5',
          label: 'CEM消费者体验管理',
          routeName: 'consumerExperience',
        },
        {
          index: '1-6',
          label: '我的订阅',
          routeName: 'subscription',
        },
        {
          index: '1-7',
          label: '我的关注',
          routeName: 'marked',
        },
        {
          index: '1-8',
          label: '配置模块管理',
          routeName: 'custom',
        },
        {
          index: '1-10',
          label: '行业报告',
          routeName: 'tradeReport',
        },
        {
          index: '1-9',
          label: '设置',
          routeName: 'settings',
        },
      ],
      disabled: false,
      businessVisible: false,
      dataVisible: false,
      consumerVisible: false,
      dialogVisible: false,
      business: require('@/assets/particle.png'),
      data: require('@/assets/import-data.png'),
      consumer: require('@/assets/experience-manage.png'),
      DEFAULT_CID: {
        jd: '1316',
        tmall: '1801',
        douyin: '20085',
        custom: 'haersi',
        amazon_us: '1111111100000033',
      },
    }
  },
  methods: {
    routeTo(routeName) {
      const r = routeName
      if (r == 'catSheet') {
        this.goCatSheet(routeName)
        this.$store.dispatch('user/changeActivedTab', 'Summary')
      } else if (routeName == 'tradeReport') {
        window.open(
          'https://www.mktindex.com/static/industryreports/?f=industryreports',
          '_blank'
        )
        this.$nextTick(() => {
          document.getElementById('1-10').classList.remove('is-active')
          document.getElementById('1-10').blur()
        })
      } else if (
        r == 'contrastAnalysis' ||
        r == 'subscription' ||
        r == 'marked' ||
        r == 'custom' ||
        r == 'settings'
      ) {
        if (r == 'contrastAnalysis') {
          sessionStorage.setItem('analyseTsType', 'month')
          this.$store.dispatch('user/changeAnalyseTsType', 'month')
          //   this.goCatSheet(routeName)
          const cat_list = this.$store.state.user.loginInfo.cat_list
          let r = this.rand_cat(cat_list)
          this.$router.push({
            name: routeName,
            query: {
              plat: r[0],
              cid: r[1],
              start: r[2],
              end: r[2],
            },
          })
        } else {
          this.$router.push({
            name: routeName,
          })
        }
      } else if (r == 'businessAnalysis') {
        this.businessVisible = true
      } else if (r == 'dataImport') {
        this.dataVisible = true
      } else if (r == 'consumerExperience') {
        this.consumerVisible = true
      }
    },
    goCatSheet(routeName) {
      const cat_list = this.$store.state.user.loginInfo.cat_list
      let r = this.rand_cat(cat_list)
      this.$store.dispatch('user/changePlat', r[0])
      this.$store.dispatch('user/changeCatId', r[1])
      this.$store.dispatch('user/changeStart', r[2])
      this.$store.dispatch('user/changeEnd', r[2])
      this.$store.dispatch('user/changeLabelLevel', '')
      this.$store.dispatch('user/changeRouterNerType', '')
      this.$store.dispatch('user/changeAspectType', '')
      this.$store.dispatch('user/changeRouterRuleId', '')
      this.$store.dispatch('user/changeAttrId', '')
      this.$store.dispatch('user/changeAttrName', '')
      this.$store.dispatch('user/changeItemId', '')
      this.$store.dispatch('user/changeShopId', '')
      this.$store.dispatch('user/changeBrandId', '')
      this.$store.dispatch('user/changeTsType', 'month')
      sessionStorage.setItem('tsType', 'month')
      this.$store.dispatch('user/changeTitle', '')
      this.$store.dispatch('user/changeTitleWord', '')
      this.$store.dispatch('user/changeTitleTyp', '')
      this.$store.dispatch('user/changeContentWord', '')
      this.$store.dispatch('user/changePicType', '1,2')
      this.$store.dispatch('user/changePriceMax', '')
      this.$store.dispatch('user/changePriceMin', '')
      this.$store.dispatch('user/changeCustomFilters', null)
      this.$router.push({
        name: routeName,
        query: {
          plat: r[0],
          cid: r[1],
          start: r[2],
          end: r[2],
        },
      })
    },
    rand_cat(catlist) {
      let cid = '',
        plat = '',
        ts = '',
        _this = this
      if (catlist == 'all') {
        getDefaultId()
      } else {
        _.forEach(
          ['jd', 'tmall', 'douyin', 'custom', 'amazon_us'],
          function (p) {
            if (plat && plat !== p) {
              return
            }
            _.each(catlist[p], function (v, k) {
              if (v.length > 0 && !cid) {
                cid = v[0]['cat_id']
                ts = v[0].ts
                plat = p
                return false
              }
            })
          }
        )
        if (!cid || !plat) {
          getDefaultId()
        }
      }
      function getDefaultId() {
        plat = plat || 'jd'
        cid = cid || _this.DEFAULT_CID[plat]
        const latestTime = _this.$store.state.user.latestTime
        if (latestTime) {
          const timeArr = latestTime[plat]
          if (timeArr[2] && Object.keys(timeArr[2]).length > 0) {
            if (timeArr[2][cid]) {
              ts = timeArr[2][cid][1]
            } else {
              ts = timeArr[1]
            }
          } else {
            ts = timeArr[1]
          }
        }
      }
      return [plat, cid, ts]
    },
    handelSelect(index) {
      this.$store.dispatch('user/changeActivedMnenue', index)
    },
    jumpToExample() {
      this.$store.dispatch('user/changePlat', 'custom')
      this.$store.dispatch('user/changeCatId', 'model_example_tmall')
      this.$store.dispatch('user/changeStart', '2023-04')
      this.$store.dispatch('user/changeEnd', '2023-04')
      this.$router.push({
        name: 'catSheet',
        query: {
          plat: 'custom',
          cid: 'model_example_tmall',
          start: '2023-04',
          end: '2023-04',
        },
      })
    },
    setMenueData() {
      let arr = []
      if (this.isPartner) {
        arr = ['businessAnalysis', 'dataImport', 'consumerExperience'] //如果是合伙人这三个菜单需要隐藏
      }
      const newArr = _.filter(
        this.menueDatas,
        (item) => !arr.includes(item.routeName)
      )
      this.menueData = newArr
    },
  },
  computed: {
    defaultActive() {
      let index = '1-1'
      let routeName = this.$route.name,
        matchItem = null
      if (routeName) {
        matchItem = this.menueData.find((item) => {
          return item.routeName == this.$route.name
        })
        if (matchItem) {
          index = matchItem.index
        } else {
          index = '1-0'
        }
      }
      return index
    },
    isPartner() {
      return this.$store.state.user.loginInfo.is_partner
    },
    isExampleUser() {
      return this.$store.state.user.loginInfo.is_example_user
    },
  },
  created() {
    this.setMenueData()
  },
}
</script>

<style lang="less" scoped>
.comment-menu {
  width: 172px;
  height: 100%;
  background-color: #f3f4f6 !important;
  overflow-x: hidden;
  overflow-y: auto;
  .usage-scenario {
    line-height: 56px;
    margin-left: 36px;
    .scenario {
      font-size: 14px;
    }
    .scenario:hover,
    .scenario:focus {
      text-decoration: unset;
    }
  }
  .el-menu-item {
    color: #85868a;
    line-height: 1.5;
    border-left: 8px solid transparent;
    background: transparent;
    padding: 18px 0 18px 24px;
    margin-left: 8px;
  }
  .el-menu-item:active,
  .el-menu-item:hover,
  .el-menu-item:focus {
    background-color: #fff;
    border-color: #ff7d00;
    margin-left: 8px;
    border-width: 8px;
    border-radius: 4px 0px 0px 4px;
    box-shadow: 2px 3px 4px 0px #ded6e9;
  }
  /deep/ .el-submenu__title {
    color: #85868a;
    line-height: 1.5;
    border-left: 8px solid transparent;
    background: transparent;
    padding: 18px 0 18px 24px;
    margin-left: 8px;
  }
  /deep/ .el-icon-arrow-down:before {
    display: none;
  }
  /deep/ .el-submenu .el-menu {
    background-color: transparent;
  }
  /deep/ .el-dialog {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>
