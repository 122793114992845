<template>
  <containElement>
  </containElement>
</template>

<script>
import containElement from '@/components/Container.vue'
import { getObjFromUrl } from '@/utils'
import ErrorStackParser from 'error-stack-parser'
import { getBaseErrorObj } from '@/utils/index.js'
import { clientError } from '@/api/public'
export default {
  components: { containElement },
  methods: {
    /**
     * 示例:
     * {
     *  rule_id: '',
     *  analyse_typ: '',
     *  label_level: '',
     *  ner_type: '',
     *  aspect_type: '',
     *  words: '',
     *  asp_level: ''
     * }
     */
    computeCustomFilters() {
      let param_val = this.$route.query
      if (param_val.words) {
        let customFilters = []
        param_val.words.split(',').forEach((item, index) => {
          let filterObj = {}
          filterObj.analyse_typ = param_val.analyse_typ
            ? param_val.analyse_typ.split(',')[index]
            : ''
          if (filterObj.analyse_typ == '') {
            // 方面/观点 && nertabs
            if (
              param_val.label_level.toString().split(',')[index] == 1 &&
              param_val.ner_type.split(',')[index] == '' &&
              param_val.rule_id.split(',')[index] == ''
            ) {
              filterObj.words = decodeURIComponent(item.split('_')[0])
              filterObj.opinions = decodeURIComponent(item.split('_')[1])
            } else if (
              item.includes('~') &&
              param_val.rule_id.split(',')[index] !== ''
            ) {
              filterObj.words = decodeURIComponent(item.replaceAll('~', ','))
            } else {
              filterObj.words = decodeURIComponent(item)
            }
          } else {
            // 人群分析/场景分析
            filterObj.words = decodeURIComponent(item)
          }
          filterObj.rule_id = param_val.rule_id
            ? param_val.rule_id.split(',')[index]
            : ''

          filterObj.label_level = param_val.label_level
            ? param_val.label_level.toString().split(',')[index]
            : ''
          filterObj.ner_type = param_val.ner_type
            ? param_val.ner_type.split(',')[index]
            : ''
          filterObj.aspect_type = param_val.aspect_type
            ? param_val.aspect_type.split(',')[index]
            : ''
          if (param_val.asp_level && param_val.asp_level.split(',')[index] !== 'level0') {
            filterObj.asp_level = param_val.asp_level.split(',')[index]
          }
          customFilters.push(filterObj)
        })
        this.$store.dispatch('user/changeCustomFilters', customFilters)
      } else {
        this.$store.dispatch('user/changeCustomFilters', null)
      }
    },
    computeAttrFilters() {
      let param_val = this.$route.query
      if (param_val.attr_name) {
        let attrFilters = []
        param_val.attr_name.split(',').forEach((item, index) => {
          let filterObj = {}
          filterObj.attr = param_val.attr_name
            ? param_val.attr_name.split(',')[index]
            : ''
          filterObj.value = param_val.attr_id
            ? param_val.attr_id.split(',')[index]
            : ''
          attrFilters.push(filterObj)
        })
        this.$store.dispatch('user/changeAttrFilters', attrFilters)
      } else {
        this.$store.dispatch('user/changeAttrFilters', null)
      }
    },
    computeTitleFilters() {
      let param_val = this.$route.query
      if (param_val.title_word) {
        let titleFilters = []
        param_val.title_word.split(',').forEach((item, index) => {
          let filterObj = {}
          filterObj.word = decodeURIComponent(item)
          filterObj.title_typ = param_val.title_typ
            ? param_val.title_typ.split(',')[index]
            : ''
          titleFilters.push(filterObj)
        })
        this.$store.dispatch('user/changeTitleFilters', titleFilters)
      } else {
        this.$store.dispatch('user/changeTitleFilters', null)
      }
    },
    checkDateFormat(dateString) {
      // 使用正则表达式来匹配两种可能的日期格式
      const regex1 = /^\d{4}-\d{2}$/
      const regex2 = /^\d{4}-\d{2}-\d{2}$/
      if (regex1.test(dateString)) {
        // 匹配第一种格式 'xxxx-xx'
        return 1
      } else if (regex2.test(dateString)) {
        // 匹配第二种格式 'xxxx-xx-xx'
        return 2
      } else {
        // 未匹配任何格式
        return 'invalid'
      }
    },
    computeTitleWordTyp(param) {
      let title_word = []
      let titleWordList = param.title_word.split(',')
      let titleTypList = param.title_typ.split(',')
      const wordReg = /%[0-9A-Fa-f]{2}/g
      let EncodeIndex = titleWordList.findIndex((item) => {
        return wordReg.test(item)
      })
      if (EncodeIndex > -1) {
        title_word = titleWordList.map((item, index) => {
          let titleWordItem = {
            word: decodeURIComponent(item),
            title_typ: titleTypList[index],
          }
          return titleWordItem
        })
      } else {
        let set = new Set(titleWordList)
        titleWordList = Array.from(set)
        title_word = titleWordList.map((item, index) => {
          let titleWordItem = {
            word: item,
            title_typ: titleTypList[index],
          }
          return titleWordItem
        })
      }
      this.$store.dispatch('user/changeTitleWord', JSON.stringify(title_word))
      this.$store.dispatch('user/changeTitleTyp', param.title_typ)
    },
  },
  watch: {
    '$route.query': {
      handler(newVal, oldVal) {
        console.log('App router change', newVal)
        let param_val = newVal
        let monthType = this.$store.state.user.tsType
        if (
          param_val.plat &&
          param_val.plat != 'jd' &&
          param_val.plat != 'tmall'
        ) {
          console.log(param_val.plat)
          localStorage.removeItem('is_question')
        }
        if (param_val.start && param_val.end) {
          this.$store.dispatch('user/changeStart', param_val.start)
          this.$store.dispatch('user/changeEnd', param_val.end)
          if (this.checkDateFormat(param_val.start) == 2) {
            if (
              sessionStorage.getItem('tsType') &&
              sessionStorage.getItem('tsType') == 'week'
            ) {
              monthType = 'week'
            } else {
              monthType = 'day'
            }
          } else {
            monthType = 'month'
          }
        }
        if (param_val.cid) {
          this.$store.dispatch('user/changeCatId', param_val.cid)
          if (
            param_val.cid.indexOf('model_bestore_daily_tmall') > -1 ||
            param_val.cid.indexOf('model_bestore_daily_jd') > -1
          ) {
            monthType = 'day'
          } else if (
            param_val.cid.indexOf('model_bestore_month_tmall') > -1 ||
            param_val.cid.indexOf('model_bestore_month_jd') > -1
          ) {
            monthType = 'month'
          }
          if (this.$store.state.user.tsType != monthType) {
            sessionStorage.setItem('tsType', monthType)
            this.$store.dispatch('user/changeTsType', monthType)
          }
        }
        if (param_val.words) {
          this.$store.dispatch(
            'user/changeWords',
            decodeURIComponent(param_val.words)
          )
        }
        if (param_val.analyse_typ) {
          this.$store.dispatch(
            'user/changeRouterAnalyseTyp',
            param_val.analyse_typ
          )
        }
        if (param_val.label_level) {
          this.$store.dispatch('user/changeLabelLevel', param_val.label_level)
        }
        if (param_val.ner_type) {
          this.$store.dispatch('user/changeRouterNerType', param_val.ner_type)
        }
        if (param_val.aspect_type) {
          this.$store.dispatch('user/changeAspectType', param_val.aspect_type)
        }
        if (param_val.fx_url_id) {
          this.$store.dispatch('user/changeFxUrlId', param_val.fx_url_id)
        }
        if (param_val.rule_id) {
          this.$store.dispatch('user/changeRouterRuleId', param_val.rule_id)
        }
        if (param_val.attr_id) {
          this.$store.dispatch('user/changeAttrId', param_val.attr_id)
        }
        if (param_val.attr_name) {
          this.$store.dispatch('user/changeAttrName', param_val.attr_name)
        }
        if (param_val.item_id) {
          this.$store.dispatch('user/changeItemId', param_val.item_id)
        }
        if (param_val.shop) {
          this.$store.dispatch('user/changeShopId', param_val.shop)
        }
        if (param_val.brand) {
          this.$store.dispatch('user/changeBrandId', param_val.brand)
        }
        if (param_val.sku) {
          this.$store.dispatch('user/changeSkuId', param_val.sku)
        }
        if (param_val.price_min) {
          this.$store.dispatch('user/changePriceMin', param_val.price_min)
        }
        if (param_val.price_max) {
          this.$store.dispatch('user/changePriceMax', param_val.price_max)
        }
        if (param_val.title_word && param_val.title_typ) {
          this.computeTitleWordTyp(param_val)
        }
        if (param_val.content_word) {
          this.$store.dispatch(
            'user/changeContentWord',
            decodeURIComponent(param_val.content_word)
          )
        }
        if (param_val.active_rule_id) {
          this.$store.dispatch(
            'user/changeActiveRuleId',
            param_val.active_rule_id
          )
        }
        this.computeCustomFilters()
        this.computeAttrFilters()
        this.computeTitleFilters()
      },
    },
  },
  created() {
    //初始化时不需要修改plat,cid.start,end的值
    let param_val = getObjFromUrl(window.location.href)
    let monthType = this.$store.state.user.tsType
    if (param_val.start && param_val.end) {
      if (this.checkDateFormat(param_val.start) == 2) {
        if (
          sessionStorage.getItem('tsType') &&
          sessionStorage.getItem('tsType') == 'week'
        ) {
          monthType = 'week'
        } else {
          monthType = 'day'
        }
      } else {
        monthType = 'month'
      }
    }
    if (param_val.cid) {
      if (
        param_val.cid.indexOf('model_bestore_daily_tmall') > -1 ||
        param_val.cid.indexOf('model_bestore_daily_jd') > -1
      ) {
        monthType = 'day'
      } else if (
        param_val.cid.indexOf('model_bestore_month_tmall') > -1 ||
        param_val.cid.indexOf('model_bestore_month_jd') > -1
      ) {
        monthType = 'month'
      }
    }
    if (this.$store.state.user.tsType != monthType) {
      sessionStorage.setItem('tsType', monthType)
      this.$store.dispatch('user/changeTsType', monthType)
      console.log(this.$store.state.user.tsType, '1-1')
    }
    if (param_val.shop) {
      this.$store.dispatch('user/changeShopId', param_val.shop)
    }
    if (param_val.brand) {
      this.$store.dispatch('user/changeBrandId', param_val.brand)
    }
    if (param_val.item_id) {
      this.$store.dispatch('user/changeItemId', param_val.item_id)
    }
    if (param_val.fx_url_id) {
      this.$store.dispatch('user/changeFxUrlId', param_val.fx_url_id)
    }
    if (param_val.rule_id) {
      this.$store.dispatch('user/changeRouterRuleId', param_val.rule_id)
    }
    if (param_val.attr_id) {
      this.$store.dispatch('user/changeAttrId', param_val.attr_id)
    }
    if (param_val.attr_name) {
      this.$store.dispatch('user/changeAttrName', param_val.attr_name)
    }
    if (param_val.price_min) {
      this.$store.dispatch('user/changePriceMin', param_val.price_min)
    }
    if (param_val.price_max) {
      this.$store.dispatch('user/changePriceMax', param_val.price_max)
    }
    if (param_val.title_word && param_val.title_typ) {
      this.computeTitleWordTyp(param_val)
    }
    if (param_val.content_word) {
      this.$store.dispatch(
        'user/changeContentWord',
        decodeURIComponent(param_val.content_word)
      )
    }
    if (param_val.active_rule_id) {
      this.$store.dispatch('user/changeActiveRuleId', param_val.active_rule_id)
    }
    window.onerror = function (message, url, lineNumber, columnNumber, error) {
      console.error(
        'Error: ' + message + '\nURL: ' + url + '\nLine Number: ' + lineNumber
      )
      const parsedError = ErrorStackParser.parse(error)
      const baseObj = getBaseErrorObj()
      baseObj.error_msg = message
      baseObj.stackTrace = parsedError
      clientError(baseObj)
      return true // 阻止浏览器默认行为
    }
  },
  beforeDestroy() {
    localStorage.removeItem('customHeaderCheck')
  },
}
</script>

<style>
</style>
