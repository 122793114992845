import { Message } from "element-ui";
import i18n from "@/components/I18n";
var ajaxErrors = {
  "-404": {
    msg_en: "The requested data has not yet been recorded",
    msg: "所要的数据尚未收录",
  }, //宝贝、店铺、类目、品牌尚未收录
  "-403": {
    msg_en:
      "The current version does not have permission to view, please upgrade",
    msg: "当前版本没有权限查看，请升级",
  }, //没有权限查看宝贝、店铺、类目、品牌的数据
  "-401": {
    msg_en:
      "You are not logged in yet, <a style='cursor:pointer;' ng-click='login()'>Click here to log in now</a>",
    msg: "您尚未登录, <a style='cursor:pointer;' ng-click='login()'>点此立即登录</a>",
  }, //用户尚未登录
  "-499": {
    msg_en:
      "Your account needs to be verified by SMS, please refresh and try again after validate",
    msg: "您的账号需要进行短信验证，请验证后刷新重试",
  },
  "-500": {
    msg_en: "Server error, please take screenshot and contact customer service",
    msg: "服务器错误，请截屏并联系客服",
  }, //服务器未知错误
  "-400": {
    msg_en:
      "Required parameter error, please take a screenshot and contact customer service",
    msg: "请求参数错误，请截屏并联系客服",
  }, //请求参数错误
  404: {
    msg_en: "Url error",
    msg: "url错误",
  },
  0: {
    msg_en: "",
    msg: "",
  },
  422: {
    msg_en:
      "Required parameter error, please take a screenshot and contact customer service",
    msg: "请求参数错误，请截屏并联系客服",
  },
  429: {
    msg_en: "Too many requests",
    msg: "请求次数过多",
  },
  500: {
    msg_en: "Server error, please take screenshot and contact customer service",
    msg: "服务器错误，请截屏并联系客服",
  }, //服务器未知错误
  504: {
    msg_en:
      "Server response time is too long, please refresh the page and try again",
    msg: "服务器响应时间过长，请刷新页面重试",
  }, //time-out
  502: {
    msg_en: "Server error, please take screenshot and contact customer service",
    msg: "服务器错误，请截屏并联系客服",
  }, //服务器未知错误
  "-502": {
    msg_en: "Server error, please take screenshot and contact customer service",
    msg: "服务器错误，请截屏并联系客服",
  },
};
function handleRequestFail(error) {
  //console.log(JSON.stringify(i18n.locale) + "$$$qqqccg");
  let lan_msg = i18n.locale == "zh" ? "msg" : "msg_en";
  let { config } = error;
  let message = error.message
    ? error.message
    : i18n.locale == "zh"
    ? "服务器错误。请稍后重试"
    : "Server error. Please try again later";
  let error_code;
  if (error.status === 200) {
    message = error.data.error_msg;
    error_code = error.data.error_code || "0";
    if (_.isObject(message)) {
      message = message.msg;
    }
    message = message || ajaxErrors[error_code][lan_msg];
  } else if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    let { status } = error.response;
    message = ajaxErrors[status] ? ajaxErrors[status][lan_msg] : message;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
  if (config.errorShowCallback) {
    config.errorShowCallback(message);
  } else {
    /* Message({
            duration: 5000,
            message: message,
            center: true,
            type: 'error'
        })*/
  }
}

export { handleRequestFail };
