import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import qs from "qs";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
import "font-awesome/css/font-awesome.min.css";
import "element-ui/lib/theme-chalk/index.css";
import "@/style/element-variables.scss";
import "@/assets/iconfont1/iconfont.css";
import "@/assets/iconfont1/iconfont.js";
import ElementUI from "element-ui";
// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueECharts from "vue-echarts/components/ECharts.vue";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/chart/scatter";
import "echarts/lib/component/polar";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min";
import Datatable from "vue2-datatable-component";
import locale_zh from "vue2-datatable-component/locale/zh-cn";
//import locale_default from "vue2-datatable-component/locale/pt-br";
import _ from "lodash";
import less from "less";
import i18n from "./components/I18n";
import "./directive";
import "@/style/index.less";
import ErrorStackParser from 'error-stack-parser';
import {clientError} from '@/api/public'
import { getBaseErrorObj } from '@/utils/index.js'

import VueWorker from "vue-worker";
Vue.use(less);
Vue.prototype._ = _;
Vue.prototype.$qs = qs;
Vue.prototype.$eventBus = new Vue();
Vue.config.productionTip = false;
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });
Vue.prototype.$axios = axios;
const isDev = process.env.NODE_ENV !== "production";
Vue.config.performance = isDev;
let local_datatable = {};
local_datatable.locale = locale_zh;
Vue.use(Datatable, local_datatable); //{ locale: locale_zh });
Vue.use(VueWorker);
Vue.component("v-chart", VueECharts);
Vue.config.errorHandler = function (err, vm, info) {
    console.log('Error: ', err, 'Vue Instance: ', vm, 'Info: ', info);
    const parsedError = ErrorStackParser.parse(err);
    console.log(err.name, err.message, parsedError)
    const baseObj = getBaseErrorObj()
    baseObj.error_msg = err.message
    baseObj.stackTrace = parsedError
    clientError(baseObj)
    // Send error information to a logging service
};
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
