import axios from "axios";
import router from "@/router";
import store from "@/store";
import { Message } from "element-ui";
import { handleRequestFail } from "@/utils/HandleError/handle_error/handle_error";
import qs from "qs";
const BASE_API = process.env.VUE_APP_BASE_API;
let whiteNmae = [BASE_API + "/api/brands", BASE_API + "/api/summary"];

let backMsg = "";
const pending = new Map();
/**
 * 添加请求
 * @param {Object} config
 **/
const addPending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.data ? config.data : ""),
  ].join("&");
  if (pending.has(url) && !whiteNmae.includes(config.url)) {
    /* config.cancelToken = new axios.CancelToken((cancel) =>
      cancel(`重复的请求被主动拦截: ${url}`)
    );*/
    pending.get(url)(`重复的请求被主动拦截: ${url}`);
    config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
        pending.set(url, cancel);
      });
  } else {
    // 如果 pending 中不存在当前请求，则添加进去
    if (!whiteNmae.includes(config.url)) {
      config.cancelToken =
        config.cancelToken ||
        new axios.CancelToken((cancel) => {
          pending.set(url, cancel);
        });
    }
  }
};

/**
 * 移除请求
 * @param {Object} config
 */
const removePending = (config) => {
  const url = [
    config.method,
    config.url.replace(config.baseURL, ""), // 响应url会添加域名，需要去掉与请求URL保持一致
    qs.stringify(config.data ? config.data : ""), // 需要与request的参数结构保持一致，request中是对象，response中是字符串
  ].join("&");
  if (pending.has(url) && !whiteNmae.includes(config.url)) {
    // 如果在 pending 中存在当前请求标识，取消当前请求，并且移除
    pending.delete(url);
  }
};

const service = axios.create({
  baseURL: "",
  timeout: 300000,
  defaults: {
    withCredentials: true,
    crossDomain: true,
  },
  //   headers: {//解决请求from disk cache 后端缓存接口数据导致先从缓存拿，如果缓存没有，再从接口拿
  //     "Cache-control": "no-cache, no-store, must-revalidate",
  //     Pragma: "no-cache",
  //     Expires: 0,
  //   },
  /* headers: {
    "MOOJING-APIKEY": store.state.API_TOKEN
      ? store.state.API_TOKEN
      : "871D9CD60741071DC88D77AC04C4748B",
  },*/
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (store.state.user.API_TOKEN) {
      config.headers["MOOJING-APIKEY"] = store.state.user.API_TOKEN;
    }
    if (config.params) {
      _.each(config.params, function (value, key) {
        if (config.url.includes(`:${key}`)) {
          config.url = config.url.replace(`:${key}`, value);
          delete config.params[key];
        }
      });
    }
    // addPending(config); // 将当前请求添加到 pending 中
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  async (response) => {
    try {
      let { data, config, headers } = response;
      let { status } = data;
      let errorShowCallback = (msg) => {
        backMsg = msg;
      };
      await handleRequestFail({
        ...response,
        ...{ config: { errorShowCallback: errorShowCallback } },
      });
      if (status === "ok") {
        // removePending(response.config); // 在请求结束后，移除本次请求
        return { ...data, backMsg: backMsg };
      } else {
        // removePending(response.config); // 在请求结束后，移除本次请求
        return Promise.reject({ ...response, backMsg: backMsg });
      }
    } catch (err) {
      console.error(err, "axios 全局处理res报错");
      if (err && err.config) {
        // removePending(err.config); // 在请求结束后，移除本次请求
      }
      return Promise.reject(response);
    }
  },
  async (error) => {
    let errorShowCallback = function (msg) {
      backMsg = msg;
    };
    await handleRequestFail({
      ...error,
      ...{ config: { errorShowCallback: errorShowCallback } },
    });
    if (error && error.config) {
      //   removePending(error.config); // 在请求结束后，移除本次请求
    }
    return Promise.reject({
      ...error,
      backMsg: backMsg.includes("重复的请求被主动拦截: ")
        ? "RepeatRequest"
        : backMsg,
    });
  }
);

export default service;

/**
 * 清空 pending 中的请求（在路由跳转时调用）
 */
export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
};
