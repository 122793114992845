<template>
  <div class="header-box">
    <div class="header-left"
         v-if="hasUserInfo">
      <div class="logo clearfix">
        <div class="fl">
          <img v-if="!isPartner"
               src="../assets/logo_ld.png">
          <img v-else-if="$store.state.user.loginInfo.partner_logo_url"
               :src="$store.state.user.loginInfo.partner_logo_url">
        </div>
        <div class="fl"
             v-if="!isPartner">
          <div class="logoTitle"><a href="/#/catsheetnew"
               class="logoLink">魔镜电商聆听</a></div>
          <div class="logoText">MKTINDEX.COM</div>
        </div>
        <div class="fl"
             v-else>
          <div style="height: 100%; line-height: 50px;font-size: 16px; font-weight: 700">{{$store.state.user.loginInfo.partner_title}}</div>
        </div>
        <div class="fl"
             v-if="!isPartner">
          <el-popover placement="bottom-start"
                      trigger="hover">
            <div>
              <img src="../assets/kf.png">
            </div>
            <div style="text-align:center;">扫码联系售后人员</div>
            <i class="iconfont icon-kefu kf"
               slot="reference"><span class="text">售后支持</span></i>
          </el-popover>
        </div>
        <div class="fl"
             v-if="!isPartner">
          <i class="iconfont icon-wendang document"
             slot="reference">
            <a class="text"
               style="color:#ff7d00"
               href="https://qmctsz2s8t.feishu.cn/drive/folder/fldcnRdT2U46SNVTWmfL2cQBuWh"
               target="_blank">产品文档</a>
          </i>
        </div>
        <div class="fl"
             v-if="!isPartner">
          <el-popover placement="bottom-start"
                      trigger="hover">
            <div>
              <img src="../assets/kefu_code2.png"
                   style="width: 200px;height: 200px">
            </div>
            <div style="text-align:center;">产品使用遇到问题！</div>
            <div style="text-align:center;">可扫码申请产品讲解或培训哦～</div>
            <i class="iconfont icon-kefu2 kf"
               slot="reference"><span class="text">申请讲解/培训</span></i>
          </el-popover>
        </div>
        <div class="fl"
             style="line-height:56px;margin-left:20px;"><a style="color:#ff7d00"
             href="https://comment9.mktindex.com/">回到旧版</a></div>
      </div>
    </div>
    <div class="header-right">
      <template>
        <TopSearch />
      </template>
      <!-- <div class="complete"
           v-if="isCompleted&&!isPartner">
        <el-tooltip effect="dark"
                    content="魔镜CMI布道师"
                    placement="top">
          <img src="../assets/medal.png"
               class="completeImg cp"
               :class="{'flicker':isCompletedMove}"
               @click="openHeadProduct">
        </el-tooltip>
      </div> -->
      <userinfo @userLogin="userLoginValue"> </userinfo>
    </div>
  </div>
</template>

<script>
import TopSearch from './TopSearch.vue'
import { userinfo } from 'common'
import { getLatestTime } from '@/api/public'
export default {
  components: {
    TopSearch,
    userinfo,
  },
  data() {
    return {
      loginInfo: {},
      // isCompleted: false,
      // isCompletedMove: false,
    }
  },
  methods: {
    // openHeadProduct() {
    //   this.$store.dispatch(
    //     'user/changeHeadProductIsShow',
    //     !this.$store.state.user.headProductIsShow
    //   )
    // },
    userLoginValue(val) {
      this.loginInfo = val
      this.$store.dispatch(
        'user/changeShowDownloadBtn',
        this.loginInfo.download
      )
      this.$store.dispatch('user/changeLoginInfo', val)
      let normal = false
      if (val.is_show_opinion) {
        if (!val.is_three_squirrels_user) {
          normal = true
        }
      }
      this.$store.dispatch('user/changeIsNormal', normal)
      const is_question = JSON.parse(localStorage.getItem('is_question'))
      const { plat, cid } = this.$route.query
      const timeParams = {
        bid: this.brandId ? this.brandId : 'all',
        cid: cid,
        fx_url_id: this.fxUrlId,
        plat: plat,
        url_id: cid,
      }
      if (is_question) {
        timeParams.is_question = is_question
      }
      getLatestTime(timeParams).then((res) => {
        if (res.status == 'ok') {
          this.$store.dispatch('user/changeLatestTime', res.result).then(() => {
            this.$store.dispatch('user/changeUserLoaded', true)
          })
        }
      })
    },
  },
  computed: {
    isPartner() {
      return this.$store.state.user.loginInfo.is_partner
    },
    hasUserInfo() {
      return Object.keys(this.$store.state.user.loginInfo).length > 0
    },
    brandId() {
      return this.$store.state.user.brandId
    },
    fxUrlId() {
      return this.$store.state.user.fxUrlId
    },
  },
  watch: {
    // '$store.state.user.headProductList': {
    //   handler(newVal, oldVal) {
    //     if (newVal.length > 0) {
    //       let arr = _.filter(newVal, function (o) {
    //         return !o.status
    //       })
    //       if (arr.length > 0) {
    //         this.isCompleted = false
    //       } else {
    //         this.isCompleted = true
    //       }
    //     }
    //   },
    //   deep: true,
    // },
    // '$store.state.user.headProductCompletedMove': {
    //   handler(newVal, oldVal) {
    //     this.isCompletedMove = newVal
    //   },
    // },
  },
  created() {},
}
</script>

<style scoped lang='less'>
.header-box {
  display: flex;
  align-items: normal;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header-left {
  color: #ff7d00;
  min-width: 500px;
  .lb {
    display: inline-block;
  }
  .logo {
    img {
      height: 50px;
      padding: 5px 0px;
      margin-right: 12px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .logoTitle {
      font-size: 24px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ff7d00;
      margin-top: 5px;
      line-height: 30px;
    }
    .logoLink {
      color: #ff7d00;
    }
    .logoLink:hover,
    .logoLink:focus {
      text-decoration: none;
      outline: unset;
      outline-offset: unset;
    }
    .logoText {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #ff7d00;
      transform: scale(0.7);
      text-align: center;
      line-height: 10px;
    }
  }
  .kf {
    margin-left: 30px;
    cursor: pointer;
    line-height: 56px;
    font-size: 24px;
  }
  .document {
    margin-left: 30px;
    cursor: pointer;
    line-height: 56px;
    font-size: 24px;
  }
  .text {
    display: inline-block;
    font-size: 14px;
    vertical-align: top;
    margin-left: 4px;
  }
}
.header-right {
  display: flex;
  align-items: center;
  .complete {
    position: relative;
    width: 26px;
    height: 26px;
    margin-left: 10px;
  }
}
</style>
<style lang='less'>
.outer-container .outheader .header-box .header-right .user {
  margin-left: 20px;
}
.complete {
  position: relative;
  width: 26px;
  height: 26px;
  margin-left: 10px;
}
.completeImg {
  width: 26px;
  height: 26px;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
