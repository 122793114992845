// 注意：一定是 exports，不是 export，否则会报错，报错信息是下列的中的内容不是 string
module.exports = {
	topHead: {
		login_label: '登录',
		login_out: '退出',
	},
	mainHead: {
		tabs_Summary: '基本信息',
		tabs_EachSound: '方面&观点',
		tabs_TopBrand: '声量TOP100品牌',
		tabs_TopShop: '店铺分析',
		tabs_TopItem: '商品分析',
		tabs_TopSku: 'SKU分析',
		tabs_CatList: '类目分析',
		tabs_Itemtastelist: '商品口味分析',
		tabs_Customaspect: '自定义分析',
	},
	userInfo: {
		select_phone_title: '选择一个手机号进行验证',
		select_placeholder: '请选择',
		search_phone: '搜索手机号',
		input_sms: '输入验证码',
		get_sms: '获取验证码',
		verify: '验证',
		cancel: '取消',
	},
	baseInfo: {
		downLoad: '下载',
		downLoading: '下载准备中...',
		ranking: '序号',
		// headProductText: '为什么值得买：',
	},
	catList: {
		catName: '类目',
		comment_count: '评论数',
		volume_count: '声量数',
		pos_volume: '正向声量',
		neg_volume: '负向声量',
		neu_volume: '中性声量',
		percentage: '占比',
		neg_percentage: '负向率',
		neg_percentage_ratio: '负向声量占比',
	},
	shopList: {
		shopName: '店铺',
		advantage: '优势方面',
		inferiority: '劣势方面',
	},
	itemList: {
		image: '图片',
		itemName: '商品',
		price: '价格',
	},
	brandList: {
		name: '声量TOP100品牌',
		collect: '汇总',
	},
	skuList: {
		image: '图片',
		itemName: '商品',
		skuName: 'SKU名称',
		price: '价格',
	},
	attrList: {
		attr: '属性',
		sold: '销量',
		pos_volume: '正向声量',
		neg_volume: '负向声量',
		neu_volume: '中性声量',
		neg_per: '差评率',
		pos_per: '好评率',
	},
	setting: {
		phone_set: 'Mobile Number Setting',
		primary_number: 'Your Primary Mobile Number',
		second_number: 'Your Secondary Mobile  Number',
		delete: 'Delete',
		phone_placeholder: 'Please enter content',
		sms: 'SMS login verification',
		add: 'Add mobile number',
		success: 'Mobile number added successfully',
		confirm: 'Confirm',
		dialog_phone: 'Primary Mobile Number',
		dialog_input_placeholder: 'Enter verification code',
		verification: 'Verification',
		cancel: 'Cancel',
		yesterday: 'yesterday',
		7: 'Nearly 7 days',
		30: 'Nearly 30 days',
		90: 'Nearly 90 days',
		enter_num: 'Enter your phone number',
		enter_correct_num: 'Enter the correct phone number',
		first_num: 'The first phone number must enable SMS login verification.',
	},
	changePasswordPanel: {
		change_password_title:
			'Change The Password (Only the primary mobile number is available)',
		change_password: 'Change password',
		use_latest_cat:
			'When the platform category structure changes, use the latest category structure to track historical data.',
		message_success: 'Successfully modified.',
	},
}
