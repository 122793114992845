<template>
  <div class="mainHeader">
    <div class="header-one"
         v-if="currentRoute == 'catSheet'">
      <FilterHeader :shopName="shopName"
                    :brandName="brandName"
                    :itemName="itemName"
                    :skuName="skuName"
                    :itemAnalyse="itemAnalyse"
                    :navTabs="navTabs" />
      <div class="pic-type-select-area">
        <el-checkbox-group v-model="picType"
                           @change="changePicType">
          <el-checkbox label="1"
                       :disabled="picType.indexOf('2') == -1">有图评论</el-checkbox>
          <el-checkbox label="2"
                       :disabled="picType.indexOf('1') == -1">无图评论</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>

    <div class="header-two"
         v-if="currentRoute == 'catSheet'">
      <el-tabs v-model="navTabsValue"
               type="card"
               class="tabSign"
               @tab-click="handleTabClick"
               :before-leave="beforeLeave">
        <el-tab-pane :key="item.name"
                     v-for="item in navTabs"
                     :name="item.name">
          <span slot="label">
            <navTabRename ref="navTabs"
                          :item="item"
                          :hideList="hideList"
                          @modifyTabName="modifyTabName"
                          @deleteHideTabs="deleteHideTabs"
                          @hideTab="hideTab" />
          </span>
        </el-tab-pane>
        <el-tab-pane name="more"
                     v-if="hideList.length > 0">
          <el-dropdown trigger="click"
                       slot="label"
                       @command="deleteHideTabs">
            <span class="not-draggable">更多...</span>
            <el-dropdown-menu slot="dropdown">
              <div v-for="item in hideList"
                   :key="item.index">
                <el-dropdown-item v-if="item.name"
                                  :key="item.index"
                                  :command="item">{{item.name}}</el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-tab-pane>
        <!-- <el-tab-pane name="noHide"
                     v-else /> -->
      </el-tabs>
    </div>

    <div class="header-three"
         v-if="currentRoute == 'contrastAnalysis'">
      <div class="right-area">
        <div class="time-picker-area">
          <PickerCpn ref="pickerCpn"
                     :typ="pickerTyp" />
          <div style="margin-left: 20px">
            <el-button @click="contrastConfirm"
                       size="mini"
                       type="primary"> 确定 </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import { getNav, putNav } from '@/api/public'
import FilterHeader from '@/components/FilterHeader'
import { paramsObj } from '@/mixins/mixins.js'
import i18n from '@/components/I18n'
import PickerCpn from './FilterHeader/PickerCpn.vue'
import navTabRename from '@/components/inputRename/navTabRename.vue'
const defaultTabs = []
export default {
  components: {
    FilterHeader,
    PickerCpn,
    navTabRename,
  },
  data() {
    return {
      navTabs: _.cloneDeep(defaultTabs),
      shopName: [],
      brandName: '',
      itemName: [],
      skuName: [],
      itemAnalyse: '',
      brand: '',
      picType: this.$store.state.user.picType.split(','),
      tabNameValue: '',
      hideList: [],
      isJumpTab: false,
      eventTab: {},
      url_ids: '', //切换时间时对比分析页面如果已有对比项需要url_ids参数
    }
  },
  computed: {
    navTabsValue: {
      get() {
        if (this.$store.state.user.ActivedTab) {
          return this.$store.state.user.ActivedTab
        } else {
          return 'Summary'
        }
      },
      set(val) {
        this.$store.state.user.ActivedTab = val
      },
    },
    currentRoute() {
      return this.$route.name
    },
    putNavUrlParams() {
      const { plat, catId, start, end, tsType, shopId, itemId, skuId } =
        this.$store.state.user
      const urlParams = {
        brand_id: this.$route.query.brand ? this.$route.query.brand : 'all',
        cat_id: catId,
        end: end,
        fx_url_id: this.$store.state.user.fxUrlId,
        item_id: itemId,
        parent_cat_id: this.$store.state.user.parentCatId,
        shop_id: shopId,
        sku_id: skuId,
        start: start,
        ts_type: tsType,
        url_id: catId,
      }
      return urlParams
    },
    fxUrlId() {
      return this.$store.state.user.fxUrlId
    },
    pickerTyp() {
      if (this.$route.name == 'contrastAnalysis') {
        return 'contrastAnalysis'
      } else {
        return 'catsheetnew'
      }
    },
  },
  created() {
    this.getNavDataDebounce = _.debounce(this.getNavData, 300)
    const { plat, cid, start, end } = this.$route.query
    if (plat && cid && start && end) {
      this.getNavDataDebounce()
    }
  },
  mixins: [paramsObj],
  methods: {
    tabDrop() {
      this.$nextTick(() => {
        let moreDom = document.getElementById('tab-more')
        if (moreDom) {
          moreDom.classList.add('not-draggable') //更多的tab项不能拖拽
        }
      })
      const el = document.querySelector('.tabSign .el-tabs__nav') //找到想要拖拽的那一列
      const _this = this
      Sortable.create(el, {
        filter: '.not-draggable',
        onEnd({ newIndex, oldIndex }) {
          //oldIIndex拖放前的位置， newIndex拖放后的位置
          const currRow = _this.navTabs.splice(oldIndex, 1)[0] //鼠标拖拽当前的el-tabs-pane
          if (!currRow) {
            return
          }
          _this.navTabs.splice(newIndex, 0, currRow)
          let obj = {
            from: oldIndex,
            to: newIndex,
            name: currRow.rule_id ? currRow.rule_id : currRow.name,
          }
          _this.moveTab(obj)
        },
      })
    },
    moveTab(moveParam) {
      const { plat } = this.$store.state.user
      const param = { order: moveParam }
      putNav(plat, this.putNavUrlParams, param).then((res) => {
        this.getNavDataDebounce()
      })
    },
    changePicType(val) {
      this.$store.dispatch('user/changePicType', val.join(','))
    },
    beforeLeave(tabName) {
      if (tabName == 'saveBtn' || tabName == 'more' || tabName == 'noHide') {
        return false
      }
    },
    transformType(val) {
      if (val == '1') {
        return ''
      } else if (val == '2') {
        return '(正向)'
      } else if (val == '3' || val == '4') {
        return '(负向)'
      } else if (val == '5') {
        return '(中性)'
      }
    },
    hideTab(hideParam) {
      const { plat } = this.$store.state.user
      const param = hideParam
      putNav(plat, this.putNavUrlParams, param).then((res) => {
        this.getNavDataDebounce()
      })
    },
    deleteHideTabs(item) {
      const { plat } = this.$store.state.user
      if (item.analyse_typ) {
        const param = {
          hide: {
            from: '',
            name: item.analyse_typ,
            to: false,
          },
        }
        putNav(plat, this.putNavUrlParams, param).then((res) => {
          this.getNavDataDebounce()
        })
      } else if (item.rule_id) {
        const param = {
          hide: {
            from: '',
            name: item.rule_id,
            to: false,
          },
        }
        putNav(plat, this.putNavUrlParams, param).then((res) => {
          this.getNavDataDebounce()
        })
      }
    },
    modifyTabName(titleParam, tabNameValue) {
      const { plat } = this.$store.state.user
      const titleIndex = this.$refs.navTabs.findIndex((item) => {
        return item.inputItem.title == titleParam.title.from
      })
      if (tabNameValue && tabNameValue !== '') {
        const param = titleParam
        putNav(plat, this.putNavUrlParams, param).then((res) => {
          if (res.status == 'ok') {
            if (titleIndex > -1) {
              this.$message.success('修改成功')
              this.getNavDataDebounce()
              setTimeout(() => {
                this.$refs.navTabs[titleIndex].showInput = false
              }, 500)
            }
          } else {
            this.$message.error('修改失败')
          }
        })
      }
    },
    getNavData() {
      const {
        plat,
        catId,
        start,
        end,
        tsType,
        shopId,
        itemId,
        skuId,
        parentCatId,
        brandId,
      } = this.$store.state.user
      const { cid, brand } = this.$route.query
      let param = {
        cat_id: catId ? catId : cid,
        url_id: catId ? catId : cid,
        fx_url_id: this.fxUrlId,
        brand_id: brandId ? brandId : brand ? brand : 'all',
        shop_id: shopId,
        item_id: itemId,
        sku_id: skuId,
        parent_cat_id: parentCatId,
        start: this.$route.query.start ? this.$route.query.start : start,
        end: this.$route.query.end ? this.$route.query.end : end,
        ts_type: tsType,
      }
      let paramPlat = plat ? plat : this.$route.query.plat
      console.log('nav3--------------', paramPlat, 'param--------------', param)
      if (!paramPlat) {
        return
      }
      let datas = window.cache_cat_jd
      switch (paramPlat) {
        case 'jd':
          datas = window.cache_cat_jd
          break
        case 'tmall':
          datas = window.cache_cat
          break
        case 'custom':
          datas = window.cache_cat_custom
          break
        case 'douyin':
          datas = window.cache_douyin_cats
          break
        case 'amazon_us':
          datas = window.cache_cat_amazon_us
          break
        default:
          datas = window.cache_cat_jd
      }
      if (
        datas &&
        datas.result.childrenList &&
        datas.result.childrenList[param.cat_id]
      ) {
        const parent_cat_id = datas.result.childrenList[param.cat_id].parent_id
        if (parent_cat_id) {
          param.parent_cat_id = parent_cat_id
        }
      }
      getNav({ plat: paramPlat }, param)
        .then((res) => {
          if (res.status == 'ok') {
            this.navTabs = _.cloneDeep(defaultTabs)
            let data = res.result.content_analyses
            let hideData = res.result.hide_cat_analyse
            this.$store.dispatch('user/changeNavData', res.result)
            this.$store.dispatch('user/changeCatName', res.result.cat_name)
            this.$store.dispatch('user/changeIsShowNeu', res.result.is_show_neu)
            this.hideList = []
            const { itemAnalyse } = this.$store.state.user
            for (let i = 0; i < hideData.length; i++) {
              const element = hideData[i]
              this.hideList.push({
                index: i,
                name: element.title,
                analyse_typ: element.analyse_typ,
                rule_id: element.rule_id,
              })
            }
            const iscatparent = res.result.iscatparent
            if (res.result.shop_name && res.result.shop_name.length == 1) {
              this.shopName = res.result.shop_name
            } else if (
              res.result.shop_name &&
              res.result.shop_name.length > 1
            ) {
              this.shopName = []
              let shopStr = '已选择' + res.result.shop_name.length + '个店铺'
              this.shopName.push(shopStr)
            } else {
              this.shopName = []
            }
            if (res.result.brand_name) {
              this.brandName = res.result.brand_name
              const brandIndex = this.navTabs.findIndex(
                (item) => item.name == 'TopBrand'
              )
              this.navTabs.splice(brandIndex, 1)
            } else {
              this.brandName = ''
            }
            if (res.result.item_name && res.result.item_name.length == 1) {
              this.itemName = res.result.item_name
            } else if (
              res.result.item_name &&
              res.result.item_name.length > 1
            ) {
              this.itemName = []
              let itemStr = '已选择' + res.result.item_name.length + '个商品'
              //   this.itemName.push(itemStr)
              this.itemName = res.result.item_name
            } else {
              this.itemName = []
            }
            if (res.result.sku_name && res.result.sku_name.length == 1) {
              this.skuName = res.result.sku_name
            } else if (res.result.sku_name && res.result.sku_name.length > 1) {
              this.skuName = []
              let itemStr = '已选择' + res.result.sku_name.length + '个SKU'
              this.skuName.push(itemStr)
            } else {
              this.skuName = []
            }
            if (itemAnalyse) {
              this.itemAnalyse = itemAnalyse
            } else {
              this.itemAnalyse = ''
            }
            if (!iscatparent) {
              const catIndex = this.navTabs.findIndex(
                (item) => item.name == 'CatList'
              )
              this.navTabs.splice(catIndex, 1)
            }
            this.$store.dispatch('user/changeNavContent', data)
            for (let i = 0; i < data.length; i++) {
              if (data[i].analyse_typ) {
                const obj = {
                  title: data[i].title,
                  name: data[i].analyse_typ,
                  rule_id: data[i].rule_id,
                  analyse_typ: data[i].analyse_typ,
                  custom_typ: data[i].custom_typ,
                }
                if (data[i].origin_user) {
                  obj.origin_user = data[i].origin_user
                }
                this.navTabs.splice(this.navTabs.length, 0, obj)
              } else {
                //analyse_typ为空的表示是自定义的
                const obj = {
                  title: data[i].title,
                  name: data[i].rule_id,
                  rule_id: data[i].rule_id,
                  analyse_typ: '',
                  custom_typ: data[i].custom_typ,
                  ner_type: data[i].ner_type,
                  anta_target: data[i].anta_target,
                }
                if (data[i].origin_user) {
                  obj.origin_user = data[i].origin_user
                }
                this.navTabs.splice(this.navTabs.length, 0, obj)
              }
            }
            if (this.isJumpTab) {
              this.isJumpTab = false
              this.handleTabClick(this.eventTab)
            }
            this.tabDrop()
            let activeRuleId = this.$store.state.user.activeRuleId
            let activeRuleIndex = data.findIndex((r) => {
              return r.rule_id == activeRuleId
            })
            if (activeRuleIndex > -1) {
              let item = data[activeRuleIndex]
              let activeRuleTab = {}
              if (item.analyse_typ == '') {
                let activeRuleTab = {
                  name: item.rule_id,
                  title: item.title,
                  rule_id: item.rule_id,
                  analyseTyp: '',
                  custom_typ: item.custom_typ,
                  ner_type: item.ner_type,
                  anta_target: item.anta_target,
                }
                this.handleTabClick(activeRuleTab)
              } else if (item.rule_id && item.analyse_typ) {
                let activeRuleTab = {
                  name: item.rule_id,
                  title: item.title,
                  rule_id: item.rule_id,
                  analyseTyp: item.analyse_typ,
                }
                this.handleTabClick(activeRuleTab)
              }
            }
          } else {
            this.$store.dispatch('user/changeCatName', '')
          }
        })
        .catch((e) => {
          this.$store.dispatch('user/changeCatName', '')
        })
        .finally(() => {})
    },
    handleTabClick(tab, event) {
      if (tab.name == 'saveBtn' || tab.name == 'more' || tab.name == 'noHide') {
        return false
      }
      let item = this.navTabs.find((item) => {
        return item.name == tab.name
      })
      if (item.rule_id && item.analyse_typ) {
        this.$store.dispatch('user/changeTitle', item.title)
        this.$store.dispatch('user/changeRuleId', item.rule_id)
        this.$store.dispatch('user/changeAnalyseTyp', item.analyse_typ)
        this.$store.dispatch('user/changeCustomTyp', item.custom_typ)
      } else if (item.analyse_typ == '') {
        this.$store.dispatch('user/changeTitle', item.title)
        this.$store.dispatch('user/changeRuleId', item.rule_id)
        this.$store.dispatch('user/changeAnalyseTyp', item.analyse_typ)
        this.$store.dispatch('user/changeCustomTyp', item.custom_typ)
        this.$store.dispatch('user/changeNerType', item.ner_type)
        this.$store.dispatch('user/changeAntaTarget', item.anta_target)
      }
      this.$store.dispatch('user/changeActivedTab', tab.name)
    },
    contrastConfirm() {
      const routeQuery = _.cloneDeep(this.$route.query)
      const { dateTime } = this.$refs.pickerCpn
      routeQuery.start = dateTime[0]
      routeQuery.end = dateTime[1]
      if (this.url_ids) {
        routeQuery.url_ids = this.url_ids
      }
      this.$router.push({
        name: 'contrastAnalysis',
        query: routeQuery,
      })
    },
    jumpHideTab(tab) {
      this.deleteHideTabs(tab)
      this.isJumpTab = true
    },
    searchNavHideTab(tab) {
      this.eventTab = tab
      const { title, rule_id } = tab
      let index = this.navTabs.findIndex((item) => {
        return title == item.title || rule_id == item.rule_id
      })
      if (index > 0) {
        this.handleTabClick(tab)
      } else {
        this.jumpHideTab(tab)
      }
    },
  },
  mounted() {
    this.$eventBus.$on('jumpScopeTab', (tab) => {
      this.searchNavHideTab(tab)
    })
    this.$eventBus.$on('TabRename', (titleParam, tabNameValue) => {
      this.modifyTabName(titleParam, tabNameValue)
    })
    this.$eventBus.$on('changeContrastAnalysisUrlIds', (val) => {
      this.url_ids = val
    })
    this.$eventBus.$on('reloadNavData', (tab) => {
      this.getNavDataDebounce()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('jumpScopeTab')
    this.$eventBus.$off('TabRename')
    this.$eventBus.$off('changeContrastAnalysisUrlIds')
    this.$eventBus.$off('reloadNavData')
  },
  watch: {
    // paramsObj: {
    //   handler(newVal, oldVal) {
    //     const { plat, catId, parentCatId, start, end } = newVal
    //     if (
    //       this.$route.name != 'contrastAnalysis' &&
    //       plat &&
    //       catId &&
    //       start &&
    //       end
    //     ) {
    //       console.log(plat, catId, start, end, '调用nav')
    //       this.getNavDataDebounce()
    //     }
    //   },
    // },
    $route: {
      handler(newVal, oldVal) {
        if (newVal.name == 'catSheet') {
          const { plat, cid, start, end } = this.$route.query
          console.log(plat, cid, start, end, '调用nav2')
          if (plat && cid && start && end) {
            this.getNavDataDebounce()
          }
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-tabs__nav {
  white-space: normal;
}
.pic-type-select-area {
  padding-left: 10px;
  padding-bottom: 15px;
}
.timePick {
  /deep/ .el-date-editor .el-range-separator {
    width: 7%;
  }
}
.mainHeader {
  margin-right: 2%;
}
.header-two {
  /deep/ .el-tabs--card > .el-tabs__header {
    margin-bottom: 0;
  }
  /deep/ .el-tabs--card > .el-tabs__header .el-tabs__item {
    border-bottom: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-left: 0;
  }
  /deep/ .el-tabs__item.is-active {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #fff;
  }
  .el-icon-remove {
    display: none;
  }
  .el-icon-edit {
    display: none;
  }
  .icon-box:hover > .icon-show-box > .el-icon-remove {
    display: inline-block;
  }
  .icon-box:hover > .icon-show-box > .el-icon-edit {
    display: inline-block;
  }
  .tab-name-input {
    display: inline-block;
    width: 100px;
  }
  .el-icon-circle-check {
    color: #85868a;
    margin: 0 5px 0 5px;
  }
  .el-icon-circle-close {
    color: #85868a;
  }
}
.header-three {
  .right-area {
    display: flex;
    flex-direction: row-reverse;
    .time-picker-area {
      display: flex;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>