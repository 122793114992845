import request from "@/utils/request";
const BASE_API = process.env.VUE_APP_BASE_API;

export function getPlatCats(params) {
  return request({
    url: `${BASE_API}/api/linsten/plat_cats`,
    method: "get",
    params
  });
}

export function postUrlId(data){
  return request({
    url: `${BASE_API}/api/linsten/url_id`,
    method: "post",
    data
  })
}

export function getUrlId(param) {
  return request({
    url: `${BASE_API}/api/linsten/url_id`,
    method: 'get',
    params: param
  });
}

export function getFxUrlData(param) {
  return request({
    url: `${BASE_API}/api/linsten/fx_url_header`,
    method: 'get',
    params: param
  });
}