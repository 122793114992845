import request from "@/utils/request";
import { formateObjToParamStr } from "@/utils";
const BASE_API = process.env.VUE_APP_BASE_API;

export function getDedup(urlParam, param) {
  return request({
    url: `${BASE_API}/api/linsten/platform/` + urlParam.platform + `/cats/` + urlParam.cats + `/brands/` + urlParam.brands + `/dedup`,
    method: "get",
    params: param
  })
}

export function getBasics(urlParam, param) {
  return request({
    url: `${BASE_API}/api/linsten/platform/` + urlParam.platform + `/cats/` + urlParam.cats + `/brands/` + urlParam.brands + `/basics`,
    method: "get",
    params: param
  })
}

export function getTrend(urlParam, param) {
  return request({
    url: `${BASE_API}/api/linsten/platform/` + urlParam.platform + `/cats/` + urlParam.cats + `/brands/` + urlParam.brands + `/trend`,
    method: "get",
    params: param
  })
}

//获取声量趋势列表的下载地址
export function getTrendListDataUrl(urlParam, param) {
  return `${BASE_API}/api/linsten/platform/` + urlParam.platform + `/cats/` + urlParam.cats + `/brands/` + urlParam.brands + `/trend?` + formateObjToParamStr(param);
}

export function getFxSummary(param) {
  return request({
    url: `${BASE_API}/api/linsten/fx_summary`,
    method: "get",
    params: param
  })
}

export function postCommentUrlParams(data) {
  return request({
    url: `${BASE_API}/api/linsten/comment_url_params`,
    method: "post",
    data: data
  })
}

export function getBrand(param) {
  return request({
    url: `${BASE_API}/api/linsten/search/brand`,
    method: "get",
    params: param
  })
}
