<template>
  <div class="filter-header-cpn">
    <div class="left-area">
      <div class="plat-select-area">
        <span>平台: </span>
        <el-select size="mini"
                   v-model="plat"
                   @change="changePlat"
                   :disabled="!!fxUrlId">
          <el-option v-for="item in platOptions"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="cat-select-area"
           v-if="showCatTree">
        <span style="min-width:35px">类目: </span>
        <CatTree ref="CatTree"
                 :plat="plat"
                 :checkedKeys="defaultCheckedKeys"
                 :multiple="true"
                 v-model="selectIds"
                 :treeDomId="'catSheetTree'"
                 :treeDomSearchId="'catSheetTreeSearchId'"
                 :width="200"
                 :height="300"></CatTree>
      </div>
      <!-- 店铺分析筛选条件 -->
      <div class="tag"
           v-if="shopName.length !== 0">
        <span class="placehold">></span>
        <el-tag closable
                class="tagName"
                @close="handleShopClose">
          店铺:{{html2Text(shopName)}}
        </el-tag>
      </div>
      <!-- 品牌分析筛选条件 -->
      <div class="tag"
           v-if="brandName">
        <span class="placehold">></span>
        <el-tag closable
                class="tagName"
                @close="handleBrandClose">
          品牌:{{html2Text(brandName)}}
        </el-tag>
      </div>
      <!-- 商品分析筛选条件 -->
      <div class="tag"
           v-if="itemName.length !== 0">
        <span class="placehold">></span>
        <el-tag closable
                class="tagName"
                :title="html2Text(itemName)"
                @close="handleItemClose"
                v-if="itemName.length == 1">
          商品:{{html2Text(itemName)}}
        </el-tag>
        <el-tag closable
                class="tagName"
                :title="html2Text(itemName)"
                @close="handleItemClose"
                v-if="itemName.length > 1">
          商品: 已选择{{itemName.length}}个商品
        </el-tag>
      </div>
      <!-- SKU分析筛选条件 -->
      <div class="tag"
           v-if="skuName.length !== 0">
        <span class="placehold">></span>
        <el-tag closable
                class="tagName"
                @close="handleSkuClose">
          SKU：{{html2Text(skuName)}}
        </el-tag>
      </div>
      <!-- 属性分析筛选条件 -->
      <div class="aspect-tag"
           v-for="item in attrlist"
           :key="item.index + item.attrName">
        <span class="placehold">></span>
        <el-tag closable
                :key="item.index"
                @close="handleAttrListClose(item)"
                class="aspectName">
          <template>
            <span>{{transformAttrName(item.attrName) + ':' + html2Text(item.attrId)}}</span>
          </template>
        </el-tag>
      </div>
      <!-- 商品搜索筛选条件 -->
      <div class="tag"
           v-if="titlelist.length > 0"
           v-for="item in titlelist"
           :key="item.index">
        <span class="placehold">></span>
        <el-tag closable
                class="tagName"
                :key="item.index"
                @close="handleTitleClose(item)">
          商品: "{{item.word}}"
        </el-tag>
      </div>
      <!-- 评论搜索筛选条件 -->
      <div class="tag"
           v-if="contentWord">
        <span class="placehold">></span>
        <el-tag closable
                class="tagName"
                @close="handleContentClose">
          评论: "{{contentWord}}"
        </el-tag>
      </div>
      <!-- 价格搜索筛选条件 -->
      <div class="tag"
           v-if="priceMax || priceMin">
        <span class="placehold">></span>
        <el-tag closable
                class="tagName"
                @close="handlePriceClose">
          价格: {{priceInteval}}
        </el-tag>
      </div>
      <!-- 商品口味分析 -->
      <div class="tag"
           v-if="itemAnalyse">
        <span class="placehold">></span>
        <el-tag closable
                class="tagName"
                @close="handleItemAnalyseClose">
          口味:{{html2Text(itemAnalyse)}}
        </el-tag>
      </div>
      <!-- 分析+的查询条件... -->
      <el-tooltip effect="light"
                  v-if="fxUrlId"
                  popper-class="fenxi-tooltip"
                  placement="bottom">
        <div slot="content">
          <div class="fenxi-tool-content"
               style="font-size: 14px">
            <span class="fenxi-tool-plat">
              平台: {{fxUrlIdParams.plat}}
            </span>
            <span class="fenxi-tool-cat">
              类目:
              <span style="padding: 0; margin: 0">{{fxUrlIdParams.cat}}</span>
            </span>
            <span class="fenxi-tool-keyword"
                  v-if="!!fxUrlIdParams.keyword">
              关键词:
              <span>{{fxUrlIdParams.keyword}}</span>
            </span>
            <span class="fenxi-tool-avgPrice"
                  v-if="!!fxUrlIdParams.avgPrice">
              价格:
              <span>{{fxUrlIdParams.avgPrice}}</span>
            </span>
            <span class="fenxi-tool-sold"
                  v-if="!!fxUrlIdParams.sold">
              销量:
              <span>{{fxUrlIdParams.sold}}</span>
            </span>
            <span class="fenxi-tool-sales"
                  v-if="!!fxUrlIdParams.sales">
              销售额:
              <span>{{fxUrlIdParams.sales}}</span>
            </span>
            <span class="fenxi-tool-brand"
                  v-if="!!fxUrlIdParams.brand">
              品牌:
              <span>{{fxUrlIdParams.brand}}</span>
            </span>
            <span class="fenxi-tool-shop"
                  v-if="!!fxUrlIdParams.shop">
              店铺:
              <span>{{fxUrlIdParams.shop}}</span>
            </span>
            <span class="fenxi-tool-onlineTime"
                  v-if="!!fxUrlIdParams.onlineTime">
              上架时间:
              <span>{{fxUrlIdParams.onlineTime}}</span>
            </span>
            <span class="fenxi-tool-filingDate"
                  v-if="!!fxUrlIdParams.filingDate">
              备案时间:
              <span>{{fxUrlIdParams.filingDate}}</span>
            </span>
            <span class="fenxi-tool-attr"
                  v-for="item in attrList"
                  :key="item.index">
              <span>
                {{ item.name }}: {{ item.value }}
              </span>
            </span>
          </div>
        </div>
        <div class="tag">
          <span class="placehold">></span>
          <el-tag closable
                  class="tagName"
                  @close="handleFxUrlIdClose">
            分析+的查询条件...
          </el-tag>
        </div>
      </el-tooltip>
      <div class="aspect-tag"
           v-for="item in scenelist"
           :key="item.index + item.name">
        <span class="placehold">></span>
        <el-tag closable
                :key="item.index"
                @close="handleScenelistClose(item)"
                class="aspectName">
          <template v-if="!item.analyse_typ">
            <span v-if="item.label_level == 2">{{item.label + '：'}}{{item.name}}{{transformType(item.type)}}</span>
            <span v-if="item.label_level == 1
                  && !item.ner_type
                  && !item.rule_id">{{item.label + '：'}}{{item.name}} {{item.view + '：'}}{{item.viewName}}{{transformType(item.type)}}</span>
            <span v-if="item.label_level == 1
                  && item.ner_type">{{item.label + '：'}}{{item.name}}{{transformType(item.type)}}</span>
            <span v-if="item.label_level == 1
                  && !item.ner_type
                  && item.rule_id">{{item.label + '：'}}{{item.name}}{{transformType(item.type)}}</span>
            <span v-if="item.label_level == ''
                  && !item.name.includes(',')">{{item.label + ':   '+ '"' + item.name+ '"'}}</span>
            <span v-if="item.label_level == ''
                  && item.name.includes(',')">{{item.label}}: 已选择{{item.name.split(',').length}}个{{item.label}}</span>
          </template>
          <template v-else-if="item.analyse_typ == 'people_word' || item.analyse_typ == 'scene_word'">
            <span>{{item.label + ':   '+ '"' + item.name+ '"'}}</span>
          </template>
        </el-tag>
      </div>
      <div class="subscribe-area"
           @click="followClick">
        <i v-show="!isMarked"
           class="iconfont icon-heart-fill"
           style="color: #7d8a9b; margin-left: 12px; vertical-align: middle"></i>
        <i v-show="isMarked"
           class="iconfont icon-heart-fill"
           style="color: #ff7d00; margin-left: 12px; vertical-align: middle"></i>
        <span class="headText"
              v-show="!isMarked">
          <span style="vertical-align: middle; position: relative; top: 0px">＋</span>
          <span style="vertical-align: middle">关注</span>
        </span>
        <span class="headText"
              v-show="isMarked">
          <span style="vertical-align: middle; position: relative; top: 0px">＋</span>
          <span style="vertical-align: middle">已关注</span>
        </span>
      </div>
    </div>
    <div class="right-area">
      <div class="question-area">
        <QuestionSelect />
      </div>
      <div class="time-picker-area">
        <PickerCpn ref="PickerCpn" />
        <div style="margin-left: 20px">
          <el-button @click="confirm"
                     size="mini"
                     type="primary"> 确定 </el-button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {
  getPlatCats,
  postUrlId,
  getUrlId,
  getFxUrlData,
} from '@/api/filter-header.js'
import { getAttention, putAttention } from '@/api/public.js'
import { deleteAttention } from '@/api/subscription'
import { paramsObj } from '@/mixins/mixins.js'
import { html2Text, getLastFullWeekStartAndEnd, formatDate } from '@/utils'
import CatTree from '../CatTree/index.vue'
import PickerCpn from './PickerCpn.vue'
import QuestionSelect from './QuestionSelect.vue'
const PLAT_LIST = [
  {
    value: 'jd',
    label: '京东',
  },
  {
    value: 'tmall',
    label: '天猫',
  },
  {
    value: 'douyin',
    label: '抖音',
  },
  {
    value: 'amazon_us',
    label: '亚马逊',
  },
  {
    value: 'custom',
    label: '自定义',
  },
]
export default {
  name: 'FilterHeader',
  data() {
    return {
      platOptions: [],
      plat: '',
      defaultCheckedKeys: [],
      selectIds: [],
      isMarked: false,
      dateTime: ['', ''],
      showCatTree: false,
      fxUrlIdParams: {
        plat: '',
        cat: '',
        keyword: '',
        avgPrice: '',
        sold: '',
        sales: '',
        brand: '',
        shop: '',
        onlineTime: '',
        filingDate: '',
      },
      priceInteval: '',
      attrList: [],
      childrenCats: [],
      customCats: [],
      DEFAULT_CID: {
        jd: '1316',
        tmall: '1801',
        douyin: '20085',
        custom: 'haersi',
        amazon_us: '1111111100000033',
      },
      isChangePlat: false,
      isInit: true,
    }
  },
  props: {
    shopName: {
      type: Array,
      default: [],
    },
    brandName: {
      type: String,
      default: '',
    },
    itemName: {
      type: Array,
      default: [],
    },
    skuName: {
      type: Array,
      default: [],
    },
    itemAnalyse: {
      type: String,
      default: '',
    },
    navTabs: {
      type: Array,
      default: [],
    },
  },
  components: {
    CatTree,
    // DatePicker,
    PickerCpn,
    QuestionSelect,
  },
  methods: {
    html2Text(val) {
      return html2Text(val)
    },
    async handleShopClose() {
      await this.$store.dispatch('user/changeShopId', '')
      this.$store.dispatch('user/changeActivedTab', 'Summary')
    },
    async handleItemClose() {
      await this.$store.dispatch('user/changeItemId', '')
      this.$store.dispatch('user/changeActivedTab', 'Summary')
    },
    async handleSkuClose() {
      await this.$store.dispatch('user/changeSkuId', '')
      this.$store.dispatch('user/changeActivedTab', 'Summary')
    },
    async handleBrandClose() {
      await this.$store.dispatch('user/changeBrandId', '')
      this.$eventBus.$emit('deleteBrandWord')
      this.$store.dispatch('user/changeActivedTab', 'Summary')
    },
    async handleContentClose() {
      await this.$store.dispatch('user/changeContentWord', '')
      this.$eventBus.$emit('deleteContentWord')
      this.$store.dispatch('user/changeActivedTab', 'Summary')
    },
    async handlePriceClose() {
      await this.$store.dispatch('user/changePriceMin', '')
      await this.$store.dispatch('user/changePriceMax', '')
      this.$eventBus.$emit('deletePriceWord')
      this.$store.dispatch('user/changeActivedTab', 'Summary')
    },
    async handleItemAnalyseClose() {
      await this.$store.dispatch('user/changeItemAnalyse', '')
      this.$store.dispatch('user/changeActivedTab', 'Summary')
    },
    async handleFxUrlIdClose() {
      await this.$store.dispatch('user/changePlat', '')
      await this.$store.dispatch('user/changeCatId', '')
      await this.$store.dispatch('user/changeFxUrlId', '')
      await this.$router.push({
        name: 'catSheet',
        plat: '',
        cid: '',
      })
      this.$store.dispatch('user/changeActivedTab', 'Summary')
    },
    transformType(val) {
      if (val == 'total') {
        return ''
      } else if (val == 'pos') {
        return '(正向)'
      } else if (val == 'neg') {
        return '(负向)'
      } else {
        return '(中性)'
      }
    },
    transformAttrName(val) {
      if (val == 'style_no') {
        return '款号'
      } else if (val == 'apply_scene') {
        return '适用场景'
      } else {
        return '性别'
      }
    },
    changePlat(val) {
      this.isChangePlat = true
      const loginCatlist = this.loginInfo.cat_list
      this.plat = val
      let r = this.rand_cat(loginCatlist)
      this.defaultCheckedKeys = [r[1]]
      this.$store.dispatch('user/changePlat', val)
      console.log('切换平台', r)
      this.$store.dispatch('user/changeStart', r[2])
      this.$store.dispatch('user/changeEnd', r[2])
      sessionStorage.setItem('tsType', 'month')
      this.$store.dispatch('user/changeTsType', 'month')
      if (this.fxUrlId == '') {
        this.$store.dispatch('user/changeCatId', r[1])
      }
      this.$store.dispatch('user/changeActivedTab', 'Summary')
      this.$store.dispatch('user/changeLabelLevel', '')
      this.$store.dispatch('user/changeAspectType', '')
      this.$store.dispatch('user/changeWords', '')
      this.$store.dispatch('user/changeRouterAnalyseTyp', '')
      this.$store.dispatch('user/changeRouterRuleId', '')
      this.$store.dispatch('user/changeRouterNerType', '')
      this.$store.dispatch('user/changeShopId', '')
      this.$store.dispatch('user/changeBrandId', '')
      this.$store.dispatch('user/changeItemId', '')
      this.$store.dispatch('user/changeRouterRuleId', '')
      this.$store.dispatch('user/changeAttrId', '')
      this.$store.dispatch('user/changeAttrName', '')
      this.$store.dispatch('user/changeMultipleCatIdArr', [])
    },
    handleScenelistClose(item) {
      let index = item.index
      let query = _.cloneDeep(this.$route.query)
      if (this.customFilters.length == 1) {
        this.$store.dispatch('user/changeLabelLevel', '')
        this.$store.dispatch('user/changeAspectType', '')
        this.$store.dispatch('user/changeWords', '')
        this.$store.dispatch('user/changeRouterAnalyseTyp', '')
        this.$store.dispatch('user/changeRouterRuleId', '')
        this.$store.dispatch('user/changeRouterNerType', '')
        this.$store.dispatch('user/changeRouterAspLevel', '')
        delete query.label_level
        delete query.aspect_type
        delete query.words
        delete query.analyse_typ
        delete query.rule_id
        delete query.ner_type
        delete query.asp_level
        if (query.aspect_source) {
          query.aspect_source = ''
        }
        this.$store.dispatch('user/changeActivedTab', 'Summary')
        this.$router.push({
          name: this.$route.name,
          query,
        })
      } else if (this.customFilters.length > 1) {
        let labelLevelArr = this.$store.state.user.labelLevel
          .toString()
          .split(',')
        let aspectTypeArr = this.$store.state.user.aspectType.split(',')
        let wordsArr = []
        if (
          query.words.includes('~') &&
          !this.$store.state.user.words.includes('~')
        ) {
          wordsArr = query.words.split(',')
        } else {
          wordsArr = this.$store.state.user.words.split(',')
        }
        let analyseTypArr = this.$store.state.user.routerAnalyseTyp.split(',')
        let ruleIdArr = this.$store.state.user.routerRuleId.split(',')
        let nerTypeArr = this.$store.state.user.routerNerType.split(',')
        let aspLevelArr = this.$store.state.user.routerAspLevel.split(',')
        labelLevelArr.splice(index, 1)
        aspectTypeArr.splice(index, 1)
        wordsArr.splice(index, 1)
        analyseTypArr.splice(index, 1)
        ruleIdArr.splice(index, 1)
        nerTypeArr.splice(index, 1)
        aspLevelArr.splice(index, 1)
        this.$store.dispatch('user/changeLabelLevel', labelLevelArr.join(','))
        this.$store.dispatch('user/changeAspectType', aspectTypeArr.join(','))
        this.$store.dispatch('user/changeWords', wordsArr.join(','))
        this.$store.dispatch(
          'user/changeRouterAnalyseTyp',
          analyseTypArr.join(',')
        )
        this.$store.dispatch('user/changeRouterRuleId', ruleIdArr.join(','))
        this.$store.dispatch('user/changeRouterNerType', nerTypeArr.join(','))
        this.$store.dispatch('user/changeRouterAspLevel', aspLevelArr.join(','))
        query.label_level = this.$store.state.user.labelLevel
        query.aspect_type = this.$store.state.user.aspectType
        query.words = this.$store.state.user.words
        query.analyse_typ = this.$store.state.user.routerAnalyseTyp
        query.rule_id = this.$store.state.user.routerRuleId
        query.ner_type = this.$store.state.user.routerNerType
        query.asp_level = this.$store.state.user.routerAspLevel
        if (query.aspect_source) {
          let aspect_source_arr = query.aspect_source.split(',')
          aspect_source_arr.splice(index, 1)
          query.aspect_source = aspect_source_arr.join(',')
        }
        this.$store.dispatch('user/changeActivedTab', 'Summary')
        this.$router.push({
          name: this.$route.name,
          query,
        })
      }
    },
    handleAttrListClose(item) {
      let index = item.index
      let query = _.cloneDeep(this.$route.query)
      if (this.attrFilters.length == 1) {
        this.$store.dispatch('user/changeAttrId', '')
        this.$store.dispatch('user/changeAttrName', '')
        delete query.attr_id
        delete query.attr_name
        this.$store.dispatch('user/changeActivedTab', 'Summary')
        this.$router.push({
          name: this.$route.name,
          query,
        })
      } else if (this.attrFilters.length > 1) {
        let attrIdArr = this.$store.state.user.attrId.split(',')
        let attrNameArr = this.$store.state.user.attrName.split(',')
        attrIdArr.splice(index, 1)
        attrNameArr.splice(index, 1)
        this.$store.dispatch('user/changeAttrId', attrIdArr.join(','))
        this.$store.dispatch('user/changeAttrName', attrNameArr.join(','))
        query.attr_id = this.$store.state.user.attrId
        query.attr_name = this.$store.state.user.attrName
        this.$store.dispatch('user/changeActivedTab', 'Summary')
        this.$router.push({
          name: this.$route.name,
          query,
        })
      }
    },
    handleTitleClose(item) {
      let index = item.index
      let query = _.cloneDeep(this.$route.query)
      if (this.titleFilters.length == 1) {
        this.$store.dispatch('user/changeTitleWord', '')
        this.$store.dispatch('user/changeTitleTyp', '')
        delete query.title_word
        delete query.title_typ
        this.$eventBus.$emit('deleteTitleWord', query.title_word)
        this.$store.dispatch('user/changeActivedTab', 'Summary')
        this.$router.push({
          name: this.$route.name,
          query,
        })
      } else if (this.titleFilters.length > 1) {
        let titleWordArr = this.titleFilters.map((item) => item.word)
        let titleTypArr = this.titleFilters.map((item) => item.title_typ)
        titleWordArr.splice(index, 1)
        titleTypArr.splice(index, 1)
        this.$store.dispatch('user/changeTitleWord', titleWordArr.join(','))
        this.$store.dispatch('user/changeTitleTyp', titleTypArr.join(','))
        query.title_word = this.$store.state.user.titleWord
        query.title_typ = this.$store.state.user.titleTyp
        this.$eventBus.$emit('deleteTitleWord', query.title_word)
        this.$store.dispatch('user/changeActivedTab', 'Summary')
        this.$router.push({
          name: this.$route.name,
          query,
        })
      }
    },
    getMarkedMethods() {
      const { cid, plat } = this.$route.query
      const {
        brandId,
        contentWord,
        itemAnalyse,
        fxUrlId,
        itemId,
        parentCatId,
        priceMax,
        priceMin,
        shopId,
        skuId,
        titleWord,
        titleTyp,
      } = this.$store.state.user
      let typ = titleTyp
      if (contentWord || titleWord || itemId) {
        if (contentWord) {
          typ = 'content'
        }
        if (titleWord || (itemId && !contentWord)) {
          typ = 'title'
        }
      } else {
        if (shopId) {
          typ = 'shop'
        } else if (brandId && brandId !== 'all') {
          typ = 'brand'
        } else {
          typ = 'cat'
        }
      }
      const params = {
        brand_id: brandId,
        cat_id: cid,
        content_word: contentWord,
        fx_url_id: fxUrlId,
        item_analyse: itemAnalyse,
        item_id: itemId,
        parent_cat_id: parentCatId,
        plat: plat,
        price_max: priceMax,
        price_min: priceMin,
        shop_id: shopId,
        sku_id: skuId,
        title_word: titleWord,
        typ: typ,
        url_id: cid,
      }
      getAttention(params).then((res) => {
        if (res.status == 'ok') {
          const result = res.result
          if (result.count && result.count > 0) {
            this.isMarked = true
          } else {
            this.isMarked = false
          }
        } else {
          this.isMarked = false
        }
      })
    },
    followClick() {
      const { cid, plat } = this.$route.query
      const {
        brandId,
        contentWord,
        itemAnalyse,
        fxUrlId,
        itemId,
        parentCatId,
        priceMax,
        priceMin,
        shopId,
        skuId,
        titleWord,
        titleTyp,
      } = this.$store.state.user
      let typ = titleTyp
      if (contentWord || titleWord || itemId) {
        if (contentWord) {
          typ = 'content'
        }
        if (titleWord || (itemId && !contentWord)) {
          typ = 'title'
        }
      } else {
        if (shopId) {
          typ = 'shop'
        } else if (brandId && brandId !== 'all') {
          typ = 'brand'
        } else {
          typ = 'cat'
        }
      }
      const params = {
        brand_id: brandId,
        cat_id: cid,
        content_word: contentWord,
        fx_url_id: fxUrlId,
        item_analyse: itemAnalyse,
        item_id: itemId,
        parent_cat_id: parentCatId,
        plat: plat,
        price_max: priceMax,
        price_min: priceMin,
        shop_id: shopId,
        sku_id: skuId,
        title_word: titleWord,
        typ: typ,
        url_id: cid,
      }

      if (
        this.$store.state.user.words &&
        this.$store.state.user.words.split(',').length > 0
      ) {
        var customFilters = []
        for (
          let i = 0;
          i < this.$store.state.user.words.split(',').length;
          i++
        ) {
          let aspect_type = this.$store.state.user.aspectType.split(',')[i]
          if (
            this.$store.state.user.aspectType.split(',')[i] ==
              'neg_percentage' ||
            this.$store.state.user.aspectType.split(',')[i] == 'total_neg' ||
            this.$store.state.user.aspectType.split(',')[i] == 'new_neg_per'
          ) {
            aspect_type = 'neg'
          }
          var _customFilter = {
            rule_id: this.$store.state.user.routerRuleId.split(',')[i],
            analyse_typ: this.$store.state.user.routerAnalyseTyp.split(',')[i],
            ner_type: this.$store.state.user.routerNerType.split(',')[i],
            label_level: this.$store.state.user.labelLevel.split(',')[i],
            aspect_type: aspect_type,
          }
          var words = this.$store.state.user.words.split(',')[i]
          if (words.split('_').length > 1) {
            _customFilter['words'] = words.split('_')[0]
            _customFilter['opinions'] = words.split('_')[1]
          } else {
            _customFilter['words'] = this.$store.state.user.words.split(',')[i]
          }
          customFilters.push(_customFilter)
        }

        params.custom_filters = JSON.stringify(customFilters)
      }
      if (this.$store.state.user.attrFilters) {
        params.attr_filters = JSON.stringify(this.$store.state.user.attrFilters)
      }
      if (titleWord && titleWord.split(',').length > 0) {
        var title_word = []
        for (let i = 0; i < titleWord.split(',').length; i++) {
          title_word.push({
            word: titleWord.split(',')[i],
            title_typ: titleTyp.split(',')[i],
          })
        }
        params.title_word = JSON.stringify(title_word)
      } else {
        params.title_word = titleWord
      }
      let api = putAttention
      if (this.isMarked) {
        api = deleteAttention
      }
      api(params).then((res) => {
        if (res.status == 'ok') {
          this.isMarked = !this.isMarked
        } else {
        }
      })
    },
    confirm() {
      this.dateTime = this.$refs.PickerCpn.dateTime
      const query = _.cloneDeep(this.$route.query)
      query.start = this.dateTime[0]
      query.end = this.dateTime[1]
      this.$router.push({
        name: 'catSheet',
        query,
      })
    },
    fixPlatOrder(arr) {
      const order = ['jd', 'tmall', 'douyin', 'custom', 'amazon_us']
      arr.sort((a, b) => {
        const indexA = order.indexOf(a.value)
        const indexB = order.indexOf(b.value)
        return indexA - indexB
      })
      return arr
    },
    changeCatById(val) {
      const catInfo = this.$refs.CatTree.getCatInfoById(val)
      this.defaultCheckedKeys = [catInfo.id]
      this.$store.dispatch('user/changeCatId', catInfo.id)
      this.$store.dispatch('user/changeParentCatId', catInfo.parent_id)
      this.$store.dispatch('user/changeActivedTab', 'Summary')
    },
    getFxUrlDataPromise() {
      let param = {
        fx_url_id: this.fxUrlId,
      }
      getFxUrlData(param).then((res) => {
        const { data } = res.result.data.data
        let index = this.platOptions.findIndex(
          (item) => item.value == this.plat
        )
        if (index > -1) {
          let plat = this.platOptions[index].label
          this.fxUrlIdParams.plat = plat
        }
        this.fxUrlIdParams.cat = data.cats.map((item) => item.name)[0]
        this.fxUrlIdParams.keyword = data.title
        this.fxUrlIdParams.brand = data.brand
        this.fxUrlIdParams.shop = data.shop_name
        this.fxUrlIdParams.avgPrice = this.compareCalFunc(data.avg_price)
          ? this.compareCalFunc(data.avg_price) + '元'
          : ''
        this.fxUrlIdParams.sold = this.compareCalFunc(data.sold)
          ? this.compareCalFunc(data.sold) + '件'
          : ''
        this.fxUrlIdParams.sales = this.compareCalFunc(data.sales)
          ? this.compareCalFunc(data.sales) + '万元'
          : ''
        this.fxUrlIdParams.onlineTime = this.compareCalFunc(
          data.online_time,
          'time'
        )
        this.fxUrlIdParams.filingDate = this.compareCalFunc(
          data.filing_date,
          'time'
        )
        this.attrList = data.attr_filters
      })
    },
    compareCalFunc(val, param) {
      if (val[0] == '' && val[1] == '') {
        return false
      } else if (val[0] !== '' && val[1] !== '') {
        return val[0] + '~' + val[1]
      } else if (val[0] == '' && val[1] !== '') {
        return param == 'time' ? val[1] + '之前' : '<=' + val[1]
      } else if (val[0] !== '' && val[1] == '') {
        return param == 'time' ? val[0] + '之后' : '>=' + val[0]
      }
    },
    initData() {
      const { plat, cid } = this.$route.query
      getPlatCats().then((res) => {
        if (res.status == 'ok') {
          const result = res.result
          const resultOptions = []
          let platList = PLAT_LIST
          if (!this.loginInfo.is_add_custom) {
            //非自定义权限账号不显示自定义平台
            platList = _.filter(platList, function (o) {
              return o.value != 'custom'
            })
          }
          Object.keys(result).forEach((item) => {
            const option = platList.find((ele) => ele.value == item)
            if (option) {
              resultOptions.push(option)
            }
          })
          this.platOptions = this.fixPlatOrder(resultOptions)
          this.$store.dispatch('user/changePlatCats', result)
          if (plat && cid) {
            this.plat = plat
            const numberRegex = /^\d+$/
            const singleCid = numberRegex.test(cid)
            this.customCats = result.custom.pids
            let customIndex = this.customCats.findIndex((item) => {
              return _.includes(cid, item)
            })
            if (singleCid || customIndex > -1) {
              this.defaultCheckedKeys = [cid]
              this.showCatTree = true
              this.$store.dispatch('user/changeMultipleCatIdArr', [])
              const datas = this.cacheData
              if (datas && datas.result.childrenList) {
                const parent_id = datas.result.childrenList[cid].parent_id
                if (parent_id) {
                  this.$store.dispatch('user/changeParentCatId', parent_id)
                }
              }
            } else {
              this.getMultipleCid(cid)
            }
          } else {
            this.plat = this.platOptions[0].value
            const cat_list = this.$store.state.user.loginInfo.cat_list
            let r = this.rand_cat(cat_list)
            this.defaultCheckedKeys = [r[1]]
            this.$store.dispatch('user/changePlat', r[0])
            this.$store.dispatch('user/changeCatId', r[1])
            this.$store.dispatch('user/changeStart', r[2])
            this.$store.dispatch('user/changeEnd', r[2])
            this.showCatTree = true
            this.$store.dispatch('user/changeMultipleCatIdArr', [])
          }
          // this.changePlat(this.plat)
        }
      })
    },
    rand_cat(catlist) {
      let cid = '',
        plat = this.plat,
        ts = '',
        _this = this
      if (catlist == 'all') {
        getDefaultId()
      } else {
        _.forEach(
          ['jd', 'tmall', 'douyin', 'custom', 'amazon_us'],
          function (p) {
            if (plat && plat !== p) {
              return
            }
            _.each(catlist[p], function (v, k) {
              if (v.length > 0 && !cid) {
                cid = v[0]['cat_id']
                ts = v[0].ts
                plat = p
                return false
              }
            })
          }
        )
        if (!cid || !plat) {
          getDefaultId()
        }
      }
      function getDefaultId() {
        plat = plat || 'jd'
        cid = cid || _this.DEFAULT_CID[plat]
        const latestTime = _this.$store.state.user.latestTime
        if (latestTime) {
          const timeArr = latestTime[plat]
          if (timeArr[2] && Object.keys(timeArr[2]).length > 0) {
            if (timeArr[2][cid]) {
              ts = timeArr[2][cid][1]
            } else {
              ts = timeArr[1]
            }
          } else {
            ts = timeArr[1]
          }
        }
      }
      return [plat, cid, ts]
    },
    getMultipleCid(cid) {
      getUrlId({ url_id: cid }).then((res) => {
        if (res.status == 'ok') {
          const { cats } = res.result.data
          this.$store.dispatch('user/changeMultipleCatIdArr', cats)
          this.childrenCats = []
          cats.forEach((item) => {
            this.childrenCats.push(item)
          })
          this.defaultCheckedKeys = this.childrenCats
          this.showCatTree = true
        }
      })
    },
    resetJump(param) {
      let query = _.cloneDeep(this.$route.query)
      console.log('准备跳转； ------param:', param, 'query:', query)
      let newQuery = param || query
      this.$router.push({
        name: 'catSheet',
        query: newQuery,
      })
    },
    setStartAndEnd(catArr) {
      const cat_list = this.$store.state.user.loginInfo.cat_list
      const latestTime = this.$store.state.user.latestTime
      const timeArr = latestTime[this.plat]
      let query = _.cloneDeep(this.$route.query)
      let tsType = this.$store.state.user.tsType
      const isDay = catArr.some(
        (item) =>
          item &&
          (item.includes('model_bestore_daily_tmall') ||
            item.includes('model_bestore_daily_jd'))
      )
      const ismouth = catArr.some(
        (item) =>
          item &&
          (item.includes('model_bestore_month_tmall') ||
            item.includes('model_bestore_month_jd'))
      )
      if (isDay) {
        tsType = 'day'
      }
      if (ismouth) {
        tsType = 'month'
      }
      let ts = '',
        DataTime = { start: '', end: '' }
      let start = timeArr[1],
        end = timeArr[1]
      let catPathArr = []
      for (let i = 0; i < catArr.length; i++) {
        if (catArr[i]) {
          catPathArr.push(this.$refs.CatTree.getCatInfoById(catArr[i]).cid_path)
        }
      }
      let allCatPathArr = _.uniq(_.flatten(catPathArr)) //所选类目(包含父类目)的cid数组集合
      if (timeArr[2] && Object.keys(timeArr[2]).length > 0) {
        let arr = []
        for (let i = 0; i < allCatPathArr.length; i++) {
          let item = allCatPathArr[i]
          if (timeArr[2][item]) {
            arr.push([timeArr[2][item][0], timeArr[2][item][1]])
          }
        }
        if (arr.length > 0) {
          let arr1 = _.map(arr, (item) => item[0])
          let arr2 = _.map(arr, (item) => item[1])
          // if(isDay){
          //     arr1 = _.map(arr, (item) =>
          //         formatDate(
          //             new Date(item[0].split('-')[0], item[0].split('-')[1], 0),
          //             '{y}-{m}-{d}'
          //         )
          //     )
          //     arr2 = _.map(arr, (item) =>
          //         formatDate(
          //             new Date(item[1].split('-')[0], item[1].split('-')[1], 0),
          //             '{y}-{m}-{d}'
          //         )
          //     )
          // }
          let f1 = arr1.map((dateStr) => new Date(dateStr).getTime())
          const startTime = new Date(Math.max(...f1))
          let f2 = arr2.map((dateStr) => new Date(dateStr).getTime())
          const endTime = new Date(Math.min(...f2))
          if (startTime > endTime) {
            this.$message.error('所选类目没有可查看时间的数据')
            return
          }
          if (isDay) {
            DataTime.start = startTime.toISOString().slice(0, 10)
            DataTime.end = endTime.toISOString().slice(0, 10)
          } else {
            DataTime.start = startTime.toISOString().slice(0, 7)
            DataTime.end = endTime.toISOString().slice(0, 7)
          }
        } else {
          DataTime.start = timeArr[0]
          DataTime.end = timeArr[1]
        }
      } else {
        DataTime.start = timeArr[0]
        DataTime.end = timeArr[1]
      }
      if (cat_list == 'all') {
        start = DataTime.end
        end = DataTime.end
      } else {
        const platCatList = cat_list[this.plat]
        const datas = _.flatMap(platCatList)
        const result = _.filter(datas, (obj) =>
          allCatPathArr.includes(obj.cat_id)
        )
        if (result.length > 0) {
          let tsArr = _.map(result, 'ts')
          let f2 = tsArr.map((dateStr) => new Date(dateStr).getTime())
          const minTs = new Date(Math.min(...f2))
          if (isDay) {
            ts = minTs.toISOString().slice(0, 10)
          } else {
            ts = minTs.toISOString().slice(0, 7)
          }
          start = ts
          end = ts
          if (DataTime.end < ts) {
            start = DataTime.end
            end = DataTime.end
          }
        }
      }
      this.$store.dispatch('user/changePlatTime', {
        end: DataTime.end,
        start: DataTime.start,
      })
      if (!this.isChangePlat) {
        if (tsType == 'month') {
          start = start.slice(0, 7)
          end = end.slice(0, 7)
        } else if (tsType == 'week') {
          const time = getLastFullWeekStartAndEnd(
            end.slice(5, 7),
            end.slice(0, 4)
          )
          start = time.start
          end = time.end
        } else if (tsType == 'day') {
          if (end.split('-').length != 3) {
            start =
              start.slice(0, 7) +
              '-' +
              new Date(start.slice(0, 4), start.slice(5, 7), 0).getDate()
            end =
              end.slice(0, 7) +
              '-' +
              new Date(end.slice(0, 4), end.slice(5, 7), 0).getDate()
          }
        }
      }
      console.log('start', start)
      console.log('end', end)
      console.log(this.isInit, 'isInit')
      if (query.start && !this.isChangePlat && this.isInit) {
        start = query.start
      }
      if (query.end && !this.isChangePlat && this.isInit) {
        end = query.end
      }
      console.log('DataTime', DataTime)
      this.$store.dispatch('user/changeStart', start)
      this.$store.dispatch('user/changeEnd', end)
      this.isChangePlat = false
      this.isInit = false
      //   this.$router.push({
      //     name: this.$route.name,
      //     query,
      //   })
    },
  },
  mixins: [paramsObj],
  created() {
    this.getMarked = _.debounce(this.getMarkedMethods, 300)
    this.resetJumpDebounce = _.debounce(this.resetJump, 300)
    this.initData()
    if (this.fxUrlId) {
      this.getFxUrlDataPromise()
    }
    const { priceMin, priceMax } = this.$store.state.user
    if (priceMin && priceMax) {
      this.priceInteval = priceMin + '~' + priceMax + '元'
    } else if (priceMin && !priceMax) {
      this.priceInteval = '≥' + priceMin + '元'
    } else if (!priceMin && priceMax) {
      this.priceInteval = '≤' + priceMax + '元'
    }
  },
  mounted() {
    this.$eventBus.$on('changeCatById', this.changeCatById)
    setTimeout(() => {
      console.log(111111)
      this.getMarked()
    }, 1000)
  },
  beforeDestroy() {
    this.$eventBus.$off('changeCatById')
  },
  computed: {
    latestTime() {
      return this.$store.state.user.latestTime
    },
    cacheData() {
      let datas
      switch (this.plat) {
        case 'jd':
          datas = window.cache_cat_jd
          break
        case 'tmall':
          datas = window.cache_cat
          break
        case 'custom':
          datas = window.cache_cat_custom
          break
        case 'douyin':
          datas = window.cache_douyin_cats
          break
        case 'amazon_us':
          datas = window.cache_cat_amazon_us
          break
        default:
          datas = window.cache_cat_jd
      }
      return datas
    },
    loginInfo() {
      return this.$store.state.user.loginInfo
    },
    customFilters() {
      return this.$store.state.user.customFilters
    },
    titleWord() {
      return this.$store.state.user.titleWord
    },
    contentWord() {
      return this.$store.state.user.contentWord
    },
    priceMin() {
      return this.$store.state.user.priceMin
    },
    priceMax() {
      return this.$store.state.user.priceMax
    },
    fxUrlId() {
      return this.$store.state.user.fxUrlId
    },
    attrFilters() {
      return this.$store.state.user.attrFilters
    },
    titleFilters() {
      return this.$store.state.user.titleFilters
    },
    scenelist() {
      let aspect_source = this.$route.query.aspect_source
        ? this.$route.query.aspect_source.split(',')
        : []
      if (this.customFilters && this.customFilters.length > 0) {
        let tagString = []
        // 循环遍历customFilters的值，如果label不存在，就把words按照下划线分隔，再赋值给字符串数组。
        // 方面子图表和观点的逻辑一致
        for (let i = 0; i < this.customFilters.length; i++) {
          const item = this.customFilters[i]
          const label = item.label_level
          const rule_id = decodeURIComponent(item.rule_id)
          const analyse_typ = decodeURIComponent(item.analyse_typ)
          const ner_type = item.ner_type
          const aspLevel = item.asp_level
          const navTabs = _.cloneDeep(this.navTabs)
          const ruleIndex = navTabs.findIndex(
            (element) => element.rule_id == item.rule_id
          )
          let ruleName = navTabs[ruleIndex] ? navTabs[ruleIndex].title : '方面'
          let words = item.words
          let opinions = item.opinions
          if (aspect_source.length > 0) {
            if (aspect_source[i]) {
              if (aspect_source[i].split('_').length > 1) {
                words = aspect_source[i].split('_')[0]
                opinions = aspect_source[i].split('_')[1]
              } else {
                words = aspect_source[i]
              }
            }
          }
          if (!analyse_typ) {
            if (label == 2 && !ner_type) {
              if (aspLevel == 'level1') {
                tagString.push({
                  label: '一级指标',
                  name: decodeURIComponent(words),
                  type: decodeURIComponent(item.aspect_type),
                  label_level: label,
                  index: i,
                  analyse_typ: analyse_typ,
                })
              } else if (aspLevel == 'level2') {
                tagString.push({
                  label: '二级指标',
                  name: decodeURIComponent(words),
                  type: decodeURIComponent(item.aspect_type),
                  label_level: label,
                  index: i,
                  analyse_typ: analyse_typ,
                })
              } else {
                tagString.push({
                  label: '方面',
                  name: decodeURIComponent(words),
                  type: decodeURIComponent(item.aspect_type),
                  label_level: label,
                  index: i,
                  analyse_typ: analyse_typ,
                })
              }
            } else if (label == 1 && !ner_type && !rule_id) {
              tagString.push({
                label: '方面',
                name: decodeURIComponent(words),
                view: '观点',
                viewName: decodeURIComponent(opinions),
                type: decodeURIComponent(item.aspect_type),
                label_level: label,
                index: i,
                rule_id: rule_id,
                analyse_typ: analyse_typ,
              })
            } else if (label == 1 && !ner_type && rule_id) {
              tagString.push({
                label: ruleName,
                name: decodeURIComponent(words),
                type: decodeURIComponent(item.aspect_type),
                ner_type: decodeURIComponent(ner_type),
                label_level: label,
                index: i,
                rule_id: rule_id,
                analyse_typ: analyse_typ,
              })
            } else if (ner_type) {
              tagString.push({
                label: ruleName,
                name: decodeURIComponent(words),
                type: decodeURIComponent(item.aspect_type),
                ner_type: decodeURIComponent(ner_type),
                label_level: label,
                index: i,
              })
            } else {
              tagString.push({
                label: ruleName,
                name: decodeURIComponent(words),
                type: decodeURIComponent(item.aspect_type),
                label_level: label,
                index: i,
              })
            }
          } else if (
            analyse_typ == 'people_word' ||
            analyse_typ == 'scene_word'
          ) {
            const analyseName =
              analyse_typ == 'people_word' ? '人群分析' : '场景分析'
            tagString.push({
              label: analyseName,
              name: decodeURIComponent(words),
              type: decodeURIComponent(item.aspect_type),
              label_level: label,
              index: i,
              analyse_typ: analyse_typ,
            })
          }
        }
        return tagString
      } else {
        return []
      }
    },
    attrlist() {
      if (this.attrFilters && this.attrFilters.length > 0) {
        let attrString = []
        for (let i = 0; i < this.attrFilters.length; i++) {
          const item = this.attrFilters[i]
          const attrId = item.value
          const attrName = item.attr
          attrString.push({
            index: i,
            attrName: attrName,
            attrId: attrId,
          })
        }
        return attrString
      } else {
        return []
      }
    },
    titlelist() {
      if (this.titleFilters && this.titleFilters.length > 0) {
        let titleString = []
        for (let i = 0; i < this.titleFilters.length; i++) {
          const item = this.titleFilters[i]
          const word = item.word
          const titleTyp = item.title_typ
          titleString.push({
            index: i,
            word,
            titleTyp,
          })
        }
        return titleString
      } else {
        return []
      }
    },
    is_add_custom() {
      return this.$store.state.user.loginInfo.is_add_custom
    },
  },
  watch: {
    paramsObj: {
      handler(newVal, oldVal) {
        console.log('触发了paramsObj的监听', newVal)
        const {
          plat,
          catId,
          shop,
          brand,
          item,
          titleWord,
          titleTyp,
          contentWord,
          priceMax,
          priceMin,
          fxUrlId,
          sku,
        } = newVal
        let query = _.cloneDeep(this.$route.query)
        if (plat) {
          query.plat = plat
          this.plat = plat
        }
        if (catId) {
          query.cid = catId
          //   if (this.childrenCats && this.childrenCats.length > 0) {
          //     this.defaultCheckedKeys = this.childrenCats
          //   } else {
          //     this.defaultCheckedKeys = [catId]
          //   }
        }
        if (shop) {
          query.shop = shop
        } else {
          delete query.shop
        }
        if (brand) {
          query.brand = brand
        } else {
          delete query.brand
        }
        if (item) {
          query.item_id = item
        } else {
          delete query.item_id
        }
        if (sku) {
          query.sku = sku
        } else {
          delete query.sku
        }
        // 商品搜索方案
        // if (titleWord && titleTyp) {
        //   query.title_word = titleWord
        //   query.title_typ = titleTyp
        // } else {
        //   delete query.title_word
        //   delete query.title_typ
        // }
        if (titleWord == '' && titleTyp == '') {
          delete query.title_word
          delete query.title_typ
        }
        // 评论搜索关键词
        if (contentWord) {
          query.content_word = contentWord
        } else {
          delete query.content_word
        }
        // 价格搜索的最小值和最大值
        if (priceMin && priceMax) {
          this.priceInteval = priceMin + '~' + priceMax + '元'
          query.price_min = priceMin
          query.price_max = priceMax
        } else if (priceMin && !priceMax) {
          this.priceInteval = '≥' + priceMin + '元'
          query.price_min = priceMin
        } else if (!priceMin && priceMax) {
          this.priceInteval = '≤' + priceMax + '元'
          query.price_max = priceMax
        } else {
          delete query.price_min
          delete query.price_max
        }
        if (fxUrlId) {
          query.fx_url_id = fxUrlId
          this.getFxUrlDataPromise()
        } else {
          delete query.fx_url_id
        }
        if (this.$store.state.user.start != query.start) {
          query.start = this.$store.state.user.start
        }
        if (this.$store.state.user.end != query.end) {
          query.end = this.$store.state.user.end
        }
        if (query.start) {
          this.resetJumpDebounce(query)
        }
      },
    },
    selectIds(newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.setStartAndEnd(_.map(newVal, 'cat_id'))
      }
      if (_.isEqual(newVal, oldVal) || oldVal.length == 0) {
        return
      }
      if (newVal.length == 1) {
        this.defaultCheckedKeys = newVal
        this.childrenCats = []
        if (this.fxUrlId == '') {
          this.$store.dispatch('user/changeCatId', newVal[0].cat_id)
        } else {
          this.$store.dispatch('user/changeCatId', this.$route.query.cid)
        }
        this.$store.dispatch('user/changeParentCatId', newVal[0].parent_id)
      } else if (newVal.length > 1) {
        this.defaultCheckedKeys = newVal
        this.$store.dispatch('user/changeMultipleCatIdArr', newVal)
        postUrlId({
          plat: this.plat,
          cats: newVal,
        }).then((res) => {
          if (res.status == 'ok') {
            const result = res.result
            const url_id = result.data.url_id
            if (this.fxUrlId == '') {
              this.$store.dispatch('user/changeCatId', url_id)
            } else {
              this.$store.dispatch('user/changeCatId', this.$route.query.cid)
            }
            this.$store.dispatch('user/changeParentCatId', '')
          }
        })
      } else {
        return
      }
    },
    '$route.query': {
      handler(newVal, oldVal) {
        this.getMarked()
        let query = _.cloneDeep(newVal)
        const numberRegex = /^\d+$/
        const singleCid = numberRegex.test(query.cid)
        if (this.customCats.length > 0) {
          let customIndex = this.customCats.findIndex((item) => {
            return _.includes(query.cid, item)
          })
          if (!singleCid && customIndex == -1 && newVal.cid != oldVal.cid) {
            getUrlId({ url_id: query.cid }).then((res) => {
              if (res.status == 'ok') {
                const { cats } = res.result.data
                this.childrenCats = []
                cats.forEach((item) => {
                  this.childrenCats.push(item)
                })
                this.defaultCheckedKeys = this.childrenCats
                // this.$store.dispatch('user/changeCatId', query.cid)
                this.$store.dispatch('user/changeActivedTab', 'Summary')
              }
            })
          } else {
            if (newVal.cid != oldVal.cid) {
              this.defaultCheckedKeys = [query.cid]
              this.$store.dispatch('user/changeActivedTab', 'Summary')
              //   this.$store.dispatch(
              //     'user/changeCatId',
              //     this.defaultCheckedKeys[0]
              //   )
            }
          }
        }
        // if (newVal.plat == 'custom' && newVal.cid != oldVal.cid) {
        //   this.$store.dispatch('user/changeAttrId', '')
        //   this.$store.dispatch('user/changeAttrName', '')
        //   query.attr_id = this.$store.state.user.attrId
        //   query.attr_name = this.$store.state.user.attrName
        //   this.$store.dispatch('user/changeActivedTab', 'Summary')
        //   this.$router.push({
        //     name: this.$route.name,
        //     query,
        //   })
        // }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.fenxi-tooltip {
  .fenxi-tool-content {
    font-size: 14px;
    .fenxi-tool-plat {
      color: #ffffff;
      border: 1px solid;
      background-color: #3a63f3;
      padding: 4px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
    .fenxi-tool-cat {
      color: #ffffff;
      border: 1px solid;
      background-color: #ff6a5b;
      padding: 4px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
    .fenxi-tool-keyword {
      color: #ffffff;
      border: 1px solid;
      background-color: #5bc0de;
      padding: 4px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
    .fenxi-tool-avgPrice {
      color: #ffffff;
      border: 1px solid;
      background-color: #427ff0;
      padding: 4px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
    .fenxi-tool-sold {
      color: #ffffff;
      border: 1px solid;
      background-color: #9338e9;
      padding: 4px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
    .fenxi-tool-sales {
      color: #ffffff;
      border: 1px solid;
      background-color: #74d150;
      padding: 4px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
    .fenxi-tool-brand {
      color: #ffffff;
      border: 1px solid;
      background-color: #29b36e;
      padding: 4px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
    .fenxi-tool-shop {
      color: #ffffff;
      border: 1px solid;
      background-color: #5bc0de;
      padding: 4px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
    .fenxi-tool-attr {
      color: #ffffff;
      border: 1px solid;
      background-color: #7baaf1;
      padding: 4px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
    .fenxi-tool-onlineTime {
      color: #ffffff;
      border: 1px solid;
      background-color: #f19f7b;
      padding: 4px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
    .fenxi-tool-filingDate {
      color: #ffffff;
      border: 1px solid;
      background-color: #f19f7b;
      padding: 4px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
  }
}
.filter-header-cpn {
  width: 100%;
  min-height: 50px;
  margin-bottom: 5px;
  margin-top: 10px;
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .left-area {
    // float: left;
    display: flex;
    align-items: center;
    overflow: auto hidden;
    flex-wrap: wrap;
    .plat-select-area {
      min-width: 140px;
      height: 40px;
      line-height: 40px;
      margin-right: 10px;
    }

    .cat-select-area {
      height: 40px;
      line-height: 40px;
      display: flex;
    }
    .aspect-tag {
      display: flex;
      align-items: center;
      height: 40px;
      .placehold {
        margin: 0 5px;
      }
      .aspectName {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        background-color: rgba(72, 219, 192, 0.3);
        border: 1px solid #ffe5cc;
      }
    }

    .tag {
      display: flex;
      align-items: center;
      height: 40px;
      .placehold {
        margin: 0 5px;
      }

      .tagName {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        background-color: rgba(72, 219, 192, 0.3);
        border: 1px solid #ffe5cc;
      }
    }

    .subscribe-area {
      min-width: 90px;
      height: 40px;
      line-height: 40px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .right-area {
    // float: right;
    .question-area {
      float: left;
      height: 40px;
      line-height: 40px;
    }
    .time-picker-area {
      display: flex;
      height: 40px;
      line-height: 40px;
      float: left;
    }
  }
}
</style>
<style lang="less">
.left-area {
  .plat-select-area {
    .el-select > .el-input {
      width: 100px;
    }
  }
}
</style>
