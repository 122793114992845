/**
 * Created by PanJiaChen on 16/11/18.
 */
import store from '@/store'
import { DateTime } from 'luxon'
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function ToGB(str) {
	//把UniCode转化成中文
	return unescape(str.replace(/\\u/gi, '%u'))
}
//根据开始月和结束月返回中间的月
export function build_ts(max, min) {
	var monthes = []
	var min = min || '2017-01'
	var timeArr = max.split('-')
	var lastMonth = new Date(timeArr[0], timeArr[1] - 1, 1)
	timeArr = min.split('-')
	var monthIter = new Date(timeArr[0], timeArr[1] - 1, 1)
	while (monthIter.getTime() <= lastMonth.getTime()) {
		var m = monthIter.getMonth() + 1
		monthes.unshift(monthIter.getFullYear() + '-' + (m > 9 ? m : '0' + m))
		monthIter.setMonth(monthIter.getMonth() + 1)
	}
	return monthes
}
//根据开始月和结束月返回中间的日
export function build_day_ts(max, min) {
	const startDate = new Date(min)
	const endDate = new Date(max)
	const dateArray = []
	let currentDate = new Date(startDate)

	const endYear = endDate.getFullYear()
	const endMonth = endDate.getMonth()
	while (currentDate.getFullYear() <= endYear) {
		if (
			currentDate.getFullYear() == endYear &&
			currentDate.getMonth() > endMonth
		) {
			break
		}
		const year = currentDate.getFullYear()
		const month = String(currentDate.getMonth() + 1).padStart(2, '0')
		const day = String(currentDate.getDate()).padStart(2, '0')
		const dateString = `${year}-${month}-${day}`
		dateArray.unshift(dateString)
		currentDate.setDate(currentDate.getDate() + 1)
	}
	return dateArray
}
//根据开始日和结束日返回中间所有的日
export function build_day(max, min) {
	const dates = []
	const start = new Date(max)
	const end = new Date(min)

	for (let date = start; date >= end; date.setDate(date.getDate() - 1)) {
		dates.push(new Date(date).toISOString().slice(0, 10))
	}

	return dates
}
export function getLastFullWeekStartAndEnd(month, year) {
	//根据给定的月份和年份获取所需的最后一个完整周的开始和结束时间
	let lastDayOfMonth = new Date(year, month, 0).getDate()
	let lastDayOfWeek = new Date(year, month - 1, lastDayOfMonth).getDay() // 获取当月最后一天是星期几

	let endDay = lastDayOfMonth - lastDayOfWeek // 获取完整周的结束日期

	let endWeekStart = new Date(year, month - 1, endDay - 6) // 完整周开始时间
	let endWeekEnd = new Date(year, month - 1, endDay) // 完整周结束时间

	return {
		start: formatDate(endWeekStart),
		end: formatDate(endWeekEnd),
	}
}
//获得12个月以前的年月
export function getLastYearYestdy(value) {
	let date = new Date(value)
	var strYear = date.getFullYear() - 1
	var strMonth = date.getMonth() + 2
	if (strMonth < 10) {
		strMonth = '0' + strMonth
	}
	if (strMonth == 13) {
		strYear = date.getFullYear()
		strMonth = '01'
	}
	let datastr = strYear + '-' + strMonth
	return datastr
}
export function ChangeDecimalToPercentage(data) {
	let data1 = (data * 100).toFixed(2) + '%'
	return data1
}
export function threeDigitsPercentage(data) {
	//数字转百分比保留小数点后3位不会4舍5入
	let data1 = Math.floor(data * 10000) / 100 + '%'
	return data1
}
export function formatInt(num, prec = 2, ceil = true) {
	const len = String(num).length
	if (len <= prec) {
		return num
	}

	const mult = Math.pow(10, prec)
	return ceil ? Math.ceil(num / mult) * mult : Math.floor(num / mult) * mult
}
export function display_growth(growth, last) {
	if (last == 0) {
		return '&#8734;'
	} else {
		return Math.round(growth * 100).toFixed(2) + '%'
	}
}
export function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null
	} else if (!time) {
		return null
	}
	const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
			time = parseInt(time)
		}
		if (typeof time === 'number' && time.toString().length === 10) {
			time = time * 1000
		}
		if (typeof time === 'number') {
			// 毫秒格式的处理
			date = DateTime.fromMillis(time).setZone('Asia/Shanghai')
		} else {
			// ISO格式的处理
			date = DateTime.fromISO(time).setZone('Asia/Shanghai')
		}
	}
	const formatObj = {
		y: date.year,
		m: date.month,
		d: date.day,
		h: date.hour,
		i: date.minute,
		s: date.second,
		a: date.day,
	}
	const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
		const value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value]
		}
		return value.toString().padStart(2, '0')
	})
	return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
	if (('' + time).length === 10) {
		time = parseInt(time) * 1000
	} else {
		time = +time
	}
	const d = new Date(time)
	const now = Date.now()

	const diff = (now - d) / 1000

	if (diff < 30) {
		return '刚刚'
	} else if (diff < 3600) {
		// less 1 hour
		return Math.ceil(diff / 60) + '分钟前'
	} else if (diff < 3600 * 24) {
		return Math.ceil(diff / 3600) + '小时前'
	} else if (diff < 3600 * 24 * 2) {
		return '1天前'
	}
	if (option) {
		return parseTime(time, option)
	} else {
		return (
			d.getMonth() +
			1 +
			'月' +
			d.getDate() +
			'日' +
			d.getHours() +
			'时' +
			d.getMinutes() +
			'分'
		)
	}
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
	url = url == null ? window.location.href : url
	const search = url.substring(url.lastIndexOf('?') + 1)
	const obj = {}
	const reg = /([^?&=]+)=([^?&=]*)/g
	search.replace(reg, (rs, $1, $2) => {
		const name = decodeURIComponent($1)
		let val = decodeURIComponent($2)
		val = String(val)
		obj[name] = val
		return rs
	})
	return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
	// returns the byte length of an utf8 string
	let s = str.length
	for (var i = str.length - 1; i >= 0; i--) {
		const code = str.charCodeAt(i)
		if (code > 0x7f && code <= 0x7ff) s++
		else if (code > 0x7ff && code <= 0xffff) s += 2
		if (code >= 0xdc00 && code <= 0xdfff) i--
	}
	return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
	const newArray = []
	for (let i = 0; i < actual.length; i++) {
		if (actual[i]) {
			newArray.push(actual[i])
		}
	}
	return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
	if (!json) return ''
	return cleanArray(
		Object.keys(json).map((key) => {
			if (json[key] === undefined) return ''
			return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
		})
	).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
	const search = url.split('?')[1]
	if (!search) {
		return {}
	}
	return JSON.parse(
		'{"' +
			decodeURIComponent(search)
				.replace(/"/g, '\\"')
				.replace(/&/g, '","')
				.replace(/=/g, '":"')
				.replace(/\+/g, ' ') +
			'"}'
	)
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
	const div = document.createElement('div')
	div.innerHTML = val
	return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
	if (typeof target !== 'object') {
		target = {}
	}
	if (Array.isArray(source)) {
		return source.slice()
	}
	Object.keys(source).forEach((property) => {
		const sourceProperty = source[property]
		if (typeof sourceProperty === 'object') {
			target[property] = objectMerge(target[property], sourceProperty)
		} else {
			target[property] = sourceProperty
		}
	})
	return target
}
export function findKeyArr(arr, obj) {
	let keys = Object.keys(obj)
	let result = []
	for (let i = 0; i < arr.length; i++) {
		if (keys.includes(arr[i])) {
			result.push(arr[i])
		}
	}
	return result
}
export function getMinSet(...arrays) {
	// 将每个数组中的日期字符串转换为Date对象
	const dateArrays = arrays.map((arr) =>
		arr.map((dateStr) => new Date(dateStr))
	)
	// 将每个数组进行排序
	const sortedArrays = dateArrays.map((arr) => arr.sort((a, b) => a - b))

	// 取得每个数组的最大时间和最小时间
	const maxTimes = sortedArrays.map((arr) => arr[0])
	const minTimes = sortedArrays.map((arr) => arr[arr.length - 1])

	// 取得所有数组中的最大时间和最小时间
	const maxTime = new Date(Math.max(...maxTimes))
	const minTime = new Date(Math.min(...minTimes))
	// 生成最小时间段的日期数组
	const minSet = [maxTime, minTime].map((date) =>
		date.toISOString().slice(0, 10)
	)

	return minSet
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
	if (!element || !className) {
		return
	}
	let classString = element.className
	const nameIndex = classString.indexOf(className)
	if (nameIndex === -1) {
		classString += '' + className
	} else {
		classString =
			classString.substr(0, nameIndex) +
			classString.substr(nameIndex + className.length)
	}
	element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
	if (type === 'start') {
		return new Date().getTime() - 3600 * 1000 * 24 * 90
	} else {
		return new Date(new Date().toDateString())
	}
}
export function getMonthRange(year, month) {
	//通过年月获取当月的第一天和最后一天
	var firstDay = new Date(year, month - 1, 1)
	var lastDay = new Date(year, month, 0)
	return {
		firstDay: firstDay,
		lastDay: lastDay,
	}
}
export function formatDate(date, cFormat) {
	//把date格式的日期转成年月日格式
	const format = cFormat || '{y}-{m}-{d}'
	const year = date.getFullYear()
	const month = String(date.getMonth() + 1).padStart(2, '0')
	const day = String(date.getDate()).padStart(2, '0')
	const formatObj = {
		y: year,
		m: month,
		d: day,
	}
	const time_str = format.replace(/{([ymd])+}/g, (result, key) => {
		const value = formatObj[key]
		return value.toString()
	})
	return time_str
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
	let timeout, args, context, timestamp, result

	const later = function () {
		// 据上一次触发时间间隔
		const last = +new Date() - timestamp

		// 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
		if (last < wait && last > 0) {
			timeout = setTimeout(later, wait - last)
		} else {
			timeout = null
			// 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
			if (!immediate) {
				result = func.apply(context, args)
				if (!timeout) context = args = null
			}
		}
	}

	return function (...args) {
		context = this
		timestamp = +new Date()
		const callNow = immediate && !timeout
		// 如果延时不存在，重新设定延时
		if (!timeout) timeout = setTimeout(later, wait)
		if (callNow) {
			result = func.apply(context, args)
			context = args = null
		}

		return result
	}
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
	if (!source && typeof source !== 'object') {
		throw new Error('error arguments', 'deepClone')
	}
	const targetObj = source.constructor === Array ? [] : {}
	Object.keys(source).forEach((keys) => {
		if (source[keys] && typeof source[keys] === 'object') {
			targetObj[keys] = deepClone(source[keys])
		} else {
			targetObj[keys] = source[keys]
		}
	})
	return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
	return Array.from(new Set(arr))
}
//把对象数组每一项数据里面的对象的值为数字的相加，最终合并成一个对象返回
//例如：[{num:1,name:4},{num:2,name:3}] => 返回 {num:3,name:7}
export function mergeArr(arr) {
	let res = arr.reduce((result, next) => {
		if (!result) result = {}
		Object.keys(next).forEach((key) => {
			//数值类型
			if (typeof next[key] == 'number') {
				result[key] = (result[key] ? result[key] : 0) + next[key]
			}
			//数组类型
			if (next[key] instanceof Array) {
				result[key] = (result[key] ? result[key] : []).concat(next[key])
			}
		})
		return result
	})
	return res
}

/**
 * @returns {string}
 */
export function createUniqueString() {
	const timestamp = +new Date() + ''
	const randomNum = parseInt((1 + Math.random()) * 65536) + ''
	return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
	return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
	if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
	if (hasClass(ele, cls)) {
		const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
		ele.className = ele.className.replace(reg, ' ')
	}
}

/***
 * 将对象转为数组，仅限一层键值对
 */
export function coverObjToArr(obj) {
	return Object.keys(obj).map(function (i) {
		return { name: i, value: obj[i] } // obj[i];
	})
}

/***
 *获取浏览器地址栏参数
 */
export function getUrlParams(params) {
	var urlObj = {}
	if (!window.location.hash.split('?')[1]) {
		return false
	}
	var urlParams = window.location.hash.split('?')[1]
	//   urlParams = decodeURIComponent(urlParams);
	var urlArr = urlParams.split(/&(?!amp;)/)
	for (var i = 0; i < urlArr.length; i++) {
		var urlArrItem = urlArr[i].split('=')
		if (urlArrItem[0] == 'plat') {
		}
		urlObj[urlArrItem[0]] = decodeURIComponent(urlArrItem[1]).replace(
			new RegExp('&amp;', 'g'),
			'&'
		)
	} // 判断是否有参数
	if (arguments.length >= 1) {
		return urlObj[params]
	}
	return urlObj
}

//设置浏览器地址栏参数
export function setUrlParams(Url, params, val) {
	let urls = decodeURIComponent(Url)
	if (!Url.split('?')[1]) {
		return Url + '?' + params + '=' + val
	}
	var urlParams = Url.split('?')[1]
	urlParams = decodeURIComponent(urlParams)
	var urlArr = urlParams.split(/&(?!amp;)/)
	let str = ''
	const urlObj = getObjFromUrl(Url)
	if (!urlObj[params]) {
		return Url + '&' + params + '=' + val
	} else {
		for (var i = 0; i < urlArr.length; i++) {
			var urlArrItem = urlArr[i].split('=')
			if (urlArrItem[0] == params) {
				str = urlArrItem[0] + '=' + urlArrItem[1]
				return urls.replace(str, params + '=' + val)
			}
		}
	}
}
export function jqUrlParams(obj) {
	if (!obj) return ''
	var params = []
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) {
			var value = obj[key]
			if (Array.isArray(value)) {
				for (var i = 0; i < value.length; i++) {
					params.push(key + '[]=' + encodeURIComponent(value[i]))
				}
			} else {
				params.push(key + '=' + encodeURIComponent(value))
			}
		}
	}
	return params.join('&')
}
function filter(str) {
	// 特殊字符转义
	str += '' // 隐式转换
	str = str.replace(/%/g, '%25')
	str = str.replace(/\+/g, '%2B')
	str = str.replace(/ /g, '%20')
	str = str.replace(/\//g, '%2F')
	str = str.replace(/\?/g, '%3F')
	str = str.replace(/&/g, '%26')
	str = str.replace(/\=/g, '%3D')
	str = str.replace(/#/g, '%23')
	str = str.replace(/,/g, '%2C')
	return str
}

export function formateObjToParamStr(paramObj) {
	const sdata = []
	for (let attr in paramObj) {
		if (paramObj[attr] == null) {
			paramObj[attr] = ''
		}
		sdata.push(`${attr}=${filter(paramObj[attr])}`)
	}
	return sdata.join('&')
}

export function formateFenxiParamStr(paramObj) {
	const sdata = []
	for (let attr in paramObj) {
		if (attr == 'sortby' && paramObj[attr] == 'sale') {
			sdata.push(`${attr}=sales`)
		} else if (attr == 'desc') {
			sdata.push(paramObj[attr] == 'true' ? `asc=0` : `asc=1`)
		} else {
			if (paramObj[attr] == null) {
				paramObj[attr] = ''
			}
			sdata.push(`${attr}=${filter(paramObj[attr])}`)
		}
	}
	return sdata.join('&')
}
//从浏览器地址栏中读取参数
export function getObjFromUrl(url) {
	var args = url.split('?')
	if (args[0] == url) {
		return ''
	}
	var arr = args[1].split(/&(?!amp;)/)
	var obj = {}
	for (var i = 0; i < arr.length; i++) {
		var arg = arr[i].split('=')
		obj[arg[0]] = arg[1].replace(new RegExp('&amp;', 'g'), '&')
	}
	return obj
}

//设置y轴最大值
export function setYAxisMaxVal(arrayF) {
	let flatten = (arr) => {
		while (arr.some((item) => Array.isArray(item))) {
			arr = [].concat(...arr)
		}
		return arr
	}
	let returnMaxValue = (array, referMax) => {
		try {
			if (Math.max(...array.flat(Infinity)) > referMax) {
				referMax =
					referMax -
					(Math.max(...array.flat(Infinity)) % referMax) +
					Math.max(...array.flat(Infinity))
			}
			return referMax
		} catch (error) {
			if (Math.max(...flatten(array)) > referMax) {
				referMax =
					referMax -
					(Math.max(...flatten(array)) % referMax) +
					Math.max(...flatten(array))
			}
			return referMax
		}
	}
	let maxValue = 10
	maxValue = returnMaxValue(arrayF, maxValue)
	var big_val = maxValue
		? formatInt(maxValue, (Math.round(maxValue) + '').length - 2)
		: 100
	return big_val
}

//获取指定年月的上一个月
export function getLastMonth(data) {
	var year, lastMonth
	var date = new Date(data)
	var nowYear = date.getFullYear()
	var nowMonth = date.getMonth()
	if (nowMonth == 0) {
		year = nowYear - 1
		lastMonth = 12
	} else {
		year = nowYear
		lastMonth = nowMonth
	}
	lastMonth = lastMonth < 10 ? '0' + lastMonth : lastMonth
	let lastYearMonth = year + '-' + lastMonth
	return lastYearMonth
}

//处理品牌下拉选项问题
export function handelBrandNameOptions(newVal, brandOptions) {
	/* if (!brandOptions) {
    return [];
  }*/
	let options = brandOptions
	if (!newVal || !newVal.length) {
		return options
	} else {
		let otherOptions = store.state.user.brandName
		let defaultBrandOptions = localStorage.getItem('defaultBrandOption')
			? JSON.parse(localStorage.getItem('defaultBrandOption'))
			: []
		let arr = [...brandOptions, ...otherOptions, ...defaultBrandOptions]
		let newArr = []
		arr.map((item) => {
			let isExit = newArr.find((item2) => {
				if (item2.id == item.id) {
					return item2
				}
			})
			if (!isExit) {
				newArr.push(item)
			}
		})
		return newArr
	}
}

//两个数组合并去重
export function MergeDeduplicationArray(arr1, arr2) {
	let oldBrandOptions1 = JSON.parse(JSON.stringify(arr1))
	let oldBrandOptions2 = JSON.parse(JSON.stringify(arr2))
	let newOptions = []
	let a = [...oldBrandOptions1, ...oldBrandOptions2]
	let b = new Set(a)
	newOptions = [...b]
	return newOptions
}
//将数字转为金额
export function transformAmount(value, type = 'ch') {
	if (!value && value !== 0) return 0
	if (localStorage.getItem('language') == 'en' || type == 'en') {
		if (value > 999999999) {
			value = (value / 1000000000).toFixed(2) + 'B'
		} else if (value > 999999) {
			value = (value / 1000000).toFixed(2) + 'M'
		} else if (value > 999) {
			value = (value / 1000).toFixed(2) + 'K'
		} else {
			value = parseInt(value, 10) + ''
		}
		value = 'US $' + value
	} else {
		if (value > 99999999) {
			value = (value / 100000000).toFixed(2) + '亿'
		} else if (value > 999999) {
			value = (value / 10000).toFixed(2) + '万'
		} else if (value > 99999) {
			value = (value / 10000).toFixed(2) + '万'
		} else if (value > 9999) {
			value = (value / 10000).toFixed(2) + '万'
		} else {
			value = parseFloat(value).toFixed(2)
		}
		value = '￥' + value
	}
	return value
}
export function transformNumber(value) {
	if (!value) return 0
	if (localStorage.getItem('language') == 'en') {
		if (value > 999999999) {
			value = (value / 1000000000).toFixed(2) + 'B'
		} else if (value > 999999) {
			value = (value / 1000000).toFixed(2) + 'M'
		} else if (value > 999) {
			value = (value / 1000).toFixed(2) + 'K'
		} else {
			value = parseInt(value, 10) + ''
		}
	} else {
		if (value > 99999999) {
			value = (value / 100000000).toFixed(2) + '亿'
		} else if (value > 999999) {
			value = (value / 10000).toFixed(2) + '万'
		} else if (value > 99999) {
			value = (value / 10000).toFixed(2) + '万'
		} else if (value > 9999) {
			value = (value / 10000).toFixed(2) + '万'
		} else {
			value = parseFloat(value)
		}
	}
	return value
}
export function computeChartNumber(value, divisor, unit) {
  // divisor 除数 unit 单位
  value = (value / divisor).toFixed(1).toString()
  if (value.split('.')[1] == 0) {
    value = value.split('.')[0]
  }
  value = value + unit;
  return value
}
export function transformNumberChart(value) {
	if (!value) return 0
	if (localStorage.getItem('language') == 'en') {
		if (value > 999999999) {
			value = computeChartNumber(value, 1000000000, "B");
		} else if (value > 999999) {
			value = computeChartNumber(value, 1000000, "M");
		} else if (value > 999) {
			value = computeChartNumber(value, 1000, "K");
		} else {
			value = parseInt(value, 10) + ''
		}
	} else {
		if (value > 99999999) {
			value = computeChartNumber(value, 100000000, "亿");
		} else if (value > 999999) {
			value = computeChartNumber(value, 10000, "万");
		} else if (value > 99999) {
			value = computeChartNumber(value, 10000, "万");
		} else if (value > 9999) {
			value = computeChartNumber(value, 10000, "万");
		} else {
			value = parseFloat(value)
		}
	}
	return value
}
export function toInfinity(val, num) {
	if (num == 0) {
		return '0'
	} else {
		let str = Number(val * 100).toFixed(2) + '%'
		return str
	}
}
//将数字转为百分比，如果为0就变成无穷大
export function transformInfinity(val) {
	if (val == 0) {
		return '∞'
	} else {
		let str = Number(val * 100).toFixed(2)
		str += '%'
		return str
	}
}
//将数字转为千分位
export function transformNum(val) {
	return val.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
}

//根据当前浏览器地址中获取的id设置相应的name(目前只有brand品牌用到)，用于解决location带来的无法session隔离的问题
export function initLocalName(ids, names) {
	let NewNames = []
	if (ids && ids.length && names && names.length) {
		NewNames = names.filter((item) => {
			return ids.find((id) => {
				return id == item.id
			})
		})
	}
	return NewNames
}

//设置当前tab
export function setActivedTab(val) {
	if (store.state.user.ActivedTab != val) {
		store.dispatch('user/changeActivedTab', val)
	}
}
//两个对象的深度合并
export function deepMerge(obj1, obj2) {
	let key
	for (key in obj2) {
		obj1[key] =
			obj1[key] &&
			typeof obj1[key] === 'object' &&
			obj2[key] &&
			typeof obj2[key] === 'object'
				? deepMerge(obj1[key], obj2[key])
				: (obj1[key] = obj2[key])
	}
	return obj1
}

export function getBaseErrorObj(){
  const url = window.location.href
  const ua = navigator.userAgent
  const tab = store.state.user.ActivedTab
  const browser_finger = localStorage.getItem('browserFinger')
  console.log(url, browser_finger,store)
  return {
    error_url: url,
    ua,
    tab,
    browser_finger
  }
}
