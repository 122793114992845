<template>
  <div class="DianShang-Dialog">
    <img :src="src">
  </div>
</template>

<script>
export default {
  name: "DianShangDialog",
  props: ["src"],
  data() {
    return {}
  },
}
</script>

<style lang="less">
.DianShang-Dialog {
  width: 1000px;
  height: 563px;
  border-radius: 5px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
