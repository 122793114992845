<template>
  <div class="footer">
    <p>魔镜市场情报与淘宝、天猫、京东、1688.com、速卖通、亚马逊等电商平台无直接关联，是一家独立的第三方电商数据机构</p>
    <p>数据由公开页面采集并汇总处理，数据口径和平台及其他机构有所不同，仅供参考，如有差异请以平台官方发布数据为准</p>
    <p class="text-footer"> ©2023 北京淘幂科技有限公司
      <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备15061734号-4</a> |  
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502037832" target="_blank">
        <img src="https://static.mktindex.com/common/images/gonganlogo.png" style="vertical-align: sub; width: 15px">
        京公网安备 11010502037832号
      </a>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="less">
.footer {
  width: 100%;
  background-color: #43474d;
  color: #85868A;
  font-size: 14px;
  padding: 20px;
  p {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .text-footer {
    color: #f3f3f3;
    a {
      color: #4e90e2;
    }
    a:hover, a:focus {
      color: #23527c;
    }
  }
}
</style>