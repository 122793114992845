import request from "@/utils/request";
const BASE_API = process.env.VUE_APP_BASE_API;
const host = window.location.host
let inspirationApi = ''
if (host == 'commentvue.mktindex.com' || host == 'comment.mktindex.com') {
  inspirationApi = 'https://cmi.mktindex.com'
} else {
  inspirationApi = 'https://cmitest.mktindex.com'
}
const CMI_BASE_API = inspirationApi


export function getSubscribe(param) {
  return request({
    url: `${BASE_API}/api/linsten/subscribe`,
    method: 'get',
    params: param
  })
}

export function getAttention(param) {
  return request({
    url: `${BASE_API}/api/linsten/attention`,
    method: 'get',
    params: param
  })
}

export function putAttention(param) {
  return request({
    url: `${BASE_API}/api/linsten/attention`,
    method: 'put',
    data: param
  })
}

export function deleteAttention(param) {
  return request({
    url: `${BASE_API}/api/linsten/attention`,
    method: 'delete',
    params: param
  })
}

export function getCustomAnalyse(param) {
  return request({
    url: `${BASE_API}/api/linsten/custom_analyse`,
    method: 'get',
    params: param
  })
}

export function deleteCustomAnalyse(param) {
  return request({
    url: `${BASE_API}/api/linsten/custom_analyse`,
    method: 'delete',
    params: param
  })
}

export function postCustomPublish(param){
  return request({
    url: `${CMI_BASE_API}/api/custom_publish`,
    method: "post",
    data: param
  })
}
