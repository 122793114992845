<template>
  <el-container class="outer-container">
    <el-header class="outheader">
      <TopHeader>
      </TopHeader>
    </el-header>
    <el-container class="inner-container">
      <el-aside class="inner-aside"
                style="width: auto">
        <AsideMenue>
        </AsideMenue>
      </el-aside>
      <el-container class="inner-container2">
        <!-- <HeadProduct v-if="!$store.state.user.loginInfo.is_partner"></HeadProduct> -->
        <el-header class="header">
          <MainHeader v-if="showMainHeader && userLoaded">
          </MainHeader>
        </el-header>
        <el-main>
          <el-scrollbar style="height:100%"
                        ref="scrollbar">
            <router-view class="roterView"
                         v-if="userLoaded&&showView" />
            <Footer></Footer>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import TopHeader from '@/components/topHeader.vue'
import MainHeader from '@/components/mainHeader.vue'
import AsideMenue from '@/components/AsideMenue.vue'
import Footer from '@/components/footer.vue'
// import HeadProduct from '@/components/headProduct.vue'
export default {
  components: {
    TopHeader,
    MainHeader,
    AsideMenue,
    Footer,
    // HeadProduct,
  },
  data() {
    return {
      showView: true,
    }
  },
  mounted() {
    this.$eventBus.$on('refreshView', this.refreshView)
  },
  computed: {
    showMainHeader() {
      return this.$store.state.user.ShowMainHead
    },
    userLoaded() {
      return this.$store.state.user.userLoaded
    },
  },
  methods: {
    refreshView() {
      this.showView = false
      this.$nextTick(() => {
        this.showView = true
      })
    },
  },
  beforeDestroyed() {
    this.$eventBus.$off('refreshView')
  },
}
</script>

<style scoped lang="less">
.outer-container {
  height: 100%;
  .outheader {
    min-height: 60px;
    height: auto !important;
    z-index: 2000;
    box-shadow: 0 2px 5px 0 rgba(159, 77, 0, 0.2);
    border-radius: 0 0 4px 4px;
  }
  .inner-container {
    height: calc(100% - 60px);
    .inner-container2 {
      height: 100%;
      .el-main {
        background-color: #f3f4f6;
        overflow: hidden auto;
        padding: 0px;
      }
    }
  }
}
.header {
  padding: 0;
  height: auto !important;
  background: #f3f4f6;
}
</style>
