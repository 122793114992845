export const timeRnage = {
  data() {
    return {
    };
  },
  methods: {},
};

export const paramsObj = {
  data() {
    return {};
  },
  computed: {
    paramsObj() {
      return {
        plat: this.$store.state.user.plat,
        catId: this.$store.state.user.catId,
        start: this.$store.state.user.start,
        end: this.$store.state.user.end,
        tsType: this.$store.state.user.tsType,
        shop: this.$store.state.user.shopId,
        brand: this.$store.state.user.brandId,
        item: this.$store.state.user.itemId,
        titleWord: this.$store.state.user.titleWord,
        titleTyp: this.$store.state.user.titleTyp,
        contentWord: this.$store.state.user.contentWord,
        priceMax: this.$store.state.user.priceMax,
        priceMin: this.$store.state.user.priceMin,
        picType: this.$store.state.user.picType,
        question_time_typ: this.$store.state.user.question_time_typ,
        navData: this.$store.state.user.navData,
        itemAnalyse: this.$store.state.user.itemAnalyse,
        fxUrlId: this.$store.state.user.fxUrlId,
        sku: this.$store.state.user.skuId,
        parentCatId: this.$store.state.user.parentCatId,
        customFilters: this.$store.state.user.customFilters
      };
    },
  },
  watch: {
  },
};
